import Login from "../components/Login";
import Navigation from "../components/Navigation";
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from "../Context/AuthContext";
import Loading from "../components/Loading";

function LoginPage() {

    const { currentUser, loading } = useContext(AuthContext)
    const navigate = useNavigate()

    // Check if the current user exists on the initial render.
    useEffect(() => {
        if (!loading && currentUser?.email == undefined) {
            navigate('/Login')
        } else {
            console.log("No user is logged in")
        }
    }, [currentUser, loading]);

    return (
            <div className="bg-black h-screen w-screen">
                <Login />
            </div>
    );
}

export default LoginPage;