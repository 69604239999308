import toast, { Toaster } from 'react-hot-toast';
import { useEffect, useRef } from "react"
import bubble from '../sounds/bubble.mp3'


interface NotificationsProps {
    newMessage: string;
    newTitle: string;
  }
  

export default function Notifications({newMessage, newTitle}: NotificationsProps) {

    const alertShown = useRef(false);
    const audio = new Audio(bubble);

    useEffect(() => {
      if (!alertShown.current && newMessage && newTitle) {
        alertShown.current = true;
        audio.play();
        setTimeout(() => {
          
          alert();
          
        }, 1000); // 1 second delay
      }
    }, [newMessage, newTitle, alertShown]);

    const alert = () => {
  
          // Use the callback to update both states at once
  
          toast.custom((t) => (
            <div
              className={`${t.visible ? 'animate-enter' : 'animate-leave'} max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
            >
              <div className="flex-1 w-0 p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0 pt-0.5">
                  </div>
                  <div className="ml-3 flex-1">
                    <p className="text-sm font-medium text-gray-900">
                      {newTitle}
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      {newMessage}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex border-l border-gray-200">
                <button
                  onClick={() => toast.dismiss(t.id)}
                  className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                >
                  Close
                </button>
              </div>
            </div>
          ));
          return newMessage;
    }

    return (
            <Toaster position="top-right"/>
    );
}