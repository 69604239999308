import { useState, useContext, useEffect, useMemo } from 'react'
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
} from '@headlessui/react'
import {
  Bars3Icon,
  BellIcon,
  CalendarIcon,
  ChartPieIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { AuthContext } from '../../Context/AuthContext'
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";



const navigation = [
  { name: 'Dashboard', href: '#', icon: HomeIcon, current: true },
  { name: 'Team', href: '#', icon: UsersIcon, current: false },
  { name: 'Projects', href: '#', icon: FolderIcon, current: false },
  { name: 'Calendar', href: '#', icon: CalendarIcon, current: false },
  { name: 'Documents', href: '#', icon: DocumentDuplicateIcon, current: false },
  { name: 'Reports', href: '#', icon: ChartPieIcon, current: false },
]
const userNavigation = [
  { name: 'Your profile', href: '#' },
  { name: 'Sign out', href: '#' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}



export default function Header() {

  const [userData, setUserData] = useState<userDataInterface>();

  type userDataInterface = {
    userContactPh: string;
    userCustomerNum: string;
    userEmail: string;
    userFirstName: string;
    userLastName: string;
    imageUrl: string;
    role: string;
  }[];

  const { currentUser, signOut, loading } = useContext(AuthContext)
  const auth = useContext(AuthContext);
  const userEmail = auth.currentUser?.email

  const LoadingEvent = () => (
    <div className="absolute z-20 mx-auto right-10 mt-3">
      <div className="flex start-0 mx-auto">
        <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    </div>
  )

  const getUserData = async () => {

    const docRef = doc(db, "Users", userEmail);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const data = docSnap.data()
      setUserData([
        {
          userContactPh: data.contactPh,
          userCustomerNum: data.customerNum,
          userEmail: data.email,
          userFirstName: data.firstName,
          userLastName: data.lastName,
          imageUrl: data.imageUrl,
          role: data.role
        }]
      );
    } else {
      alert('No such Id. Please check again');
    }

  }

  useEffect(() => {
    if (!loading && userEmail == undefined) {
      return;
    } else {
      getUserData()
    }
  }, [currentUser, userData])

  return (
    <div className="z-100 mx-auto">
      {userEmail == undefined ? (<LoadingEvent />) : (
        <div className="absolute z-20 mx-auto right-20 sm:right-8 max-sm:right-5 mt-3 max-sm:mt-4 sm:mt-4">
          <div className="flex start-0 mx-auto">
           {/* <button type="button" className="-m-2.5 p-2.5 text-white hover:text-gray-500">
              <span className="sr-only">View notifications</span>
              <BellIcon aria-hidden="true" className="h-10 w-10 mr-10 max-sm:h-7 max-sm:w-7 max-sm:mr-5" />
            </button>
            */}
            {/* Profile dropdown */}
            <Menu as="div" className="relative">
              <MenuButton className="-m-1.5 flex items-center p-1.5">
                <span className="sr-only">Open user menu</span>
                <img
                  alt=""
                  src={userData?.[0].imageUrl}
                  className="h-12 w-12 rounded-full bg-gray-50"
                />
                <span className="lg:flex lg:items-center">
                  <span aria-hidden="true" className="ml-4 text-lg max-sm:text-sm sm:text-sm font-regular leading-6 lg:text-white sm:text-white max-sm:text-white">
                    {userData?.[0].userFirstName + " " + userData?.[0].userLastName}
                  </span>
                  <ChevronDownIcon aria-hidden="true" className="ml-2 h-5 w-5 text-white" />
                </span>
              </MenuButton>
              <MenuItems
                transition
                className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
              >
                <MenuItem>
                  <a
                    href='/Profile'
                    className="block px-3 py-1 text-lg leading-6 text-gray-900 data-[focus]:bg-gray-50"
                  >
                    My Profile
                  </a>
                </MenuItem>
                <MenuItem>
                  <a
                    onClick={signOut}
                    className="block px-3 py-1 text-lg leading-6 text-gray-900 data-[focus]:bg-gray-50"
                  >
                    Log Off
                  </a>
                </MenuItem>
              </MenuItems>
            </Menu>
          </div>
        </div>
      )}
    </div>
  )
}