import Header from "../components/CustomerPortal/Header";
import SideBar from "../components/CustomerPortal/SideBar";
import Customers from "../components/CustomerPortal/Customers";

function CustomersPage() {

  return(
      <div>
        <Header />
        <SideBar />
        <Customers/>
      </div>
  );
}

export default CustomersPage;
