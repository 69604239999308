import Header from "../components/CustomerPortal/Header";
import NewOrderHistory from "../components/CustomerPortal/NewOrderHistory"
import SideBar from "../components/CustomerPortal/SideBar";

function OrderHistoryPage() {

  return(
      <div>
        <Header />
        <SideBar />
        <NewOrderHistory />
      </div>
  );
}

export default OrderHistoryPage;
