import { useState } from 'react'
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  TransitionChild,
} from '@headlessui/react'
import {
  Bars3Icon,
  DocumentTextIcon,
  Cog6ToothIcon,
  FolderIcon,
  DocumentPlusIcon,
  UsersIcon,
  CurrencyDollarIcon,
  XMarkIcon,
  ChartBarSquareIcon,
} from '@heroicons/react/24/outline'
import TanekahaLogo from '../../images/TanekahaLogo.jpg'


const navigation = [
  { name: 'Dashboard (Coming Soon)', href: '/Dashboard', icon: ChartBarSquareIcon, current: true },
  { name: 'Customers', href: '/Customers', icon: UsersIcon, current: false },
  { name: 'Sellers (coming soon)', href: '/Portal', icon: CurrencyDollarIcon, current: false },
  { name: 'New Order', href: '/NewOrder', icon: DocumentPlusIcon, current: false },
  { name: 'Current Orders', href: '/CurrentOrders', icon: DocumentTextIcon, current: false },
  { name: 'Order History', href: '/OrderHistory', icon: FolderIcon, current: false }
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function SideBar() {
  const [sidebarOpen, setSidebarOpen] = useState(false)


    return(

        <div>
        <Dialog open={sidebarOpen} onClose={setSidebarOpen} className="relative z-50 lg:hidden">
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-black transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
          />

          <div className="fixed inset-0 flex bg-black">
            <DialogPanel
              transition
              className="bg-black relative mr-16 flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out data-[closed]:-translate-x-full"
            >
              <TransitionChild>
                <div className="bg-black absolute left-full top-0 flex w-16 justify-center pt-5 duration-300 ease-in-out data-[closed]:opacity-0">
                  <button type="button" onClick={() => setSidebarOpen(false)} className="-m-2.5 p-2.5">
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon aria-hidden="true" className="h-6 w-6 text-white" />
                  </button>
                </div>
              </TransitionChild>
              {/* Sidebar component, swap this element with another sidebar if you like */}
              <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-black px-6 pb-4 ring-1 ring-white/10">
                <div className="flex h-16 shrink-0 items-center">
                  <img
                    alt="Tanekaha Fuels"
                    src={TanekahaLogo}
                    className="h-8 w-auto"
                  />
                </div>
                <nav className="flex flex-1 flex-col">
                  <ul role="list" className="bg-black flex flex-1 flex-col gap-y-7">
                    <li>
                      <ul role="list" className="-mx-2 space-y-1">
                        {navigation.map((item) => (
                          <li key={item.name}>
                            <a
                              href={item.href}
                              className={classNames(
                                item.current
                                  ? 'bg-black text-white'
                                  : 'text-white hover:bg-gray-800 hover:text-white',
                                'group flex gap-x-3 rounded-md p-2 text-sm font-regular leading-6',
                              )}
                            >
                              <item.icon aria-hidden="true" className="h-6 w-6 shrink-0" />
                              {item.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </li>
                    <li className="mt-auto">
                      <a
                        href="/"
                        className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-regular leading-6 text-gray-400 hover:bg-gray-800 hover:text-white"
                      >
                        <Cog6ToothIcon aria-hidden="true" className="h-6 w-6 shrink-0" />
                        Settings
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </DialogPanel>
          </div>
        </Dialog>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-1/5 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-black px-6 pb-4">
            <div className="relative flex h-16 shrink-0 object-center mt-6 mx-5">
              <img
                alt="Tanekaha Fuels"
                src={TanekahaLogo}
                className="h-24 w-24 mx-auto"
              />
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7 mt-10">
                <li>
                  <ul role="list" className="mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          className={classNames(
                            item.current
                              ? 'bg-black text-white hover:bg-[#c1a274] hover:text-white'
                              : 'text-white hover:bg-[#c1a274] hover:text-white',
                            'group flex gap-x-3 rounded-md p-2 text-sm font-regular leading-6',
                          )}
                        >
                          <item.icon aria-hidden="true" className="h-6 w-6 shrink-0" />
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>
                <li className="mt-auto">
                  <a
                    href="/"
                    className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-regular leading-6 text-white hover:bg-[#c1a274] hover:text-white"
                  >
                    <Cog6ToothIcon aria-hidden="true" className="h-6 w-6 shrink-0" />
                    Settings
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="lg:pl-72">
          <div className="relative top-0 z-200 flex h-20 shrink-0 items-center gap-x-4 border-black max-sm:bg-black sm:bg-black px-4 sm:gap-x-6 sm:px-6 lg:px-8 lg:hidden">
            <button type="button" onClick={() => setSidebarOpen(true)} className="-m-2.5 p-2.5 text-white lg:hidden">
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon aria-hidden="true" className="h-6 w-6" />
            </button>
        </div>
      </div>
      </div>
    );
}