import { AuthContext } from '../../Context/AuthContext'
import { useEffect, useContext, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { db, storage } from "../../firebase/firebase";
import { getDoc, doc, updateDoc, setDoc, deleteDoc, getDocs, collection, Timestamp } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Loading from '../Loading';
import { FaFilter, FaChartBar, FaChartLine, FaChartPie } from 'react-icons/fa';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LineChart, Line, PieChart, Pie, Cell } from 'recharts';

export default function Dashboard () {

      // current user logged in State
  const { currentUser, setCurrentUser, signOut } = useContext(AuthContext);
  const auth = useContext(AuthContext)
  const navigate = useNavigate()
  const userEmail = auth.currentUser?.email
  const [userData, setUserData] = useState<userDataInterface>();
  const [loading, setLoading] = useState(true);

  type userDataInterface = {
    contactPh: string;
    customerNum: string;
    email: string;
    firstName: string;
    lastName: string;
    imageUrl: string;
    role: string;
    address: string;
  }[];

  const [data, setData] = useState([]);
  const [filter, setFilter] = useState('all');
  const [selectedDataPoint, setSelectedDataPoint] = useState(null);

  useEffect(() => {
    // Simulating API call to fetch data
    const fetchData = () => {
      const mockData = [
        { month: 'Jan', profit: 5000, margin: 20 },
        { month: 'Feb', profit: 6000, margin: 22 },
        { month: 'Mar', profit: 7500, margin: 25 },
        { month: 'Apr', profit: 8000, margin: 28 },
        { month: 'May', profit: 9000, margin: 30 },
        { month: 'Jun', profit: 10000, margin: 32 },
      ];
      setData(mockData);
      setLoading(false)
    };
    fetchData();
  }, []);

  const filteredData = data.filter(item => {
    if (filter === 'all') return true;
    if (filter === 'highProfit') return item.profit > 7000;
    if (filter === 'lowMargin') return item.margin < 25;
    return true;
  });

  const handleFilter = (newFilter) => {
    setFilter(newFilter);
  };

  const handleDataPointClick = (data) => {
    setSelectedDataPoint(data);
  };

  const COLORS = ['#c1a274', '#00C49F', '#FFBB28', '#FF8042', '#c1a274', '#c1a274'];


    return (
        <div>
      {loading ? (
        <Loading />
      ) : (
        <div className="relative inset-y-0 right-0 lg:absolute sm:flex flex-col min-h-screen w-full bg-tanekaha3">
          <div className="absolute mx-auto lg:top-0 lg:right-10 lg:w-3/4 md:top-30 md:right-10 md:w-3/4 h-screen sm:right-5 sm:top-30 sm:right-10 sm:w-full max-sm:top-30 max-sm:right-10 max-sm:full">
            <header className="text-primary-foreground py-4 text-left">
              <div className="container mx-auto flex justify-between">
                <h1 className="font-bebas lg:text-4xl text-left text-black mt-10 max-sm:text-xl sm:text-xl">TANEKAHA PORTAL</h1>
              </div>
            </header>
           
            <div className="min-h-screen p-8">
      <h1 className="text-2xl font-bebas font-bold text-black mb-8">TANEKAHA DASHBOARD</h1>
      
      <div className="bg-gray-400 rounded-lg shadow-lg p-6 mb-8">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold text-white">Monthly Profitability</h2>
          <div className="flex space-x-4">
            <button
              onClick={() => handleFilter('all')}
              className={`px-4 py-2 rounded ${filter === 'all' ? 'bg-[#c1a274] text-white' : 'bg-gray-200 text-gray-700'}`}
              aria-label="Show all data"
            >
              <FaFilter className="inline mr-2" />
              All
            </button>
            <button
              onClick={() => handleFilter('highProfit')}
              className={`px-4 py-2 rounded ${filter === 'highProfit' ? 'bg-[#c1a274] text-white' : 'bg-gray-200 text-gray-700'}`}
              aria-label="Filter high profit data"
            >
              <FaFilter className="inline mr-2" />
              High Profit
            </button>
            <button
              onClick={() => handleFilter('lowMargin')}
              className={`px-4 py-2 rounded ${filter === 'lowMargin' ? 'bg-[#c1a274] text-white' : 'bg-gray-200 text-gray-700'}`}
              aria-label="Filter low margin data"
            >
              <FaFilter className="inline mr-2" />
              Low Margin
            </button>
          </div>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="bg-gray-50 p-4 rounded-lg">
            <h3 className="text-lg font-semibold mb-4 text-gray-700"><FaChartBar className="inline mr-2" />Profit Overview</h3>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={filteredData} onClick={handleDataPointClick}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="profit" fill="#c1a274" name="Profit ($)" />
              </BarChart>
            </ResponsiveContainer>
          </div>
          
          <div className="bg-gray-50 p-4 rounded-lg">
            <h3 className="text-lg font-semibold mb-4 text-gray-700"><FaChartLine className="inline mr-2" />Margin Trend</h3>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={filteredData} onClick={handleDataPointClick}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="margin" stroke="#c1a274" name="Margin (%)" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
      
      {selectedDataPoint && (
        <div className="bg-white rounded-lg shadow-lg p-6 mb-8">
          <h2 className="text-xl font-semibold text-gray-700 mb-4">Detailed View: {selectedDataPoint.month}</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
              <h3 className="text-lg font-semibold mb-2 text-gray-700">Profit Breakdown</h3>
              <p className="text-2xl font-bold text-blue-600">${selectedDataPoint.profit}</p>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-2 text-gray-700">Margin Analysis</h3>
              <div className="w-full h-48">
                <ResponsiveContainer>
                  <PieChart>
                    <Pie
                      data={[{ name: 'Margin', value: selectedDataPoint.margin }, { name: 'Other', value: 100 - selectedDataPoint.margin }]}
                      cx="50%"
                      cy="50%"
                      innerRadius={60}
                      outerRadius={80}
                      fill="#8884d8"
                      dataKey="value"
                    >
                      {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip />
                  </PieChart>
                </ResponsiveContainer>
              </div>
              <p className="text-center mt-2">{selectedDataPoint.margin}% Margin</p>
            </div>
          </div>
        </div>
      )}
    </div>
          </div>
          <footer className="absolute sm:hidden max-sm:hidden inset-x-0 bottom-10 bg-muted text-muted-foreground">
            <div className="container mx-auto text-center">© 2024 Tanekaha Fuels. All rights reserved.</div>
          </footer>
        </div>
      )}
    </div>
    );
}