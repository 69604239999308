import { useState, useEffect, useContext, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../Loading";
import { collection, getDocs, Timestamp, query, where, doc, getDoc, updateDoc, arrayUnion, deleteDoc, arrayRemove } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { AuthContext } from "../../Context/AuthContext";
import { PaperClipIcon } from '@heroicons/react/20/solid'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { faExclamation, faFloppyDisk, faPencil, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ref, uploadBytes, listAll, getDownloadURL, deleteObject } from "firebase/storage";
import { storage } from "../../firebase/firebase";
import Notifications from "../Notifications";
import emailjs from '@emailjs/browser';
import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps';



export default function SelectOrder({ orderId }) {
    const [orders, setOrders] = useState<ordersInterface>([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const dateOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    };


    {/* CURRENT USER DATA */ }
    const [userData, setUserData] = useState<userDataInterface>();
    const { currentUser, setCurrentUser, signOut } = useContext(AuthContext);
    const userEmail = currentUser?.email;

    //Constants for notes section
    const [showNewNoteForm, setShowNewNoteForm] = useState(false);
    const [newNote, setNewNote] = useState({ noteTitle: '', noteDescription: '', assignedToUser: '', noteStatus: '', followUpDate: null });
    const [editingNoteIndex, setEditingNoteIndex] = useState<number | null>(null);

    // constant for editing rows
    const [editBuyer, setEditBuyer] = useState(false)
    const [buyerValue, setBuyerValue] = useState("")

    const [editBuyerAddress, setEditBuyerAddress] = useState(false)
    const [buyerAddressValue, setBuyerAddressValue] = useState("")

    const [editBuyerRepresentation, setEditBuyerRepresentation] = useState(false)
    const [buyerRepresentationValue, setBuyerRepresentationValue] = useState("")

    const [editComissionBuyer, setEditComissionBuyer] = useState(false)
    const [comissionBuyerValue, setComissionBuyerValue] = useState<number>(0)

    const [editComissionSeller, setEditComissionSeller] = useState(false)
    const [comissionSellerValue, setComissionSellerValue] = useState<number>(0)

    const [editCommodity, setEditCommodity] = useState(false)
    const [commodityValue, setCommodityValue] = useState("")

    const [editWholesalePrice, setEditWholesalePrice] = useState(false)
    const [wholesalePriceValue, setWholesalePriceValue] = useState("")

    const [editConsultant, setEditConsultant] = useState(false)
    const [consultantValue, setConsultantValue] = useState("")

    const [editCustomerName, setEditCustomerName] = useState(false)
    const [customerNameValue, setCustomerNameValue] = useState("")

    const [editCustomerNum, setEditCustomerNum] = useState(false)
    const [customerNumValue, setCustomerNumValue] = useState("")

    const [editDate, setEditDate] = useState(false)
    const [dateValue, setDateValue] = useState(null)

    const [editFollowing, setEditFollowing] = useState(false)
    const [followingValue, setFollowingValue] = useState("")

    const [editFuelType, setEditFuelType] = useState(false)
    const [fuelTypeValue, setFuelTypeValue] = useState("")

    const [editGrossPrice, setEditGrossPrice] = useState(false)
    const [grossPriceValue, setGrossPriceValue] = useState("")

    const [editGrossPriceCurrency, setEditGrossPriceCurrency] = useState(false)
    const [grossPriceCurrencyValue, setGrossPriceCurrencyValue] = useState("")

    const [editGuaranteeForContract, setEditGuaranteeForContract] = useState(false)
    const [guaranteeForContractValue, setGuaranteeForContractValue] = useState("")

    const [editId, setEditId] = useState(false)
    const [idValue, setIdValue] = useState()

    const [editInspectionValidity, setEditInspectionValidity] = useState(false)
    const [inspectionValidityValue, setInspectionValidityValue] = useState("")

    const [editLoi, setEditLoi] = useState(false)
    const [loiValue, setLoiValue] = useState("")

    const [editOneYearContractPayment, setEditOneYearContractPayment] = useState(false)
    const [oneYearContractPaymentValue, setOneYearContractPaymentValue] = useState("")

    const [editOrderNum, setEditOrderNum] = useState(false)
    const [orderNumValue, setOrderNumValue] = useState("")

    const [editOrigin, setEditOrigin] = useState(false)
    const [originValue, setOriginValue] = useState("")

    const [editPaymentForTrial, setEditPaymentForTrial] = useState(false)
    const [paymentForTrialValue, setPaymentForTrialValue] = useState("")

    const [editPaymentStatus, setEditPaymentStatus] = useState(false)
    const [paymentStatusValue, setPaymentStatusValue] = useState("")

    const [editPerformanceBond, setEditPerformanceBond] = useState(false)
    const [performanceBondValue, setPerformanceBondValue] = useState("")

    const [editQuantity, setEditQuantity] = useState(false)
    const [quantityValue, setQuantityValue] = useState<number>(0)

    const [editQuantityFrequency, setEditQuantityFrequency] = useState(false);
    const [quantityFrequencyValue, setQuantityFrequencyValue] = useState('');

    const [editRefinery, setEditRefinery] = useState(false);
    const [refineryValue, setRefineryValue] = useState('');

    const [editRefineryDirector, setEditRefineryDirector] = useState(false);
    const [refineryDirectorValue, setRefineryDirectorValue] = useState('');

    const [editRepresentedBy, setEditRepresentedBy] = useState(false);
    const [representedByValue, setRepresentedByValue] = useState('');

    const [editStatus, setEditStatus] = useState(false);
    const [statusValue, setStatusValue] = useState('');

    const [editTrialQuantity, setEditTrialQuantity] = useState(false);
    const [trialQuantityValue, setTrialQuantityValue] = useState<number>(0);

    const [editUnitQuantity, setEditUnitQuantity] = useState(false);
    const [unitQuantityValue, setUnitQuantityValue] = useState('');

    const [editGlobalQuantity, setEditGlobalQuantity] = useState(false);
    const [globalQuantityValue, setGlobalQuantityValue] = useState('');

    const [editDeliveryTrial, setEditDeliveryTrial] = useState(false);
    const [deliveryTrialValue, setDeliveryTrialValue] = useState('');

    const [editDeliveryRegular, setEditDeliveryRegular] = useState(false);
    const [deliveryRegularValue, setDeliveryRegularValue] = useState('');

    const [editLoadingPort, setEditLoadingPort] = useState(false);
    const [loadingPortValue, setLoadingPortValue] = useState('');

    const [editTrackingURL, setEditTrackingURL] = useState(false);
    const [trackingURLValue, setTrackingURLValue] = useState('');

    const [editFirstDelivery, setEditFirstDelivery] = useState(false);
    const [firstDeliveryValue, setFirstDeliveryValue] = useState('');

    const [editPriceGrossBuyer, setEditPriceGrossBuyer] = useState(false);
    const [priceGrossBuyerValue, setPriceGrossBuyerValue] = useState('');

    const [editTermsContract, setEditTermsContract] = useState(false);
    const [termsContractValue, setTermsContractValue] = useState('');

    const [editTanekahaCommission, setEditTanekahaCommission] = useState(false)
    const [tanekahaCommissionValue, setTanekahaCommissionValue] = useState("")


    // Toaster notification state
    const [toasterShow, setToasterShow] = useState(false);
    const [newMessage, setNewMessage] = useState("");
    const [newTitle, setNewTitle] = useState("");


    type userDataInterface = {
        displayName: string;
        userContactPh: string;
        userCustomerNum: string;
        userEmail: string;
        userFirstName: string;
        userLastName: string;
        imageUrl: string;
        role: string;
    }[];

    const getUserData = async () => {

        const docRef = doc(db, "Users", userEmail);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            const data = docSnap.data()
            setUserData([
                {
                    displayName: data.displayName,
                    userContactPh: data.contactPh,
                    userCustomerNum: data.customerNum,
                    userEmail: data.email,
                    userFirstName: data.firstName,
                    userLastName: data.lastName,
                    imageUrl: data.imageUrl,
                    role: data.role
                }]
            );
        } else {
            alert('No such Id. Please check again');
        }

    }

    // LIST OF CUSTOMERS FOR ORDERS
    const [customerNames, setCustomerNames] = useState<string[]>([]);
    const [CustomerAddresses, setCustomerAddresses] = useState<string[]>([]);

    const fetchCustomerNames = async () => {
        const customersCollection = collection(db, 'CustomerData');
        const usersSnapshot = await getDocs(customersCollection);
        const names = usersSnapshot.docs.map(doc => {
            const customerData = doc.data();
            return `${customerData.CustomerName}`;
        });
        const addresses = usersSnapshot.docs.map(doc => {
            const customerData = doc.data();
            return `${customerData.CustomerAddress}`;
        });
        const customerNumber = usersSnapshot.docs.map(doc => {
            const customerData = doc.data();
            return `${customerData.customerNum}`;
        });
        setCustomerNames(names);
        setCustomerAddresses(addresses);
    };

    // LIST OF USERS FOR NOTES
    const [userNames, setUserNames] = useState<userDataInterface>([]);

    const fetchUserNames = async () => {
        const usersCollection = collection(db, 'Users');
        const usersSnapshot = await getDocs(usersCollection);
        const names = usersSnapshot.docs.map(doc => {
            const userData = doc.data();
            return {
                displayName: userData.displayName,
                userContactPh: userData.contactPh,
                userCustomerNum: userData.customerNum,
                userEmail: userData.email,
                userFirstName: userData.firstName,
                userLastName: userData.lastName,
                imageUrl: userData.imageUrl,
                role: userData.role
            };
        });
        setUserNames(names);
    };


    // FETCH NOTES ASSIGNED TO USER DATA
    const [assignedUserData, setAssignedUserData] = useState<userDataInterface>([]);

    const getAssignedUserData = async (assignedToUser: string) => {
        if (assignedToUser !== "") {
            const q = query(collection(db, "Users"), where("displayName", "==", newNote.assignedToUser));
            const docSnap = await getDocs(q);
            if (!docSnap.empty) {
                const data = docSnap.docs[0].data();
                setAssignedUserData([
                    {
                        displayName: data.displayName,
                        userContactPh: data.contactPh,
                        userCustomerNum: data.customerNum,
                        userEmail: data.email,
                        userFirstName: data.firstName,
                        userLastName: data.lastName,
                        imageUrl: data.imageUrl,
                        role: data.role
                    }
                ]);
            } else {
                console.log("No user found with the assigned name");
            }
        } else {
            console.log("No user assigned");
        }
    }
    // Send Email via EmailJS
    const sendEmail = (emailFunction, assignedToUser, addedByUser, userFirstName, noteTitle, noteStatus, noteDescription, followUpDate, destinationEmail) => {
        emailjs.init({
            publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
            // Do not allow headless browsers
            blockHeadless: true,
            limitRate: {
                // Set the limit rate for the application
                id: 'app',
                // Allow 1 request per 10s
                throttle: 10000,
            },
        });

        if (followUpDate && emailFunction == 'new') {
            console.log("follow up date found");
            console.log("Follow up date is " + followUpDate);
            const parsedFollowUpDate = Timestamp.fromDate(followUpDate).toDate().toLocaleDateString('en-NZ', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
            })



            const templateParams = {
                assignedToUser: assignedToUser,
                addedByUser: addedByUser,
                userFirstName: userFirstName,
                noteTitle: noteTitle,
                noteStatus: noteStatus,
                noteDescription: noteDescription,
                followUpDate: parsedFollowUpDate,
                destinationEmail: destinationEmail,
                addedByUserEmail: currentUser.email,
                orderId: orderId,
            };


            emailjs.send('service_ovg25ss', 'template_hvuj8bf', templateParams).then(
                (response) => {
                    console.log('SUCCESS!', response.status, response.text);
                    console.log(templateParams);
                },
                (error) => {
                    console.log('FAILED...', error);
                },
            );

        }

        if (followUpDate && emailFunction == 'edit') {
            console.log("follow up date found");
            console.log("Follow up date is " + followUpDate);
            const parsedFollowUpDate = followUpDate.toDate().toLocaleDateString('en-NZ', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
            })



            const templateParams = {
                assignedToUser: assignedToUser,
                addedByUser: addedByUser,
                userFirstName: userFirstName,
                noteTitle: noteTitle,
                noteStatus: noteStatus,
                noteDescription: noteDescription,
                followUpDate: parsedFollowUpDate,
                destinationEmail: destinationEmail,
                addedByUserEmail: currentUser.email,
                orderId: orderId,
            };


            emailjs.send('service_ovg25ss', 'template_hvuj8bf', templateParams).then(
                (response) => {
                    console.log('SUCCESS!', response.status, response.text);
                    console.log(templateParams);
                },
                (error) => {
                    console.log('FAILED...', error);
                },
            );

        }

        if (followUpDate == null) {
            console.log("No follow up date")
            console.log("Follow up date is " + followUpDate);
            const templateParams = {
                assignedToUser: assignedToUser,
                addedByUser: addedByUser,
                userFirstName: userFirstName,
                noteTitle: noteTitle,
                noteStatus: noteStatus,
                noteDescription: noteDescription,
                followUpDate: "N/A",
                destinationEmail: destinationEmail,
                addedByUserEmail: currentUser.email,
                orderId: orderId,
            }

            emailjs.send('service_ovg25ss', 'template_hvuj8bf', templateParams).then(
                (response) => {
                    console.log('SUCCESS!', response.status, response.text);
                    console.log(templateParams);
                },
                (error) => {
                    console.log('FAILED...', error);
                },
            );
        }

    };

    // Fetch shipping API data

    const googleMapsApiKey = process.env.REACT_APP_GOOGLE_API_KEY;

    const [shippingData, setShippingData] = useState<any>([]);

    function unixTimeToDateTime(unixTimestamp) {
        const dateObj = new Date(unixTimestamp * 1000); // convert to milliseconds
        const year = dateObj.getFullYear();
        const month = dateObj.getMonth() + 1; // add 1, since the first month is 0
        const day = dateObj.getDate();
        const hours = dateObj.getHours();
        const minutes = dateObj.getMinutes();
        const seconds = dateObj.getSeconds();

        // return in a formatted string
        return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    }


    // Constants for shipping
    const position = { lat: 53.54992, lng: 10.00678 }
    const [vesselImo, setVesselImo] = useState("");
    const [editVesselImo, setEditVesselImo] = useState(false);

    const [vesselName, setVesselName] = useState("")
    const [editVesselName, setEditVesselName] = useState(false)

    const [shippingCompanyName, setShippingCompanyName] = useState("")
    const [editShippingCompanyName, setEditShippingCompanyName] = useState(false)

    const [shippingTrackingUrl, setShippingTrackingUrl] = useState("")
    const [editShippingTrackingUrl, setEditShippingTrackingUrl] = useState(false)

    const [shipmentStatus, setShipmentStatus] = useState("")
    const [editShipmentStatus, setEditShipmentStatus] = useState(false)

    const [dateDeparted, setDateDeparted] = useState("")
    const [editDateDeparted, setEditDateDeparted] = useState(false)

    const [dateArrived, setDateArrived] = useState("")
    const [editDateArrived, setEditDateArrived] = useState(false)

    const [shippingNotes, setShippingNotes] = useState("")
    const [editShippingNotes, setEditShippingNotes] = useState(false)

    const [consignmentNumber, setConsignmentNumber] = useState("")
    const [editConsignmentNumber, setEditConsignmentNumber] = useState(false)

    const [shipmentVolume, setShipmentVolume] = useState("")
    const [editShipmentVolume, setEditShipmentVolume] = useState(false)

    const [trialShipment, setTrialShipment] = useState(false)
    const [editTrialShipment, setEditTrialShipment] = useState(false)

    const [shipments, setShipments] = useState([]);

    const [NewShipmentForm, setNewShipmentForm] = useState(false)

    const [editingShipmentIndex, setEditingShipmentIndex] = useState<number | null>(null);

    // SHIPMENT STATUS COLOR SETTINGS
    const getShipmentStatusColor = (shipmentStatus: string) => {
        switch (shipmentStatus?.toLowerCase()) {
            case 'delivery completed':
                return 'bg-[#8ecb20]';
            case 'waiting for ttv procedure':
                return 'bg-[#FFA500]';
            case 'ship en-route to destination':
                return 'bg-[#FFA500]';
            case 'ship arrived at destination awaiting vtt':
                return 'bg-[#FFA500]';
            case 'on hold':
                return 'bg-[#FF4433]';
            default:
                return 'bg-gray-500';
        }
    };

    // SHIPMENT DIP TEST STATUS COLOR SETTINGS
    const getDipStatusColor = (dipTestSellerTankStatus: string) => {
        switch (dipTestSellerTankStatus?.toLowerCase()) {
            case 'completed and approved by buyer':
                return 'bg-[#8ecb20]';
            case 'waiting for seller tank dip test':
                return 'bg-[#FFA500]';
            case 'pending':
                return 'bg-[#FFA500]';
            case 'completed but unsatisfactory to buyer':
                return 'bg-[#FF4433]';
            default:
                return 'bg-gray-500';
        }
    };
    const updateShipment = async (updatedShipment: typeof orders[0]['shipping'][0], index: number) => {
        try {
            console.log(updatedShipment)
            const orderRef = doc(db, 'Orders', orderId);
            const orderDoc = await getDoc(orderRef);
            console.log(orderDoc)

            if (orderDoc.exists()) {
                const currentOrder = orderDoc.data();
                if (!currentOrder.shipping || !Array.isArray(currentOrder.shipping)) {
                    currentOrder.shipping = [];
                }
                const updatedShipping = [...currentOrder.shipping];
                updatedShipping[index] = updatedShipment;

                await updateDoc(orderRef, { shipping: updatedShipping });

                const newOrders = [...orders];
                if (!newOrders[0].shipping) {
                    newOrders[0].shipping = [];
                }
                newOrders[0].shipping[index] = updatedShipment;
                setOrders(newOrders);
                setEditingShipmentIndex(null);

                console.log('Shipment updated successfully');
            } else {
                console.error('Order not found');
                throw new Error('Order not found');
            }
        } catch (error) {
            console.error('Error updating shipment:', error);
            throw error;
        }
    };

    const handleUpdateShipmentClick = async (updatedShipment: typeof orders[0]['shipping'][0], index: number) => {
        try {
            await updateShipment(updatedShipment, index);
        } catch (error) {
            // Handle the error, e.g., show an error message to the user
            console.error('Failed to update shipment:', error);
        }
    };

    // Consants for file uploads
    const [file, setFile] = useState(null);
    const [attachments, setAttachments] = useState([]);
    const fileInputRef = useRef(null);

    // Delay function
    function delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    // File upload box change function
    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    // File upload function

    const uploadFile = async () => {
        if (!file) return;
        const fileRef = ref(storage, `orders/${orderId}/${file.name}`);
        await uploadBytes(fileRef, file);
        setFile(null);
        fetchAttachments();
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }

        // Alert stuff

        setNewTitle("Your file was uploaded successfully!")
        setNewMessage(file.name + " was successfully uploaded to Tanekaha Storage")
        await delay(2000);
        setToasterShow(true)
        await delay(3000);
        setToasterShow(false)
    };

    const fetchAttachments = async () => {
        const listRef = ref(storage, `orders/${orderId}`);
        const res = await listAll(listRef);
        const attachmentPromises = res.items.map(async (itemRef) => {
            const url = await getDownloadURL(itemRef);
            return { name: itemRef.name, url };
        });
        const attachmentList = await Promise.all(attachmentPromises);
        setAttachments(attachmentList);
    };

    // File attachment download function
    const downloadFile = async (fileName: string) => {
        const fileRef = ref(storage, `orders/${orderId}/${fileName}`);
        try {
            const url = await getDownloadURL(fileRef);
            const link = document.createElement('a');
            link.href = url;
            link.download = fileName;
            document.body.appendChild(link);
            window.open(url, '_blank');
            document.body.removeChild(link);
        } catch (error) {
            console.error("Error downloading file:", error);
        }
    };

    // File attachment delete file
    const deleteFile = async (fileName) => {
        const fileRef = ref(storage, `orders/${orderId}/${fileName}`);
        await deleteObject(fileRef);
        fetchAttachments();
        // Alert stuff
        setNewTitle("Your file was deleted")
        setNewMessage(fileName + " was deleted")
        await delay(2000);
        setToasterShow(true)
        await delay(3000);
        setToasterShow(false)

    };

    // New shipment constants
    const [newShipment, setNewShipment] = useState({
        shipmentName: '',
        shipmentVolume: '',
        trialShipment: false,
        dipTestSellerTankStatus: '',
        shippingCompanyName: '',
        vesselName: '',
        vesselMMSI: '',
        shipmentNumber: 0,
        shippingTrackingUrl: '',
        shipmentStatus: '',
        dateDeparted: '',
        eta: '',
        shippingNotes: '',
        paymentShipment: false
    });

    // Save new shipment function for new form
    const saveNewShipment = async () => {
        const shipmentNum = Math.floor(Math.random() * 90000) + 10000;
        setNewShipment({ ...newShipment, shipmentNumber: shipmentNum })
        console.log("created new shipment #" + newShipment.shipmentNumber)


        try {

            const orderRef = doc(db, 'Orders', orderId); // Assuming you have orderId available

            await updateDoc(orderRef, {
                shipping: arrayUnion(newShipment)
            });


            setNewShipmentForm(false)
            // Alert stuff

            setNewTitle("New Shipment was added successfully")
            setNewMessage("Shipment #" + newShipment[0].shipmentNumber + " was successfully uploaded to Tanekaha Storage")
            await delay(2000);
            setToasterShow(true)
            await delay(3000);
            setToasterShow(false)

            // Reset the form after successful save
            setNewShipment({
                shipmentName: '',
                shipmentVolume: '',
                trialShipment: false,
                dipTestSellerTankStatus: '',
                shippingCompanyName: '',
                vesselName: '',
                vesselMMSI: '',
                shipmentNumber: shipmentNum,
                shippingTrackingUrl: '',
                shipmentStatus: '',
                dateDeparted: '',
                eta: '',
                shippingNotes: '',
                paymentShipment: false,
            });

            // Optionally, you can add a success message or update the UI
            console.log('New shipment added successfully');
        } catch (error) {
            console.error('Error adding new shipment:', error);
            // Handle the error (e.g., show an error message to the user)
        }
    };

    // Consants for SHIPMENT file uploads
    const [shipmentFile, setShipmentFile] = useState(null);
    const [shipmentsAttachments, setShipmentsAttachments] = useState<Record<string, any>>({});
    const [test, setTest] = useState([]);
    const shipmentFileInputRef = useRef(null);

    // SHIPMENT File upload box change function
    const shipmentHandleFileChange = (e) => {
        setShipmentFile(e.target.files[0]);
    };


    // SHIPMENT File upload function

    const shipmentUploadFile = async (shipmentNum: any) => {
        console.log("shipmentUploadFIle function triggered")
        if (!shipmentFile) return;
        const fileRef = ref(storage, `orders/${orderId}/${shipmentNum}/${shipmentFile.name}`);
        await uploadBytes(fileRef, shipmentFile);
        setShipmentFile(null);
        shipmentFetchAttachments();
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }

        // Alert stuff

        setNewTitle("Your file was uploaded successfully!")
        setNewMessage(shipmentFile.name + " was successfully uploaded to Tanekaha Storage")
        await delay(2000);
        setToasterShow(true)
        await delay(3000);
        setToasterShow(false)
    };

    const shipmentFetchAttachments = async () => {
        const orderRef = ref(storage, `orders/${orderId}`);
        const shipmentFolders = await listAll(orderRef);

        const shipmentAttachmentsMap = {};

        for (const shipmentFolder of shipmentFolders.prefixes) {
            const shipmentRef = ref(storage, shipmentFolder.fullPath);
            const shipmentFiles = await listAll(shipmentRef);

            const shipmentAttachments = [];
            for (const fileRef of shipmentFiles.items) {
                const url = await getDownloadURL(fileRef);
                shipmentAttachments.push({
                    name: fileRef.name,
                    url
                });
            }

            if (shipmentAttachments.length > 0) {
                shipmentAttachmentsMap[shipmentFolder.name] = shipmentAttachments;
            }
        }

        if (Object.keys(shipmentAttachmentsMap).length > 0) {
            setShipmentsAttachments(shipmentAttachmentsMap);

            console.log('TESTING', shipmentsAttachments);
        } else {
            setShipmentsAttachments([]);
        }
        console.log('Shipments Attachments:', shipmentsAttachments);
    };

    // SHIPMENT File attachment download function
    const shipmentDownloadFile = async (fileName: string, shipmentNum: number) => {
        const fileRef = ref(storage, `orders/${orderId}/${shipmentNum}/${fileName}`);
        try {
            const url = await getDownloadURL(fileRef);
            const link = document.createElement('a');
            link.href = url;
            link.download = fileName;
            document.body.appendChild(link);
            window.open(url, '_blank');
            document.body.removeChild(link);
        } catch (error) {
            console.error("Error downloading file:", error);
        }
    };

    // SHIPMENT File attachment delete file
    const shipmentDeleteFile = async (fileName: string, shipmentNum: number) => {
        const fileRef = ref(storage, `orders/${orderId}/${shipmentNum}/${fileName}`);
        await deleteObject(fileRef);
        shipmentFetchAttachments();
        // Alert stuff
        setNewTitle("Your file was deleted")
        setNewMessage(fileName + " was deleted")
        await delay(2000);
        setToasterShow(true)
        await delay(3000);
        setToasterShow(false)

    };

    // SHIPMENT delete
    const deleteShipment = async (shipmentNumber: number) => {
        try {
            const updatedOrders = [...orders];
            const shipmentToDelete = updatedOrders[0].shipping.find(shipment => shipment.shipmentNumber === shipmentNumber);
            console.log('Shipment to delete:', shipmentToDelete);

            if (!shipmentToDelete) {
                throw new Error("Shipment not found");
            }

            // Check if the document exists before updating
            const shipmentRef = doc(db, 'Orders', orderId);
            const docSnap = await getDoc(shipmentRef);

            if (!docSnap.exists()) {
                throw new Error("Document does not exist");
            }

            // Delete the shipment from Firebase
            await updateDoc(shipmentRef, {
                shipping: arrayRemove(shipmentToDelete)
            });

            // Update local state
            setOrders(prevOrders => {
                const newOrders = [...prevOrders];
                newOrders[0].shipping = newOrders[0].shipping.filter(shipment => shipment.shipmentNumber !== shipmentNumber);
                return newOrders;
            });

            // Delete associated files from storage
            const shipmentFolder = `orders/${orderId}/${shipmentNumber}`;
            const folderRef = ref(storage, shipmentFolder);
            const fileList = await listAll(folderRef);

            const deletePromises = fileList.items.map(fileRef => deleteObject(fileRef));
            await Promise.all(deletePromises);

            // Show success message
            setNewTitle("Shipment deleted");
            setNewMessage("Shipment and associated files were successfully deleted");
            setToasterShow(true);
            await delay(3000);
            setToasterShow(false);

        } catch (error) {
            console.error("Error deleting shipment:", error);
            setNewTitle("Error");
            setNewMessage(error instanceof Error ? error.message : "Failed to delete shipment. Please try again.");
            setToasterShow(true);
            await delay(3000);
            setToasterShow(false);
        }
    };


    type ordersInterface = {
        buyer: string;
        buyerAddress: string;
        buyerRepresentation: string;
        comissionBuyer: number;
        comissionSeller: number;
        commodity: string;
        consultant: string;
        customerName: string;
        customerNum: string;
        date: Timestamp;
        following: string;
        fuelType: string;
        grossPrice: number;
        grossPriceCurrency: string;
        guaranteeForContract: string;
        id: number;
        inspectionValidity: string;
        loi: string;
        oneYearContractPayment: string;
        orderNum: string;
        origin: string;
        paymentForTrial: string;
        paymentStatus: string;
        performanceBond: string;
        quantity: number;
        quantityFrequency: string;
        refinery: string;
        refineryDirector: string;
        representedBy: string;
        status: string;
        trialQuantity: number;
        unitQuantity: string;
        globalQuantity: string;
        deliveryTrial: string;
        deliveryRegular: string;
        loadingPort: string;
        trackingURL: string;
        firstDelivery: string;
        priceGrossBuyer: string;
        termsContract: string;
        tanekahaCommission: string;
        wholesalePrice: string;
        notes: {
            addedByUser: string;
            assignedToUser: string;
            dateAdded: Timestamp;
            dateAssigned: Timestamp;
            lastEdited: Timestamp;
            lastEditedBy: string;
            noteDescription: string;
            noteTitle: string;
            noteStatus: string;
            followUpDate: Timestamp;
        }[];
        shipping: {
            shipmentName: string;
            shipmentNumber: number;
            vesselMMSI: string;
            vesselName: string;
            shippingCompanyName: string;
            shippingTrackingUrl: string;
            shipmentStatus: string;
            dateDeparted: string;
            dateArrived: string;
            shippingNotes: string;
            consignmentNumber: string;
            shipmentVolume: string;
            orderId: string;
            trialShipment: boolean;
            portOfOrigin: string;
            portOfOriginCode: string;
            destinationPort: string;
            destinationPortCode: string;
            eta: string;
            positionLongitude: number;
            positionLatitude: number;
            dipTestSellerTankStatus: string;
            paymentShipment: boolean;
        }[];

    }[];

    const getDataFirestore = async () => {
        setLoading(true);
        const q = query(collection(db, "Orders"), where("id", "==", Number(orderId)));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
            const orderDoc = querySnapshot.docs[0];
            const data = orderDoc.data();
            setOrders([{
                buyer: data.buyer,
                buyerAddress: data.buyerAddress,
                buyerRepresentation: data.buyerRepresentation,
                comissionBuyer: data.comissionBuyer,
                comissionSeller: data.comissionSeller,
                commodity: data.commodity,
                consultant: data.consultant,
                customerName: data.customerName,
                customerNum: data.customerNum,
                date: data.date,
                following: data.following,
                fuelType: data.fuelType,
                grossPrice: data.grossPrice,
                grossPriceCurrency: data.grossPriceCurrency,
                guaranteeForContract: data.guaranteeForContract,
                id: data.id,
                inspectionValidity: data.inspectionValidity,
                loi: data.loi,
                oneYearContractPayment: data.oneYearContractPayment,
                orderNum: data.orderNum,
                origin: data.origin,
                paymentForTrial: data.paymentForTrial,
                paymentStatus: data.paymentStatus,
                performanceBond: data.performanceBond,
                quantity: data.quantity,
                quantityFrequency: data.quantityFrequency,
                refinery: data.refinery,
                refineryDirector: data.refineryDirector,
                representedBy: data.representedBy,
                status: data.status,
                trialQuantity: data.trialQuantity,
                unitQuantity: data.unitQuantity,
                notes: data.notes,
                globalQuantity: data.globalQuantity,
                deliveryTrial: data.deliveryTrial,
                deliveryRegular: data.deliveryRegular,
                loadingPort: data.loadingPort,
                trackingURL: data.trackingURL,
                firstDelivery: data.firstDelivery,
                priceGrossBuyer: data.priceGrossBuyer,
                termsContract: data.termsContract,
                tanekahaCommission: data.tanekahaCommission,
                shipping: data.shipping,
                wholesalePrice: data.wholesalePrice,

            }]);
            setBuyerValue(data.buyer)
            setBuyerAddressValue(data.buyerAddress)
            setBuyerRepresentationValue(data.buyerRepresentation)
            setComissionBuyerValue(data.comissionBuyer)
            setComissionSellerValue(data.comissionSeller)
            setCommodityValue(data.commodity)
            setConsultantValue(data.consultant)
            setCustomerNameValue(data.customerName)
            setCustomerNumValue(data.customerNum)
            setDateValue(data.date)
            setFollowingValue(data.following)
            setFuelTypeValue(data.fuelType)
            setGrossPriceValue(data.grossPrice)
            setGrossPriceCurrencyValue(data.grossPriceCurrencyValue)
            setGuaranteeForContractValue(data.guaranteeForContract)
            setIdValue(data.id)
            setInspectionValidityValue(data.inspectionValidity)
            setLoiValue(data.loi)
            setOneYearContractPaymentValue(data.oneYearContractPayment)
            setOrderNumValue(data.orderNum)
            setOriginValue(data.origin)
            setPaymentForTrialValue(data.paymentForTrial)
            setPaymentStatusValue(data.paymentStatus)
            setPerformanceBondValue(data.performanceBond)
            setQuantityValue(data.quantity)
            setQuantityFrequencyValue(data.quantityFrequency)
            setRefineryValue(data.refinery)
            setRefineryDirectorValue(data.refineryDirector)
            setRepresentedByValue(data.representedBy)
            setStatusValue(data.status)
            setTrialQuantityValue(data.trialQuantity)
            setUnitQuantityValue(data.unitQuantity)
            setGlobalQuantityValue(data.globalQuantity)
            setDeliveryTrialValue(data.deliveryTrial)
            setDeliveryRegularValue(data.deliveryRegular)
            setLoadingPortValue(data.loadingPort)
            setTrackingURLValue(data.trackingURL)
            setFirstDeliveryValue(data.firstDelivery)
            setPriceGrossBuyerValue(data.priceGrossBuyer)
            setTermsContractValue(data.termsContract)
            setTanekahaCommissionValue(data.tanekahaCommission)
            setWholesalePriceValue(data.wholesalePrice)
            setShipments(data.shipping)

        } else {
            console.log("No matching document found");
            setOrders([]);
        }
        setLoading(false);
    };

    const LoadingEvent = () => (
        <Loading />
    );

    // MAIN USE EFFECT
    useEffect(() => {
        if (userEmail === undefined) {
            navigate('/Portal')
            console.log("Current user is not authorised")
        } else {
            setLoading(true);
            //Get order data from firestore
            getDataFirestore();
            //Get current logged on user data from firestore
            getUserData();
            //Get all users from firestore
            fetchUserNames();
            //Get all customer names from firestore
            fetchCustomerNames();
            // fetch shipment attachments
            shipmentFetchAttachments();
            //fetch attachments from firestore
            fetchAttachments();

            console.log(userEmail + " is logged on currently")
            setLoading(false);
        }
    }, [currentUser, orderId, newNote.assignedToUser, NewShipmentForm, editingNoteIndex]);

    // NOTE STATUS COLOR SETTINGS
    const getStatusColor = (status: string) => {
        switch (status?.toLowerCase()) {
            case 'closed completed':
                return 'bg-green-500';
            case 'in progress':
                return 'bg-yellow-500';
            case 'open':
                return 'bg-green-500';
            case 'pending':
                return 'bg-red-500';
            case 'on hold':
                return 'bg-red-500';
            case 'closed incomplete':
                return 'bg-red-500';
            default:
                return 'bg-gray-500';
        }
    };

    // NOTE STATUS OPTIONS
    const statusOptions = [
        'open',
        'in progress',
        'pending',
        'on hold',
        'closed incomplete',
        'closed completed'
    ];

    // NEW NOTE FUNCTION
    const saveNewNote = async () => {
        const q = query(collection(db, "Orders"), where("id", "==", Number(orderId)));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
            const orderDoc = querySnapshot.docs[0];
            const orderRef = doc(db, "Orders", orderDoc.id);
            const newNoteData = {
                noteTitle: newNote.noteTitle,
                noteDescription: newNote.noteDescription,
                assignedToUser: newNote.assignedToUser,
                addedByUser: userData[0].userFirstName + " " + userData[0].userLastName,
                noteStatus: newNote.noteStatus,
                dateAdded: Timestamp.now().toDate(),
                dateAssigned: Timestamp.now().toDate(),
                lastEdited: Timestamp.now().toDate(),
                lastEditedUser: userData[0].userFirstName + " " + userData[0].userLastName,
                followUpDate: newNote.followUpDate
            };
            const emailFunction = "new"

            try {
                await getAssignedUserData(newNoteData.assignedToUser);
                sendEmail(
                    emailFunction,
                    newNoteData.assignedToUser,
                    newNoteData.addedByUser,
                    assignedUserData[0]?.userFirstName,
                    newNoteData.noteTitle,
                    newNoteData.noteStatus,
                    newNoteData.noteDescription,
                    newNoteData.followUpDate,
                    assignedUserData[0]?.userEmail
                );
                await updateDoc(orderRef, {
                    notes: arrayUnion(newNoteData)
                });
                setNewNote({ noteTitle: '', noteDescription: '', assignedToUser: '', noteStatus: '', followUpDate: Timestamp.now().toDate() });
                setShowNewNoteForm(false);

                getDataFirestore(); // Refresh the order data
                // Alert stuff
                setToasterShow(true)
                setNewTitle("You have added a new note to the order")
                setNewMessage(newNote.noteTitle + " was added successfully")
                await delay(5000);
                setToasterShow(false)
            } catch (error) {
                console.error("Error adding new note: ", error);
            }
        } else {
            console.error("No matching order found");
        }
    };

    // NOTE DELETE FUNCTION
    const deleteNote = async (index: number) => {
        const q = query(collection(db, "Orders"), where("id", "==", Number(orderId)));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
            const orderDoc = querySnapshot.docs[0];
            const orderRef = doc(db, "Orders", orderDoc.id);
            const updatedNotes = [...orders[0].notes];
            updatedNotes.splice(index, 1);

            try {
                await updateDoc(orderRef, {
                    notes: updatedNotes
                });

                getDataFirestore(); // Refresh the order data
                // Alert stuff
                setNewTitle("You have removed a note from the order")
                setNewMessage(newNote.noteTitle + " was removed successfully")
                await delay(2000);
                setToasterShow(true)
                await delay(5000);
                setToasterShow(false)
            } catch (error) {
                console.error("Error deleting note: ", error);
            }
        }
    };

    // NOTE EDITOR FUNCTION
    const editNote = async (index: number, updatedNote: any) => {
        const q = query(collection(db, "Orders"), where("id", "==", Number(orderId)));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
            const orderDoc = querySnapshot.docs[0];
            const orderRef = doc(db, "Orders", orderDoc.id);
            const updatedNotes = [...orders[0].notes];
            updatedNotes[index] = { ...updatedNotes[index], ...updatedNote };
            const emailFunction = "edit"

            try {
                await getAssignedUserData(updatedNotes[index].assignedToUser);
                console.log("Updated Notes:", updatedNotes);
                console.log("assigned user currently: " + assignedUserData[0]?.userEmail)
                await updateDoc(orderRef, {
                    notes: updatedNotes,
                    lastEdited: Timestamp.now(),
                    lastEditedUser: userData[0].displayName
                });

                setEditingNoteIndex(null);
                sendEmail(
                    emailFunction,
                    updatedNotes[index].assignedToUser,
                    updatedNotes[index].addedByUser,
                    assignedUserData[0].userFirstName,
                    updatedNotes[index].noteTitle,
                    updatedNotes[index].noteStatus,
                    updatedNotes[index].noteDescription,
                    updatedNotes[index].followUpDate,
                    assignedUserData[0].userEmail
                );
                getDataFirestore();
                // Alert stuff
                setNewTitle("You have edited a note from the order")
                setNewMessage("note was edited successfully")
                await delay(2000);
                setToasterShow(true)
                await delay(5000);
                setToasterShow(false)
            } catch (error) {
                console.error("Error editing note: ", error);
            }
        }
    };

    const saveRow = async (field: string) => {
        let updatedOrder: any;

        const newOrders = orders.map((order, index) => {
            if (index === 0) {
                switch (field) {
                    case 'buyer':
                        setEditBuyer(false);
                        console.log(buyerValue);
                        return { ...order, buyer: buyerValue, customerName: buyerValue };
                    case 'buyerAddress':
                        setEditBuyerAddress(false);
                        return { ...order, buyerAddress: buyerAddressValue };
                    case 'buyerRepresentation':
                        setEditBuyerRepresentation(false);
                        return { ...order, buyerRepresentation: buyerRepresentationValue };
                    case 'comissionBuyer':
                        setEditComissionBuyer(false);
                        setEditComissionSeller(false);
                        return { ...order, comissionBuyer: Number(comissionBuyerValue), comissionSeller: Number(comissionSellerValue) };
                    case 'comissionSeller':
                        setEditComissionSeller(false);
                        return { ...order, comissionSeller: Number(comissionSellerValue) };
                    case 'commodity':
                        setEditCommodity(false);
                        return { ...order, commodity: commodityValue };
                    case 'consultant':
                        setEditConsultant(false);
                        return { ...order, consultant: consultantValue };
                    case 'customerName':
                        setEditCustomerName(false);
                        return { ...order, customerName: customerNameValue };
                    case 'customerNum':
                        setEditCustomerNum(false);
                        return { ...order, customerNum: customerNumValue };
                    case 'date':
                        setEditDate(false);
                        return { ...order, date: dateValue };
                    case 'following':
                        setEditFollowing(false);
                        return { ...order, following: followingValue };
                    case 'fuelType':
                        setEditFuelType(false);
                        return { ...order, fuelType: fuelTypeValue };
                    case 'globalQuantity':
                        setEditGlobalQuantity(false);
                        return { ...order, globalQuantity: globalQuantityValue };
                    case 'grossPrice':
                        setEditGrossPrice(false);
                        return { ...order, grossPrice: Number(grossPriceValue) };
                    case 'grossPriceCurrency':
                        setEditGrossPriceCurrency(false);
                        return { ...order, grossPriceCurrency: grossPriceCurrencyValue };
                    case 'guaranteeForContract':
                        setEditGuaranteeForContract(false);
                        return { ...order, guaranteeForContract: guaranteeForContractValue };
                    case 'inspectionValidity':
                        setEditInspectionValidity(false);
                        return { ...order, inspectionValidity: inspectionValidityValue };
                    case 'loi':
                        setEditLoi(false);
                        return { ...order, loi: loiValue };
                    case 'oneYearContractPayment':
                        setEditOneYearContractPayment(false);
                        return { ...order, oneYearContractPayment: oneYearContractPaymentValue };
                    case 'orderNum':
                        setEditOrderNum(false);
                        return { ...order, orderNum: orderNumValue };
                    case 'origin':
                        setEditOrigin(false);
                        return { ...order, origin: originValue };
                    case 'representedBy':
                        setEditRepresentedBy(false);
                        return { ...order, representedBy: representedByValue };
                    case 'trialQuantity':
                        setEditTrialQuantity(false);
                        return { ...order, trialQuantity: trialQuantityValue };
                    case 'refinery':
                        setEditRefinery(false);
                        return { ...order, refinery: refineryValue };
                    case 'refineryDirector':
                        setEditRefineryDirector(false);
                        return { ...order, refineryDirector: refineryDirectorValue };
                    case 'quantity':
                        setEditQuantity(false);
                        return { ...order, quantity: Number(quantityValue) || 0 };
                    case 'termsContract':
                        setEditTermsContract(false);
                        return { ...order, termsContract: termsContractValue };
                    case 'deliveryTrial':
                        setEditDeliveryTrial(false);
                        return { ...order, deliveryTrial: deliveryTrialValue };
                    case 'deliveryRegular':
                        setEditDeliveryRegular(false);
                        return { ...order, deliveryRegular: deliveryRegularValue };
                    case 'firstDelivery':
                        setEditFirstDelivery(false);
                        return { ...order, firstDelivery: firstDeliveryValue };
                    case 'loadingPort':
                        setEditLoadingPort(false);
                        return { ...order, loadingPort: loadingPortValue };
                    case 'paymentForTrial':
                        setEditPaymentForTrial(false);
                        return { ...order, paymentForTriaL: paymentForTrialValue };
                    case 'performanceBond':
                        setEditPerformanceBond(false);
                        return { ...order, performanceBond: performanceBondValue };
                    case 'status':
                        setEditStatus(false);
                        return { ...order, status: statusValue };
                    case 'paymentStatus':
                        setEditPaymentStatus(false);
                        return { ...order, paymentStatus: paymentStatusValue };
                    case 'tanekahaCommission':
                        setEditTanekahaCommission(false);
                        return { ...order, tanekahaCommission: tanekahaCommissionValue };
                    case 'wholesalePrice':
                        setEditWholesalePrice(false);
                        return { ...order, wholesalePrice: wholesalePriceValue };
                    default: return order;
                }
            }
            return order;
        });

        updatedOrder = newOrders[0];
        setOrders(newOrders);

        console.log(updatedOrder)
        try {
            if (updatedOrder) {
                const ordersRef = collection(db, 'Orders');
                const q = query(ordersRef, where('id', '==', Number(orderId)));
                const querySnapshot = await getDocs(q);
                console.log("orderid is " + orderId)
                if (!querySnapshot.empty) {
                    const doc = querySnapshot.docs[0];
                    if (doc.exists()) {
                        await updateDoc(doc.ref, { [field]: updatedOrder[field] });
                        setNewMessage(`Successfully updated ${field}`);
                        setNewTitle('Success');
                        setToasterShow(true);
                        await delay(5000);
                        setToasterShow(false);
                    } else {
                        throw new Error('Document does not exist');
                    }
                } else {
                    throw new Error('Document not found');
                }
            }
        } catch (error) {
            console.error("Error updating document: ", error);
            setNewMessage(`Failed to update ${field}`);
            setNewTitle('Error');
            setToasterShow(true);
            await delay(5000);
            setToasterShow(false);
        }
    };

    const deleteOrder = async () => {
        try {
            const ordersRef = collection(db, 'Orders')
            const q = query(ordersRef, where('id', '==', Number(orderId)))
            const querySnapshot = await getDocs(q)

            if (!querySnapshot.empty) {
                const doc = querySnapshot.docs[0]
                if (doc.exists()) {
                    await deleteDoc(doc.ref)
                    setNewMessage('Successfully deleted order')
                    setNewTitle('Success')
                    setToasterShow(true)
                    await delay(5000)
                    setToasterShow(false)
                    navigate('/CurrentOrders')
                    // Redirect or update UI as needed after deletion
                } else {
                    throw new Error('Document does not exist')
                }
            } else {
                throw new Error('Document not found')
            }
        } catch (error) {
            console.error("Error deleting document: ", error)
            setNewMessage('Failed to delete order')
            setNewTitle('Error')
            setToasterShow(true)
            await delay(5000)
            setToasterShow(false)
        }
    }


    return (
        <>
            {toasterShow ? (
                <Notifications newMessage={newMessage} newTitle={newTitle} />
            ) : (
                null
            )}
            {loading ? (
                <LoadingEvent />
            ) : (
                <div className="inset-y-0 right-0 lg:relative sm:flex flex-col min-h-screen w-full bg-tanekaha3">
                    <div>
                        <div className="relative px-4 sm:px-0 sm:right-0 lg:mt-20 lg:right-0 mx-auto text-center">
                            <p className="relative top-1 font-bebas mx-auto leading-7 text-gray-900 text-center lg:text-4xl md: text-2xl sm:text-xl max-sm:text-lg">ORDER# {orderId}</p>
                            <p className="mt-1 max-w-2xl text-sm font-bebas leading-6 text-white mx-auto text-center">CUSTOMER PURCHASE ORDER INFORMATION</p>
                            <span>
                                <button
                                    className="mt-3 mb-3 rounded-md bg-white px-2 py-1.5 text-sm font-regular text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-red-500 hover:text-white"
                                    onClick={() => deleteOrder()}
                                >
                                    <FontAwesomeIcon icon={faTrash} />&nbsp;Delete Order
                                </button>
                            </span>
                        </div>
                        <div className="relative mx-auto mt-6 border-t border-gray-100 lg:w-1/2 lg:left-20 bottom md:w-3/4 sm:w-3/4 max-sm:w-4/5 ">
                            <dl className="divide-y divide-gray-100">
                                {editStatus ? (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-regular leading-6 text-gray-900">Order Status</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <select className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-main sm:max-w-xs sm:text-sm sm:leading-6 mb-2" value={statusValue} onChange={(e) => setStatusValue(e.target.value)}>
                                                <option value="Stage 0: Awaiting Mandate DD">Stage 0: Awaiting Mandate DD</option>
                                                <option value="Stage 1:Awaiting LOI">Stage 1: Awaiting LOI</option>
                                                <option value="Stage 2: Awaiting Seller FCO">Stage 2: Awaiting Seller FCO</option>
                                                <option value="Stage 3: Awaiting Buyer Signed FCO">Stage 3: Awaiting Buyer Signed FCO </option>
                                                <option value="Stage 4: Awaiting Buyer to send FCO + BCL or MT799">Stage 4: Awaiting Buyer to send FCO + BCL or MT799 </option>
                                                <option value="Stage 5: Awaiting Seller POP to Buyer's bank">Stage 5: Awaiting Seller POP to Buyer's bank</option>
                                                <option value="Stage 6: Awaiting Seller Draft Contract">Stage 6: Awaiting Seller Draft Contract</option>
                                                <option value="Stage 7: Awaiting completed final contract">Stage 7: Awaiting completed final contract</option>
                                                <option value="Stage 8: Awaiting SBLC from Buyer Bank">Stage 8: Awaiting SBLC from Buyer Bank </option>
                                                <option value="Stage 9: Awaiting PB from Seller's Bank">Stage 9: Awaiting PB from Seller's Bank </option>
                                                <option value="Stage 10: Awaiting Buyer Confirmation of Operational Payment Instrument">Stage 10: Awaiting Buyer Confirmation of Operational Payment Instrument</option>
                                                <option value="Stage 11: Awaiting Buyer visit to Loading Port">Stage 11: Awaiting Buyer visit to Loading Port </option>
                                                <option value="Stage 12: Awaiting Seller shipment">Stage 12: Awaiting Seller shipment </option>
                                                <option value="Stage 13: Awaiting Clearance of Shipment and CIQ Documentation from port of Destination">Stage 13: Awaiting Clearance of Shipment and CIQ Documentation from port of Destination</option>
                                                <option value="Stage 14: Awaiting Buyer Payment">Stage 14: Awaiting Buyer Payment </option>
                                                <option value="IN PROGRESS: Order Ongoing">IN PROGRESS: Order Ongoing</option>
                                                <option value="CLOSED: Order Completed">CLOSED: Order Completed</option>
                                                <option value="CLOSED: Order not proceeding">CLOSED: Order not proceeding</option>
                                            </select>

                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => saveRow('status')} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faFloppyDisk} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>

                                ) : (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-regular leading-6 text-gray-900">Order Status</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <span className="flex-grow">{orders[0]?.status}</span>
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={(e) => setEditStatus(true)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faPencil} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                )}

                                {editBuyer ? (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-regular leading-6 text-gray-900">Buyer Name</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <select
                                                id="buyerName"
                                                name="buyerName"
                                                value={buyerValue}
                                                onChange={(e) => setBuyerValue(e.target.value)}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-main sm:max-w-xs sm:text-sm sm:leading-6 mb-2"
                                            >
                                                <option value="">Select a customer</option>
                                                {customerNames.map((name, index) => (
                                                    <option key={index} value={name}>
                                                        {name}
                                                    </option>
                                                ))}
                                            </select>
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => saveRow('buyer')} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faFloppyDisk} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>

                                ) : (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-regular leading-6 text-gray-900">Buyer Name</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <span className="flex-grow">{orders[0]?.buyer}</span>
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={(e) => setEditBuyer(true)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faPencil} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                )}

                                {editBuyerAddress ? (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-regular leading-6 text-gray-900">Buyer Address</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <input type="text" className="flex-grow text-black" value={buyerAddressValue} onChange={(e) => setBuyerAddressValue(e.target.value)} />
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => saveRow('buyerAddress')} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faFloppyDisk} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                ) : (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-regular leading-6 text-gray-900">Buyer Address</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <span className="flex-grow">{orders[0]?.buyerAddress}</span>
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={(e) => setEditBuyerAddress(true)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faPencil} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                )}

                                {editBuyerRepresentation ? (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-regular leading-6 text-gray-900">Represented By</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <input type="text" className="flex-grow text-black" value={buyerRepresentationValue} onChange={(e) => setBuyerRepresentationValue(e.target.value)} />
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => saveRow('buyerRepresentation')} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faFloppyDisk} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                ) : (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-regular leading-6 text-gray-900">Represented By</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <span className="flex-grow">{orders[0]?.buyerRepresentation}</span>
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={(e) => setEditBuyerRepresentation(true)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faPencil} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                )}

                                {editConsultant ? (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-regular leading-6 text-gray-900">Consulting party</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <input type="text" className="flex-grow text-black" value={consultantValue} onChange={(e) => setConsultantValue(e.target.value)} />
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => saveRow('consultant')} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faFloppyDisk} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                ) : (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-regular leading-6 text-gray-900">Consulting party</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <span className="flex-grow">{orders[0]?.consultant}</span>
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={(e) => setEditConsultant(true)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faPencil} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                )}

                                {editRepresentedBy ? (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-regular leading-6 text-gray-900">Consultant representative</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <input type="text" className="flex-grow text-black" value={representedByValue} onChange={(e) => setRepresentedByValue(e.target.value)} />
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => saveRow('representedBy')} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faFloppyDisk} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                ) : (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-regular leading-6 text-gray-900">Consultant representative</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <span className="flex-grow">{orders[0]?.representedBy}</span>
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={(e) => setEditRepresentedBy(true)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faPencil} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                )}

                                {editFollowing ? (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-regular leading-6 text-gray-900">Following</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <input type="text" className="flex-grow text-black" value={followingValue} onChange={(e) => setFollowingValue(e.target.value)} />
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => saveRow('following')} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faFloppyDisk} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                ) : (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-regular leading-6 text-gray-900">Following</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <span className="flex-grow">{orders[0]?.following}</span>
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={(e) => setEditFollowing(true)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faPencil} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                )}

                                {editRefinery ? (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-regular leading-6 text-gray-900">Refinery</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <input type="text" className="flex-grow text-black" value={refineryValue} onChange={(e) => setRefineryValue(e.target.value)} />
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => saveRow('refinery')} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faFloppyDisk} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                ) : (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-regular leading-6 text-gray-900">Refinery</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <span className="flex-grow">{orders[0]?.refinery}</span>
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={(e) => setEditRefinery(true)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faPencil} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                )}

                                {editRefineryDirector ? (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-regular leading-6 text-gray-900">Refinery Director</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <input type="text" className="flex-grow text-black" value={refineryDirectorValue} onChange={(e) => setRefineryDirectorValue(e.target.value)} />
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => saveRow('refineryDirector')} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faFloppyDisk} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                ) : (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-regular leading-6 text-gray-900">Refinery Director</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <span className="flex-grow">{orders[0]?.refineryDirector}</span>
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={(e) => setEditRefineryDirector(true)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faPencil} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                )}

                                {editCommodity ? (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-regular leading-6 text-gray-900">Commodity</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <input type="text" className="flex-grow text-black" value={commodityValue} onChange={(e) => setCommodityValue(e.target.value)} />
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => saveRow('commodity')} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faFloppyDisk} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                ) : (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-regular leading-6 text-gray-900">Commodity</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <span className="flex-grow">{orders[0]?.commodity}</span>
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={(e) => setEditCommodity(true)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faPencil} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                )}


                                {editWholesalePrice ? (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-regular leading-6 text-gray-900">Wholesale Price (Per MT)</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <input type="text" className="flex-grow text-black" value={wholesalePriceValue} onChange={(e) => setWholesalePriceValue(e.target.value)} />
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => saveRow('wholesalePrice')} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faFloppyDisk} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                ) : (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-regular leading-6 text-gray-900">Wholesale Price (USD Per MT)</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <span className="flex-grow">{"$" + orders[0]?.wholesalePrice + " USD"}</span>
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={(e) => setEditWholesalePrice(true)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faPencil} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                )}

                                {editTrialQuantity ? (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-regular leading-6 text-gray-900">Quantity for Trial</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <input type="number" className="flex-grow text-black" value={trialQuantityValue} onChange={(e) => setTrialQuantityValue(parseInt(e.target.value))} />
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => saveRow('trialQuantity')} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faFloppyDisk} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                ) : (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-regular leading-6 text-gray-900">Quantity for Trial</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <span className="flex-grow">{orders[0]?.trialQuantity + orders[0]?.unitQuantity}</span>
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={(e) => setEditTrialQuantity(true)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faPencil} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                )}

                                {editQuantity ? (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-regular leading-6 text-gray-900">Quantity for Contract</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <input type="text" className="flex-grow text-black" value={quantityValue} onChange={(e) => setQuantityValue(Number(e.target.value))} />                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => saveRow('quantity')} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faFloppyDisk} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                ) : (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-regular leading-6 text-gray-900">Quantity for Contract</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <span className="flex-grow">{orders[0]?.quantity + orders[0]?.unitQuantity + " " + orders[0]?.quantityFrequency}</span>
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={(e) => setEditQuantity(true)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faPencil} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                )}

                                {editTermsContract ? (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-regular leading-6 text-gray-900">Terms Contract</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <input type="text" className="flex-grow text-black" value={termsContractValue} onChange={(e) => setTermsContractValue(e.target.value)} />
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => saveRow('termsContract')} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faFloppyDisk} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                ) : (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-regular leading-6 text-gray-900">Terms Contract</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <span className="flex-grow">{orders[0]?.termsContract}</span>
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={(e) => setEditTermsContract(true)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faPencil} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                )}


                                {editGlobalQuantity ? (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-regular leading-6 text-gray-900">Global Quantity</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <input type="text" className="flex-grow text-black" value={globalQuantityValue} onChange={(e) => setGlobalQuantityValue(e.target.value)} />
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => saveRow('globalQuantity')} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faFloppyDisk} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>

                                ) : (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-regular leading-6 text-gray-900">Global Quantity</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <span className="flex-grow">{orders[0]?.globalQuantity}</span>
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => setEditGlobalQuantity(true)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faPencil} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                )}

                                {editOrigin ? (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-regular leading-6 text-gray-900">Origin</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <input type="text" className="flex-grow text-black" value={originValue} onChange={(e) => setOriginValue(e.target.value)} />
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => saveRow('origin')} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faFloppyDisk} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                ) : (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-regular leading-6 text-gray-900">Origin</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <span className="flex-grow">{orders[0]?.origin}</span>
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => setEditOrigin(true)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faPencil} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                )}

                                {editDeliveryTrial ? (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-regular leading-6 text-gray-900">Trial Delivery</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <input type="text" className="flex-grow text-black" value={deliveryTrialValue} onChange={(e) => setDeliveryTrialValue(e.target.value)} />
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => saveRow('deliveryTrial')} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faFloppyDisk} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                ) : (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-regular leading-6 text-gray-900">Trial Delivery</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <span className="flex-grow">{orders[0]?.deliveryTrial}</span>
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => setEditDeliveryTrial(true)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faPencil} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                )}


                                {editDeliveryRegular ? (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-regular leading-6 text-gray-900">Regular Delivery</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <input type="text" className="flex-grow text-black" value={deliveryRegularValue} onChange={(e) => setDeliveryRegularValue(e.target.value)} />
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => saveRow('deliveryRegular')} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faFloppyDisk} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>

                                ) : (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-regular leading-6 text-gray-900">Regular Delivery</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <span className="flex-grow">{orders[0]?.deliveryRegular}</span>
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => setEditDeliveryRegular(true)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faPencil} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                )}

                                {editFirstDelivery ? (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-regular leading-6 text-gray-900">First Delivery</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <input type="text" className="flex-grow text-black" value={firstDeliveryValue} onChange={(e) => setFirstDeliveryValue(e.target.value)} />
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => saveRow('firstDelivery')} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faFloppyDisk} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                ) : (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-regular leading-6 text-gray-900">First Delivery</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <span className="flex-grow">{orders[0]?.firstDelivery}</span>
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => setEditFirstDelivery(true)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faPencil} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                )}

                                {editLoadingPort ? (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-regular leading-6 text-gray-900">Loading Port</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <input type="text" className="flex-grow text-black" value={loadingPortValue} onChange={(e) => setLoadingPortValue(e.target.value)} />
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => saveRow('loadingPort')} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faFloppyDisk} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                ) : (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-regular leading-6 text-gray-900">Loading Port</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <span className="flex-grow">{orders[0]?.loadingPort}</span>
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => setEditLoadingPort(true)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faPencil} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                )}
                                {editGrossPrice ? (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-regular leading-6 text-gray-900">Buyer Gross Price</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <input type="text" className="flex-grow text-black" value={grossPriceValue} onChange={(e) => setGrossPriceValue(e.target.value)} />
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => saveRow('grossPrice')} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faFloppyDisk} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                ) : (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-regular leading-6 text-gray-900">Buyer Gross Price</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <span className="flex-grow">{"$" + orders[0]?.grossPrice + " USD"}</span>
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => setEditGrossPrice(true)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faPencil} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                )}

                                {editPaymentForTrial ? (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-regular leading-6 text-gray-900">Payment For Trial</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <input type="text" className="flex-grow text-black" value={paymentForTrialValue} onChange={(e) => setPaymentForTrialValue(e.target.value)} />
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => saveRow('paymentForTrial')} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faFloppyDisk} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                ) : (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-regular leading-6 text-gray-900">Payment For Trial</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <span className="flex-grow">{orders[0]?.paymentForTrial}</span>
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => setEditPaymentForTrial(true)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faPencil} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                )}

                                {editOneYearContractPayment ? (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-regular leading-6 text-gray-900">One Year Contract</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <input type="text" className="flex-grow text-black" value={oneYearContractPaymentValue} onChange={(e) => setOneYearContractPaymentValue(e.target.value)} />
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => saveRow('oneYearContractPayment')} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faFloppyDisk} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                ) : (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-regular leading-6 text-gray-900">One Year Contract</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <span className="flex-grow">{orders[0]?.oneYearContractPayment}</span>
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => setEditOneYearContractPayment(true)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faPencil} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                )}

                                {editGuaranteeForContract ? (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-regular leading-6 text-gray-900">Guarantee for Contract</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <input type="text" className="flex-grow text-black" value={guaranteeForContractValue} onChange={(e) => setGuaranteeForContractValue(e.target.value)} />
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => saveRow('guaranteeForContract')} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faFloppyDisk} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                ) : (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-regular leading-6 text-gray-900">Guarantee for Contract</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <span className="flex-grow">{orders[0]?.guaranteeForContract}</span>
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => setEditGuaranteeForContract(true)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faPencil} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                )}

                                {editPerformanceBond ? (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-regular leading-6 text-gray-900">Performance Bond</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <input type="text" className="flex-grow text-black" value={performanceBondValue} onChange={(e) => setPerformanceBondValue(e.target.value)} />
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => saveRow('performanceBond')} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faFloppyDisk} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                ) : (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-regular leading-6 text-gray-900">Performance Bond</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <span className="flex-grow">{orders[0]?.performanceBond}</span>
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => setEditPerformanceBond(true)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faPencil} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                )}

                                {editInspectionValidity ? (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-regular leading-6 text-gray-900">Inspection Validity</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <input type="text" className="flex-grow text-black" value={inspectionValidityValue} onChange={(e) => setInspectionValidityValue(e.target.value)} />
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => saveRow('inspectionValidity')} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faFloppyDisk} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                ) : (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-regular leading-6 text-gray-900">Inspection Validity</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <span className="flex-grow">{orders[0]?.inspectionValidity}</span>
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => setEditInspectionValidity(true)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faPencil} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                )}

                                {editComissionBuyer ? (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-regular leading-6 text-gray-900">Comission (USD)</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <input type="text" className="flex-grow text-black" value={comissionBuyerValue} onChange={(e) => setComissionBuyerValue(Number(e.target.value))} placeholder="Buyer side" />
                                            <input type="text" className="flex-grow text-black ml-2" value={comissionSellerValue} onChange={(e) => setComissionSellerValue(Number(e.target.value))} placeholder="Seller side" />
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => saveRow('comissionBuyer')} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faFloppyDisk} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                ) : (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-regular leading-6 text-gray-900">Comission (USD)</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <span className="flex-grow">{"Buyer side: $" + orders[0]?.comissionBuyer + " USD/MT | Seller side: $" + orders[0]?.comissionSeller + " USD/MT"}</span>
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => setEditComissionBuyer(true)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faPencil} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                )}

                                {editTanekahaCommission ? (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-regular leading-6 text-gray-900">Tanekaha Profit Per MT (USD)</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <input type="text" className="flex-grow text-black" value={tanekahaCommissionValue} onChange={(e) => setTanekahaCommissionValue(e.target.value)} />
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => saveRow('tanekahaCommission')} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faFloppyDisk} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                ) : (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-regular leading-6 text-gray-900">Tanekaha Profit Per MT (USD)</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <span className="flex-grow">{"$" + orders[0]?.tanekahaCommission + " USD"}</span>
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => setEditTanekahaCommission(true)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faPencil} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                )}




                                {/* Customer notes section here*/}
                                <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-regular leading-6 text-gray-900">
                                        Order Notes
                                        <br></br>
                                        <button
                                            onClick={() => setShowNewNoteForm(!showNewNoteForm)}
                                            className="mt-2 text-white bg-black hover:bg-main font-bold py-1 px-2 rounded text-xs"
                                        >
                                            {showNewNoteForm ? 'Cancel' : 'Add New Note'}
                                        </button>
                                    </dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                        {/** New Note Form */}
                                        {showNewNoteForm && (
                                            <div className="mt-4 mb-10 bg-white p-4 rounded-md">
                                                <input
                                                    type="text"
                                                    placeholder="Note Title"
                                                    value={newNote.noteTitle}
                                                    onChange={(e) => setNewNote({ ...newNote, noteTitle: e.target.value })}
                                                    className="w-full mb-2 p-2 border rounded"
                                                />
                                                <textarea
                                                    placeholder="Note Description"
                                                    value={newNote.noteDescription}
                                                    onChange={(e) => setNewNote({ ...newNote, noteDescription: e.target.value })}
                                                    className="w-full mb-2 p-2 border rounded"
                                                />
                                                <select
                                                    id="status"
                                                    name="status"
                                                    value={newNote.noteStatus}
                                                    onChange={(e) => setNewNote({ ...newNote, noteStatus: e.target.value })}
                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6 mb-2"
                                                >
                                                    <option value="">Select a status</option>
                                                    {statusOptions.map((name, index) => (
                                                        <option key={index} value={name}>
                                                            {name}
                                                        </option>
                                                    ))}
                                                </select>
                                                <select
                                                    id="assignedToUser"
                                                    name="assignedToUser"
                                                    value={newNote.assignedToUser}
                                                    onChange={(e) => setNewNote({ ...newNote, assignedToUser: e.target.value })}
                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6 mb-2"
                                                >
                                                    <option value="">Select the assignee</option>
                                                    {userNames.map((name, index) => (
                                                        <option key={index} value={name.displayName}>
                                                            {name.displayName}
                                                        </option>
                                                    ))}
                                                </select>
                                                <label htmlFor="followUpDate" className="block text-sm font-regular text-gray-700">
                                                    Follow-up Date
                                                </label>
                                                <DatePicker
                                                    id="followUpDate"
                                                    selected={newNote.followUpDate}
                                                    onChange={(date) => {
                                                        const utcDate = date ? new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())) : null;
                                                        setNewNote({ ...newNote, followUpDate: utcDate });
                                                    }}
                                                    dateFormat="dd-MM-yyyy"
                                                    className="font-regular mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm mb-2"
                                                />
                                                <br></br>
                                                <button
                                                    onClick={saveNewNote}
                                                    className="bg-gray-400 hover:bg-black text-white font-bold py-2 px-4 rounded"
                                                >
                                                    Save
                                                </button>
                                            </div>
                                        )}
                                        {orders[0]?.notes?.map((note, index) => (
                                            <div key={index} className="mb-8">
                                                {/* Note editor section here*/}
                                                {editingNoteIndex === index ? (
                                                    <div>
                                                        <input
                                                            type="text"
                                                            value={newNote.noteTitle}
                                                            onChange={(e) => setNewNote({ ...newNote, noteTitle: e.target.value })}
                                                            className="w-full mb-2 p-2 border rounded text-black"
                                                        />
                                                        <textarea
                                                            value={newNote.noteDescription}
                                                            onChange={(e) => setNewNote({ ...newNote, noteDescription: e.target.value })}
                                                            className="w-full mb-2 p-2 border rounded text-black"
                                                        />
                                                        <select
                                                            id="noteStatus"
                                                            name="noteStatus"
                                                            value={newNote.noteStatus}
                                                            onChange={(e) => setNewNote({ ...newNote, noteStatus: e.target.value })}
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6 mb-2"
                                                        >
                                                            {statusOptions.map((option) => (
                                                                <option key={option} value={option}>
                                                                    {option}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <select
                                                            id="assignedTo"
                                                            name="assignedTo"
                                                            value={newNote?.assignedToUser}
                                                            onChange={(e) => setNewNote({ ...newNote, assignedToUser: e.target.value })}
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6 mb-2"
                                                        >
                                                            <option value="">Select a user</option>
                                                            {userNames.map((name, index) => (
                                                                <option key={index} value={name.userFirstName + " " + name.userLastName}>
                                                                    {name.userFirstName + " " + name.userLastName}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <DatePicker
                                                            selected={newNote?.followUpDate?.toDate()}
                                                            onChange={(date) => {
                                                                const parsedDate = Timestamp.fromDate(date);
                                                                setNewNote({ ...newNote, followUpDate: parsedDate });
                                                            }}
                                                            dateFormat="dd-MM-yyyy"
                                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm mb-2"
                                                        />
                                                        <br></br>
                                                        <button
                                                            onClick={() => editNote(index, newNote)}
                                                            className="text-white bg-green-500 hover:bg-green-700 font-bold py-1 px-2 rounded text-xs mr-2"
                                                        >
                                                            Save
                                                        </button>
                                                        <button
                                                            onClick={() => setEditingNoteIndex(null)}
                                                            className="text-white bg-gray-500 hover:bg-gray-700 font-bold py-1 px-2 rounded text-xs"
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                ) : (
                                                    <>
                                                        {/* Displayed notes here*/}
                                                        <div className="mx-auto bg-gray-200 rounded-md px-1 py-2 mb-2">
                                                            <h4 className="font-regular text-black mb-2 decoration-main text-xl underline decoration-main">{note.noteTitle}</h4>
                                                            <p className="bg-white rounded-md py-1 px-2 mb-2"><p className="font-bold text-xs">Notes:</p>{note.noteDescription}</p>
                                                            <p className="text-xs text-black mb-2">
                                                                <p className="font-bold">Added by:</p> {note.addedByUser} on {note.dateAdded.toDate().toLocaleDateString('en-NZ', {
                                                                    year: 'numeric',
                                                                    month: '2-digit',
                                                                    day: '2-digit'
                                                                })};
                                                            </p>
                                                            <p className={`text-xs text-white rounded-md py-1 px-2 mb-2 ${getStatusColor(note.noteStatus)}`}>
                                                                <p className="font-bold">Status:</p> {note.noteStatus}
                                                            </p>
                                                            <p className="text-xs text-black bg-white rounded-md py-1 px-2 mb-2">
                                                                <p className="font-bold">Assigned to:</p> {note.assignedToUser} on {note.dateAssigned.toDate().toLocaleDateString('en-NZ', {
                                                                    year: 'numeric',
                                                                    month: '2-digit',
                                                                    day: '2-digit'
                                                                })}
                                                            </p>
                                                            <p className="text-xs text-black py-1 px-2 mb-2">
                                                                <p className="font-bold"><FontAwesomeIcon icon={faExclamation} bounce />{" "}Follow up due:</p>{note?.followUpDate?.toDate().toLocaleDateString('en-NZ', {
                                                                    year: 'numeric',
                                                                    month: '2-digit',
                                                                    day: '2-digit'
                                                                })}
                                                            </p>
                                                            <button
                                                                onClick={() => {
                                                                    setNewNote(note);
                                                                    setEditingNoteIndex(index);
                                                                }}
                                                                className="mt-2 text-white bg-gray-400 hover:bg-black font-bold py-1 px-2 rounded text-xs mr-2"
                                                            >
                                                                <FontAwesomeIcon icon={faPencil} />{"  "}Edit
                                                            </button>
                                                            <button
                                                                onClick={() => deleteNote(index)}
                                                                className="mt-2 text-white bg-main hover:bg-red-700 font-bold py-1 px-2 rounded text-xs"
                                                            >
                                                                Delete
                                                            </button>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        ))}


                                    </dd>

                                </div>

                                {/* Shipment tracking section */}
                                <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-regular leading-6 text-gray-900">
                                        Shipment Tracking
                                        <br />
                                        <button
                                            onClick={() => setNewShipmentForm(prevState => !prevState)}
                                            className="mt-2 text-white bg-black hover:bg-main font-bold py-1 px-2 rounded text-xs"
                                        >
                                            {NewShipmentForm ? 'Cancel' : 'Add New Shipment'}
                                        </button>
                                    </dt>
                                    <dd className="mt-1 font-regular text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                        {/* New shipment */}
                                        {NewShipmentForm && (
                                            <div className="mx-auto bg-gray-200 rounded-md px-1 py-2 mb-8 text-xs">
                                                <p className="font-bold">Shipment Name:</p>
                                                <input
                                                    type="text"
                                                    placeholder="Shipment Name"
                                                    onChange={(e) => setNewShipment(prevState => ({ ...prevState, shipmentName: e.target.value }))}
                                                    className="w-full mb-2 p-1 rounded"
                                                />
                                                <p className="font-bold">Shipment Volume (enter number only without comma's or any additional characters):</p>
                                                <input
                                                    type="text"
                                                    placeholder="Shipment Volume"
                                                    onChange={(e) => setNewShipment(prevState => ({ ...prevState, shipmentVolume: e.target.value }))}
                                                    className="w-full mb-2 p-1 rounded"
                                                />
                                                <p className="font-bold">Trial Shipment:</p>
                                                <select
                                                    onChange={(e) => setNewShipment(prevState => ({ ...prevState, trialShipment: e.target.value === 'YES' }))}
                                                    className="w-full mb-2 p-1 rounded"
                                                >
                                                    <option value="">Select an option</option>
                                                    <option value="true">YES</option>
                                                    <option value="false">NO</option>
                                                </select>
                                                <p className="font-bold">Payment completed for shipment:</p>
                                                <select
                                                    onChange={(e) => setNewShipment(prevState => ({ ...prevState, paymentShipment: e.target.value === 'YES' }))}
                                                    className="w-full mb-2 p-1 rounded"
                                                >
                                                    <option value="">Select an option</option>
                                                    <option value="true">YES</option>
                                                    <option value="false">NO</option>
                                                </select>
                                                <p className="font-bold">Dip test status:</p>
                                                <select
                                                    onChange={(e) => setNewShipment(prevState => ({ ...prevState, dipTestSellerTankStatus: e.target.value }))}
                                                    className="w-full mb-2 p-1 rounded"
                                                >
                                                    <option value="">Select an option</option>
                                                    <option value="Waiting for Seller Tank Dip Test">Waiting for Seller Tank Dip Test</option>
                                                    <option value="Completed and approved by buyer">Completed and approved by buyer</option>
                                                    <option value="Completed but unsatisfactory to buyer">Completed but unsatisfactory to buyer</option>
                                                </select>
                                                <p className="font-bold">Shipping company:</p>
                                                <input
                                                    type="text"
                                                    placeholder="Shipping Company"
                                                    onChange={(e) => setNewShipment(prevState => ({ ...prevState, shippingCompanyName: e.target.value }))}
                                                    className="w-full mb-2 p-1 rounded"
                                                />
                                                <p className="font-bold">Vessel Name:</p>
                                                <input
                                                    type="text"
                                                    placeholder="Vessel Name"
                                                    onChange={(e) => setNewShipment(prevState => ({ ...prevState, vesselName: e.target.value }))}
                                                    className="w-full mb-2 p-1 rounded"
                                                />
                                                <p className="font-bold">Vessel Maritime Mobile Service Identity (MMSI):</p>
                                                <input
                                                    type="text"
                                                    placeholder="Vessel Maritime Mobile Service Identity (MMSI)"
                                                    onChange={(e) => setNewShipment(prevState => ({ ...prevState, vesselMMSI: e.target.value }))}
                                                    className="w-full mb-2 p-1 rounded"
                                                />
                                                <p className="font-bold">Shipment Company Tracking URL:</p>
                                                <input
                                                    type="text"
                                                    placeholder="Shipment Company Tracking URL"
                                                    onChange={(e) => setNewShipment(prevState => ({ ...prevState, shippingTrackingUrl: e.target.value }))}
                                                    className="w-full mb-2 p-1 rounded"
                                                />
                                                <p className="font-bold">Shipment Status:</p>
                                                <select
                                                    onChange={(e) => setNewShipment(prevState => ({ ...prevState, shipmentStatus: e.target.value }))}
                                                    className="w-full mb-2 p-1 rounded"
                                                >
                                                    <option value="">Select an option</option>
                                                    <option value="Waiting for TTV procedure">Waiting for TTV procedure</option>
                                                    <option value="Ship en-route to Destination">Ship en-route to Destination</option>
                                                    <option value="Ship arrived at Destination awaiting VTT">Ship arrived at Destination awaiting VTT</option>
                                                    <option value="Delivery completed">Delivery completed</option>
                                                    <option value="On Hold">On Hold</option>
                                                </select>
                                                <p className="font-bold">Shipped on (date):</p>
                                                <DatePicker
                                                    value={newShipment?.dateDeparted}
                                                    onChange={(date) => {
                                                        const options = {
                                                            day: '2-digit',
                                                            month: '2-digit',
                                                            year: 'numeric',
                                                        };
                                                        const parsedDate = date.toLocaleString('en-NZ', options);
                                                        setNewShipment({ ...newShipment, dateDeparted: parsedDate });
                                                    }} className="w-full mb-2 p-2 rounded border border-gray-300"
                                                />
                                                <p className="font-bold">Estimated delivery (date):</p>
                                                <DatePicker
                                                    value={newShipment?.eta}
                                                    onChange={(date) => {
                                                        const options = {
                                                            day: '2-digit',
                                                            month: '2-digit',
                                                            year: 'numeric',
                                                        };
                                                        const parsedDate = date.toLocaleString('en-NZ', options);
                                                        setNewShipment({ ...newShipment, eta: parsedDate });
                                                    }} className="w-full mb-2 p-2 rounded border border-gray-300"
                                                />
                                                <p className="font-bold">Shipment notes:</p>
                                                <input
                                                    type="text"
                                                    placeholder="Notes"
                                                    onChange={(e) => setNewShipment(prevState => ({ ...prevState, shippingNotes: e.target.value }))}
                                                    className="w-full mb-2 p-1 rounded"
                                                />
                                                <button
                                                    onClick={saveNewShipment}
                                                    className="bg-gray-400 hover:bg-black text-white font-bold py-2 px-4 rounded"
                                                >
                                                    Save New Shipment
                                                </button>
                                            </div>
                                        )}

                                        {/* EDIT shipment */}
                                        {orders[0]?.shipping?.map((shipment, index) => (
                                            <div key={index} className="mb-8">
                                                {editingShipmentIndex === index ? (
                                                    <div className="mx-auto bg-gray-200 rounded-md px-4 py-4 mb-2">
                                                        <p className="font-bold">Shipment Name:</p>
                                                        <input
                                                            type="text"
                                                            value={shipment.shipmentName}
                                                            onChange={(e) => {
                                                                const updatedShipment = { ...shipment, shipmentName: e.target.value };
                                                                setOrders(prevOrders => {
                                                                    const newOrders = [...prevOrders];
                                                                    newOrders[0].shipping[index] = updatedShipment;
                                                                    return newOrders;
                                                                });
                                                            }}
                                                            className="w-full mb-2 p-2 rounded border border-gray-300"
                                                            placeholder="Shipment Name"
                                                        />
                                                        <p className="font-bold">Shipment Volume (enter number only without comma's or any additional characters):</p>
                                                        <input
                                                            type="text"
                                                            value={shipment.shipmentVolume}
                                                            onChange={(e) => {
                                                                const updatedShipment = { ...shipment, shipmentVolume: e.target.value };
                                                                setOrders(prevOrders => {
                                                                    const newOrders = [...prevOrders];
                                                                    newOrders[0].shipping[index] = updatedShipment;
                                                                    return newOrders;
                                                                });
                                                            }}
                                                            className="w-full mb-2 p-2 rounded border border-gray-300"
                                                            placeholder="Shipment Volume"
                                                        />
                                                        <p className="font-bold">Trial Shipment?</p>
                                                        <select
                                                            value={shipment.trialShipment ? 'YES' : 'NO'}
                                                            onChange={(e) => {
                                                                const updatedShipment = { ...shipment, trialShipment: e.target.value === 'YES' };
                                                                setOrders(prevOrders => {
                                                                    const newOrders = [...prevOrders];
                                                                    newOrders[0].shipping[index] = updatedShipment;
                                                                    return newOrders;
                                                                });
                                                            }}
                                                            className="w-full mb-2 p-2 rounded border border-gray-300"
                                                        >
                                                            <option value="YES">YES</option>
                                                            <option value="NO">NO</option>
                                                        </select>
                                                        <p className="font-bold">Payment completed for shipment:</p>
                                                        <select
                                                            value={shipment.paymentShipment ? 'YES' : 'NO'}
                                                            onChange={(e) => {
                                                                const updatedShipment = { ...shipment, paymentShipment: e.target.value === 'YES' };
                                                                setOrders(prevOrders => {
                                                                    const newOrders = [...prevOrders];
                                                                    newOrders[0].shipping[index] = updatedShipment;
                                                                    return newOrders;
                                                                });
                                                            }}
                                                            className="w-full mb-2 p-2 rounded border border-gray-300"
                                                        >
                                                            <option value="YES">YES</option>
                                                            <option value="NO">NO</option>
                                                        </select>
                                                        <p className="font-bold">Dip test status:</p>
                                                        <select
                                                            value={shipment.dipTestSellerTankStatus}
                                                            onChange={(e) => {
                                                                const updatedShipment = { ...shipment, dipTestSellerTankStatus: e.target.value };
                                                                setOrders(prevOrders => {
                                                                    const newOrders = [...prevOrders];
                                                                    newOrders[0].shipping[index] = updatedShipment;
                                                                    return newOrders;
                                                                });
                                                            }}
                                                            className="w-full mb-2 p-2 rounded border border-gray-300"
                                                        >
                                                            <option value="">Select an option</option>
                                                            <option value="Waiting for Seller Tank Dip Test">Waiting for Seller Tank Dip Test</option>
                                                            <option value="Completed and approved by buyer">Completed and approved by buyer</option>
                                                            <option value="Completed but unsatisfactory to buyer">Completed but unsatisfactory to buyer</option>
                                                        </select>
                                                        <p className="font-bold">Shipping company:</p>
                                                        <input
                                                            type="text"
                                                            value={shipment.shippingCompanyName}
                                                            onChange={(e) => {
                                                                const updatedShipment = { ...shipment, shippingCompanyName: e.target.value };
                                                                setOrders(prevOrders => {
                                                                    const newOrders = [...prevOrders];
                                                                    newOrders[0].shipping[index] = updatedShipment;
                                                                    return newOrders;
                                                                });
                                                            }}
                                                            className="w-full mb-2 p-2 rounded border border-gray-300"
                                                            placeholder="Shipping Company Name"
                                                        />
                                                        <p className="font-bold">Vessel Name:</p>
                                                        <input
                                                            type="text"
                                                            value={shipment.vesselName}
                                                            onChange={(e) => {
                                                                const updatedShipment = { ...shipment, vesselName: e.target.value };
                                                                setOrders(prevOrders => {
                                                                    const newOrders = [...prevOrders];
                                                                    newOrders[0].shipping[index] = updatedShipment;
                                                                    return newOrders;
                                                                });
                                                            }}
                                                            className="w-full mb-2 p-2 rounded border border-gray-300"
                                                            placeholder="Vessel Name"
                                                        />
                                                        <p className="font-bold">Vessel Maritime Mobile Service Identity (MMSI):</p>
                                                        <input
                                                            type="text"
                                                            value={shipment.vesselMMSI}
                                                            onChange={(e) => {
                                                                const updatedShipment = { ...shipment, vesselMMSI: e.target.value };
                                                                setOrders(prevOrders => {
                                                                    const newOrders = [...prevOrders];
                                                                    newOrders[0].shipping[index] = updatedShipment;
                                                                    return newOrders;
                                                                });
                                                            }}
                                                            className="w-full mb-2 p-2 rounded border border-gray-300"
                                                            placeholder="Vessel MMSI"
                                                        />
                                                        <p className="font-bold">Shipment Company Tracking URL:</p>
                                                        <input
                                                            type="text"
                                                            value={shipment.shippingTrackingUrl}
                                                            onChange={(e) => {
                                                                const updatedShipment = { ...shipment, shippingTrackingUrl: e.target.value };
                                                                setOrders(prevOrders => {
                                                                    const newOrders = [...prevOrders];
                                                                    newOrders[0].shipping[index] = updatedShipment;
                                                                    return newOrders;
                                                                });
                                                            }}
                                                            className="w-full mb-2 p-2 rounded border border-gray-300"
                                                            placeholder="Shipping Tracking URL"
                                                        />
                                                        <p className="font-bold">Shipment Status:</p>
                                                        <select
                                                            value={shipment.shipmentStatus}
                                                            onChange={(e) => {
                                                                const updatedShipment = { ...shipment, shipmentStatus: e.target.value };
                                                                setOrders(prevOrders => {
                                                                    const newOrders = [...prevOrders];
                                                                    newOrders[0].shipping[index] = updatedShipment;
                                                                    return newOrders;
                                                                });
                                                            }}
                                                            className="w-full mb-2 p-2 rounded border border-gray-300"
                                                        >
                                                            <option value="">Select an option</option>
                                                            <option value="Waiting for TTV procedure">Waiting for TTV procedure</option>
                                                            <option value="Ship en-route to Destination">Ship en-route to Destination</option>
                                                            <option value="Ship arrived at Destination awaiting VTT">Ship arrived at Destination awaiting VTT</option>
                                                            <option value="Delivery completed">Delivery completed</option>
                                                            <option value="On Hold">On Hold</option>
                                                        </select>
                                                        <p className="font-bold">Shipped on (date):</p>
                                                        <DatePicker
                                                            value={shipment?.dateDeparted}
                                                            onChange={(date) => {
                                                                const options = {
                                                                    day: '2-digit',
                                                                    month: '2-digit',
                                                                    year: 'numeric',
                                                                };
                                                                const parsedDate = date.toLocaleString('en-NZ', options);
                                                                const updatedShipment = { ...shipment, dateDeparted: parsedDate };
                                                                setOrders(prevOrders => {
                                                                    const newOrders = [...prevOrders];
                                                                    newOrders[0].shipping[index] = updatedShipment;
                                                                    return newOrders;
                                                                });
                                                            }} className="w-full mb-2 p-2 rounded border border-gray-300"
                                                        />

                                                        <p className="font-bold">Estimated delivery (date):</p>
                                                        <DatePicker
                                                            value={shipment?.eta}
                                                            onChange={(date) => {
                                                                const options = {
                                                                    day: '2-digit',
                                                                    month: '2-digit',
                                                                    year: 'numeric',
                                                                };
                                                                const parsedDate = date.toLocaleString('en-NZ', options);
                                                                const updatedShipment = { ...shipment, eta: parsedDate };
                                                                setOrders(prevOrders => {
                                                                    const newOrders = [...prevOrders];
                                                                    newOrders[0].shipping[index] = updatedShipment;
                                                                    return newOrders;
                                                                });
                                                            }} className="w-full mb-2 p-2 rounded border border-gray-300"
                                                        />

                                                        <p className="font-bold">Shipment notes:</p>
                                                        <textarea
                                                            value={shipment.shippingNotes}
                                                            onChange={(e) => {
                                                                const updatedShipment = { ...shipment, shippingNotes: e.target.value };
                                                                setOrders(prevOrders => {
                                                                    const newOrders = [...prevOrders];
                                                                    newOrders[0].shipping[index] = updatedShipment;
                                                                    return newOrders;
                                                                });
                                                            }}
                                                            className="w-full mb-2 p-2 rounded border border-gray-300"
                                                            placeholder="Shipping Notes"
                                                        />
                                                        <button
                                                            onClick={() => {
                                                                updateShipment(orders[0].shipping[index], index);
                                                                setEditingShipmentIndex(null);
                                                            }}
                                                            className="mt-2 text-white bg-black hover:bg-main font-bold py-2 px-4 rounded"
                                                        >
                                                            Save
                                                        </button>
                                                    </div>
                                                ) : (
                                                    <div className="mx-auto bg-gray-200 rounded-md px-1 py-2 mb-2">
                                                        <h4 className="font-regular text-black mb-2 decoration-main text-xl underline decoration-main">
                                                            {shipment?.shipmentName}
                                                        </h4>
                                                        <p className="text-xs text-black bg-white rounded-md py-1 px-2 mb-2">
                                                            <p className="font-bold">Shipment Volume:</p> {shipment?.shipmentVolume + " MT"}
                                                        </p>
                                                        <p className="text-xs text-black bg-white rounded-md py-1 px-2 mb-2">
                                                            <p className="font-bold">Trial shipment?</p> {shipment?.trialShipment ? 'YES' : 'NO'}

                                                        </p>
                                                        <p className="text-xs text-black bg-white rounded-md py-1 px-2 mb-2">
                                                            <p className="font-bold">Shipment Paid?</p> {shipment?.paymentShipment ? 'YES' : 'NO'}

                                                        </p>
                                                        <p className={`text-xs text-black bg-white rounded-md py-1 px-2 mb-2 ${getDipStatusColor(shipment?.dipTestSellerTankStatus)}`}>
                                                            <p className="font-bold">Dip test status:</p> {shipment?.dipTestSellerTankStatus}
                                                        </p>
                                                        <p className="text-xs text-black bg-white rounded-md py-1 px-2 mb-2">
                                                            <p className="font-bold">Shipping Company:</p> {shipment?.shippingCompanyName}
                                                        </p>
                                                        <p className="text-xs text-black bg-white rounded-md py-1 px-2 mb-2">
                                                            <p className="font-bold">Vessel Name:</p> {shipment?.vesselName}
                                                        </p>
                                                        <p className="text-xs text-black bg-white rounded-md py-1 px-2 mb-2">
                                                            <p className="font-bold">Vessel Maritime Mobile Service Identity (MMSI):</p> {shipment?.vesselMMSI}
                                                        </p>
                                                        <p className="text-xs text-black bg-white rounded-md py-1 px-2 mb-2">
                                                            <p className="font-bold">Shipment Number:</p> {shipment?.shipmentNumber.toString()}
                                                        </p>
                                                        <p className="text-xs text-black bg-white rounded-md py-1 px-2 mb-2">
                                                            <p className="font-bold">Shipment Company Tracking URL:</p> <a href={shipment?.shippingTrackingUrl} target="_blank" rel="noopener noreferrer" className="text-black hover:text-main transition-colors duration-300">{shipment?.shippingTrackingUrl}</a>
                                                        </p>
                                                        <p className={`text-xs text-black bg-white rounded-md py-1 px-2 mb-2 ${getShipmentStatusColor(shipment?.shipmentStatus)}`}>
                                                            <p className="font-bold">Status:</p> {shipment?.shipmentStatus}
                                                        </p>
                                                        <p className="text-xs text-black bg-white rounded-md py-1 px-2 mb-2">
                                                            <p className="font-bold">Shipped on:</p> {shipment?.dateDeparted}
                                                        </p>
                                                        <p className="text-xs text-black bg-white rounded-md py-1 px-2 mb-2">
                                                            <p className="font-bold">Estimated delivery:</p> {shipment?.eta}
                                                        </p>
                                                        <p className="bg-white rounded-md py-1 px-2 mb-2">
                                                            <p className="font-bold text-xs">Notes:</p>{shipment?.shippingNotes}
                                                        </p>
                                                        <p className="bg-white rounded-md py-1 px-2 mb-2">

                                                            {/* Attachments section here */}
                                                            <h4 className="font-regular text-black mb-2 decoration-main text-lg decoration-main">Shipment Attachments</h4>
                                                            <ul role="list" className="divide-y divide-gray-100 rounded-md border border-gray-200">
                                                                {shipmentsAttachments?.[shipment?.shipmentNumber]?.map((attachment, index) => (<li key={index} className="bg-gray-200 flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                                                    <div className="flex w-0 flex-1 items-center">
                                                                        <PaperClipIcon aria-hidden="true" className="h-5 w-5 flex-shrink-0 text-black" />
                                                                        <div className="ml-4 flex min-w-0 flex-1 gap-2">
                                                                            <span
                                                                                className="font-regular cursor-pointer hover:underline"
                                                                                onClick={() => shipmentDownloadFile(attachment?.name, shipment?.shipmentNumber)}
                                                                            >
                                                                                {attachment.name}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="ml-4 flex flex-shrink-0 space-x-4">
                                                                        <button type="button" onClick={() => shipmentDeleteFile(attachment?.name, shipment?.shipmentNumber)} className="text-black bg-white rounded hover:text-white hover:bg-main py-1 px-1">
                                                                            Remove
                                                                        </button>
                                                                    </div>
                                                                </li>
                                                                ))}
                                                                <li className="bg-main flex justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                                                    <div className="flex w-0 flex-1">
                                                                        <input type="file" className="mx-auto mt-1 text-black font-bold py-3 px-3 rounded text-md" onChange={shipmentHandleFileChange} ref={fileInputRef} />

                                                                    </div>
                                                                </li>
                                                                <li className="bg-main flex justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                                                    <button onClick={() => shipmentUploadFile(shipment?.shipmentNumber.toString())} className="mx-auto mt-2 text-white bg-black hover:bg-white hover:text-black font-bold py-2 px-2 rounded text-md left-0">
                                                                        Upload File
                                                                    </button>
                                                                </li>
                                                            </ul>
                                                            <div className="mt-4 relative">

                                                            </div>
                                                        </p>
                                                        <h4 className="font-regular text-black mb-2 decoration-main text-lg decoration-main">
                                                            Ship Current Location:
                                                            <br></br>
                                                            <a className="font-bold text-xs">Longitude:</a> <a className="font-normal text-xs">{shipment?.positionLongitude || " Waiting for GPS position updates"} &nbsp; </a>
                                                            <a className="font-bold text-xs">Lattitude:</a> <a className="font-normal text-xs">{shipment?.positionLatitude || " Waiting for GPS position updates"} &nbsp; </a>
                                                        </h4>
                                                        {shipment.positionLongitude > 0 ? (
                                                            <APIProvider apiKey={googleMapsApiKey}>
                                                                <Map
                                                                    defaultZoom={3}
                                                                    defaultCenter={{ lat: Number(shipment?.positionLatitude), lng: Number(shipment?.positionLongitude) }}
                                                                    gestureHandling={'greedy'}
                                                                    disableDefaultUI={true}
                                                                    mapId={"cf722d92c2900c26"}
                                                                    style={{ width: '100%', height: '300px' }}
                                                                >
                                                                    <Marker position={{ lat: Number(shipment?.positionLatitude), lng: Number(shipment?.positionLongitude) }} />
                                                                </Map>
                                                            </APIProvider>
                                                        ) : (
                                                            <p className="font-regular text-lg text-center">No GPS data available yet. As data is available, ship position will update here.</p>
                                                        )}
                                                        <button
                                                            onClick={() => {
                                                                setEditingShipmentIndex(index);
                                                            }}
                                                            className="mt-2 text-white bg-gray-400 hover:bg-black font-bold py-1 px-2 rounded text-xs mr-2"
                                                        >
                                                            <FontAwesomeIcon icon={faPencil} />{"  "}Edit
                                                        </button>
                                                        <button
                                                            onClick={() => deleteShipment(shipment?.shipmentNumber)}
                                                            className="mt-2 text-white bg-main hover:bg-red-700 font-bold py-1 px-2 rounded text-xs"
                                                        >
                                                            Delete
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </dd>
                                </div>



                                {/* Attachments section here */}
                                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-regular leading-6 text-gray-900">Attachments</dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                        <ul role="list" className="divide-y divide-gray-100 rounded-md border border-gray-200">
                                            {attachments.map((attachment, index) => (
                                                <li key={index} className="bg-gray-200 flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                                    <div className="flex w-0 flex-1 items-center">
                                                        <PaperClipIcon aria-hidden="true" className="h-5 w-5 flex-shrink-0 text-black" />
                                                        <div className="ml-4 flex min-w-0 flex-1 gap-2">
                                                            <span
                                                                className="font-regular cursor-pointer hover:underline"
                                                                onClick={() => downloadFile(attachment.name)}
                                                            >
                                                                {attachment.name}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="ml-4 flex flex-shrink-0 space-x-4">
                                                        <button type="button" onClick={() => deleteFile(attachment.name)} className="text-black bg-white rounded hover:text-white hover:bg-main py-1 px-1">
                                                            Remove
                                                        </button>
                                                    </div>
                                                </li>
                                            ))}
                                            <li className="bg-gray-200 flex justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                                <div className="flex w-0 flex-1">
                                                    <input type="file" className="mx-auto mt-1 text-black font-bold py-3 px-3 rounded text-md" onChange={handleFileChange} ref={fileInputRef} />

                                                </div>
                                            </li>
                                            <li className="bg-gray-200 flex justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                                <button onClick={uploadFile} className="mx-auto mt-2 text-white bg-black hover:bg-main hover:text-white font-bold py-2 px-2 rounded text-md left-0">
                                                    Upload File
                                                </button>
                                            </li>
                                        </ul>
                                        <div className="mt-4 relative">

                                        </div>
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    </div >
                </div >
            )
            };
        </>
    );
}