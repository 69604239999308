import { randomBytes } from "crypto"
import { useState } from "react"
import { collection, getDocs, Timestamp, query, where, doc, getDoc, updateDoc, arrayUnion, QuerySnapshot, setDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import Notifications from "../Notifications";
import { useNavigate } from "react-router-dom";

export default function NewCustomer() {
    // Toaster notification state
    const [toasterShow, setToasterShow] = useState(false);
    const [newMessage, setNewMessage] = useState("");
    const [newTitle, setNewTitle] = useState("");

    const navigate = useNavigate();


    const GenerateCustomerNumber = Math.floor(Math.random() * 90000) + 10000;

    const [CustomerAddress, setCustomerAddress] = useState("")
    const [isEditingCustomerAddress, setIsEditingCustomerAddress] = useState(false)

    const [CustomerContactEmail, setCustomerContactEmail] = useState("")
    const [isEditingCustomerContactEmail, setIsEditingCustomerContactEmail] = useState(false)

    const [CustomerContactPerson, setCustomerContactPerson] = useState("")
    const [isEditingCustomerContactPerson, setIsEditingCustomerContactPerson] = useState(false)

    const [CustomerCountry, setCustomerCountry] = useState("")
    const [isEditingCustomerCountry, setIsEditingCustomerCountry] = useState(false)

    const [CustomerName, setCustomerName] = useState("")
    const [isEditingCustomerName, setIsEditingCustomerName] = useState(false)

    const [accountName, setAccountName] = useState("")
    const [isEditingAccountName, setIsEditingAccountName] = useState(false)

    const [bankAccountNum, setBankAccountNum] = useState("")
    const [isEditingBankAccountNum, setIsEditingBankAccountNum] = useState(false)

    const [bankAddress, setBankAddress] = useState("")
    const [isEditingBankAddress, setIsEditingBankAddress] = useState(false)

    const [bankEmail, setBankEmail] = useState("")
    const [isEditingBankEmail, setIsEditingBankEmail] = useState(false)

    const [bankName, setBankName] = useState("")
    const [isEditingBankName, setIsEditingBankName] = useState(false)

    const [bankOfficer, setBankOfficer] = useState("")
    const [isEditingBankOfficer, setIsEditingBankOfficer] = useState(false)

    const [bankSwift, setBankSwift] = useState("")
    const [isEditingBankSwift, setIsEditingBankSwift] = useState(false)

    const [bankTelephone, setBankTelephone] = useState("")
    const [isEditingBankTelephone, setIsEditingBankTelephone] = useState(false)

    const [beneficiaryName, setBeneficiaryName] = useState("")
    const [isEditingBeneficiaryName, setIsEditingBeneficiaryName] = useState(false)

    const [customerNum, setCustomerNum] = useState(GenerateCustomerNumber.toString())

    const [customerPpCountry, setCustomerPpCountry] = useState("")
    const [isEditingCustomerPpCountry, setIsEditingCustomerPpCountry] = useState(false)

    const [customerPpNum, setCustomerPpNum] = useState("")
    const [isEditingCustomerPpNum, setIsEditingCustomerPpNum] = useState(false)

    const [customerPpExpiry, setCustomerPpExpiry] = useState(null)
    const [isEditingCustomerPpExpiry, setIsEditingCustomerPpExpiry] = useState(false)

    const [customerTel, setCustomerTel] = useState("")
    const [isEditingCustomerTel, setIsEditingCustomerTel] = useState(false)

    const [registrationNum, setRegistrationNum] = useState("")
    const [isEditingRegistrationNum, setIsEditingRegistrationNum] = useState(false)

    const [signatoryName, setSignatoryName] = useState("")
    const [isEditingSignatoryName, setIsEditingSignatoryName] = useState(false)

    const [trialQuantity, setTrialQuantity] = useState(0)
    const [isEditingTrialQuantity, setIsEditingTrialQuantity] = useState(false)

    const [unitQuantity, setUnitQuantity] = useState("")
    const [isEditingUnitQuantity, setIsEditingUnitQuantity] = useState(false)

    // Delay function
    function delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async function saveFormData() {
        // Form validation
        const requiredFields = [
            CustomerAddress,
            CustomerContactEmail,
            CustomerContactPerson,
            CustomerCountry,
            CustomerName,
            accountName,
            bankAccountNum,
            bankAddress,
            bankEmail,
            bankName,
            bankOfficer,
            bankSwift,
            bankTelephone,
            beneficiaryName,
            customerNum,
            customerPpCountry,
            customerPpNum,
            customerPpExpiry,
            customerTel,
            registrationNum,
            signatoryName
        ];

        if (requiredFields.some(field => field === null || field === undefined || field.trim() === '')) {
            console.log(requiredFields)
            // Alert stuff
            setNewTitle("Error")
            setNewMessage("All fields must be completed before you can save the new customer.");
            await delay(2000);
            setToasterShow(true)
            await delay(3000);
            setToasterShow(false)
            console.error("All fields must be completed");
            return;
        }

        const customerRef = doc(db, "CustomerData", `${customerNum}`);

        const newCustomerData = {
            CustomerAddress: CustomerAddress,
            CustomerContactEmail: CustomerContactEmail,
            CustomerContactPerson: CustomerContactPerson,
            CustomerCountry: CustomerCountry,
            CustomerName: CustomerName,
            accountName: accountName,
            bankAccountNum: bankAccountNum,
            bankAddress: bankAddress,
            bankEmail: bankEmail,
            bankName: bankName,
            bankOfficer: bankOfficer,
            bankSwift: bankSwift,
            bankTelephone: bankTelephone,
            beneficiaryName: beneficiaryName,
            customerNum: customerNum,
            customerPpCountry: customerPpCountry,
            customerPpNum: customerPpNum,
            customerPpExpiry: Timestamp.fromDate(new Date(customerPpExpiry)),
            customerTel: customerTel,
            registrationNum: registrationNum,
            signatoryName: signatoryName,
            trialQuantity: trialQuantity,
            unitQuantity: unitQuantity,
        };

        try {
            await setDoc(customerRef, newCustomerData);
            console.log("New customer document created successfully");
            setNewTitle("New customer added")
            setNewMessage("New customer was added successfully!");
            await delay(2000);
            setToasterShow(true)
            await delay(3000);
            setToasterShow(false)
            navigate("/Customers");
        } catch (error) {
            console.error("Error creating new customer document:", error);
        }
    }

    return (
        <div>
             {toasterShow ? (
                <Notifications newMessage={newMessage} newTitle={newTitle} />
            ) : (
                null
            )}
        <div className="relative min-h-screen flex flex-col w-full bg-tanekaha3 overflow-y-auto">
            <div className="relative flex-grow h-full">
                <div className="absolute mt-20 w-3/4 right-10 space-y-10 divide-y divide-gray-900/10">
                    <div className=" absolute mx-auto">
                        <div className="sm:px-0 sm:right-0 lg:right-0 mx-auto text-center">
                            <p className="top-1 font-bebas mx-auto leading-7 text-gray-900 text-center text-4xl">New Customer Form</p>
                            <br></br>
                            <div className="absolute mx-auto mt-1 mb-1 border-t border-gray-100 w-full " />
                            <br></br>
                        </div>
                        <p className="font-medium mt-1 max-w-2xl text-xl leading-6 text-black mx-auto text-center">General information</p>
                        <br></br>
                        <dl className="relative mx-auto divide-y divide-gray-100 w-full">
                            <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Customer Name</dt>
                                <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                    <span className="ml-4 flex-shrink-0">
                                        <input type="text" value={CustomerName} onChange={(e) => setCustomerName(e.target.value)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black 0py-1 px-3" required>
                                        </input>
                                    </span>
                                </dd>
                            </div>
                        </dl>

                        <dl className="divide-y divide-gray-100">
                            <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Customer Address</dt>
                                <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                    <span className="ml-4 flex-shrink-0">
                                        <input type="text" value={CustomerAddress} onChange={(e) => setCustomerAddress(e.target.value)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black 0py-1 px-3" required>
                                        </input>
                                    </span>
                                </dd>
                            </div>
                        </dl>

                        <dl className="divide-y divide-gray-100">
                            <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Customer Country </dt>
                                <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                    <span className="ml-4 flex-shrink-0">
                                        <input type="text" value={CustomerCountry} onChange={(e) => setCustomerCountry(e.target.value)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black 0py-1 px-3" required>
                                        </input>
                                    </span>
                                </dd>
                            </div>
                        </dl>

                        <dl className="divide-y divide-gray-100">
                            <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Customer Contact Person</dt>
                                <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                    <span className="ml-4 flex-shrink-0">
                                        <input type="text" value={CustomerContactPerson} onChange={(e) => setCustomerContactPerson(e.target.value)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black 0py-1 px-3" required>
                                        </input>
                                    </span>
                                </dd>
                            </div>
                        </dl>

                        <dl className="divide-y divide-gray-100">
                            <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Customer Contact Email Address</dt>
                                <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                    <span className="ml-4 flex-shrink-0">
                                        <input type="text" value={CustomerContactEmail} onChange={(e) => setCustomerContactEmail(e.target.value)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black 0py-1 px-3" required>
                                        </input>
                                    </span>
                                </dd>
                            </div>
                        </dl>

                        <dl className="divide-y divide-gray-100">
                            <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Customer Contact Passport Number</dt>
                                <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                    <span className="ml-4 flex-shrink-0">
                                        <input type="text" value={customerPpNum} onChange={(e) => setCustomerPpNum(e.target.value)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black 0py-1 px-3" required>
                                        </input>
                                    </span>
                                </dd>
                            </div>
                        </dl>

                        <dl className="divide-y divide-gray-100">
                            <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Customer Contact Passport Expiry</dt>
                                <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                    <span className="ml-4 flex-shrink-0">
                                        <input type="date" value={customerPpExpiry} onChange={(e) => setCustomerPpExpiry(e.target.value)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black 0py-1 px-3" required>
                                        </input>
                                    </span>
                                </dd>
                            </div>
                        </dl>

                        <dl className="divide-y divide-gray-100">
                            <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Customer Passport Country</dt>
                                <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                    <span className="ml-4 flex-shrink-0">
                                        <input type="text" value={customerPpCountry} onChange={(e) => setCustomerPpCountry(e.target.value)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black 0py-1 px-3" required>
                                        </input>
                                    </span>
                                </dd>
                            </div>
                        </dl>

                        <dl className="divide-y divide-gray-100">
                            <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Customer Contact Phone</dt>
                                <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                    <span className="ml-4 flex-shrink-0">
                                        <input type="text" value={customerTel} onChange={(e) => setCustomerTel(e.target.value)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black 0py-1 px-3" required>
                                        </input>
                                    </span>
                                </dd>
                            </div>
                        </dl>

                        <dl className="divide-y divide-gray-100">
                            <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Customer Business Registration Number</dt>
                                <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                    <span className="ml-4 flex-shrink-0">
                                        <input type="text" value={registrationNum} onChange={(e) => setRegistrationNum(e.target.value)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black 0py-1 px-3" required>
                                        </input>
                                    </span>
                                </dd>
                            </div>
                        </dl>

                        <br></br>
                        <dl className="divide-y divide-gray-100">
                            <p className="mt-1 max-w-2xl text-xl leading-6 text-black mx-auto text-center font-medium">Customer Banking Information</p>
                        </dl>
                        <br></br>
                        <dl className="divide-y divide-gray-100">
                            <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-black">Customer Bank Name</dt>
                                <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                    <span className="ml-4 flex-shrink-0">
                                        <input type="text" value={bankName} onChange={(e) => setBankName(e.target.value)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black 0py-1 px-3" required>
                                        </input>
                                    </span>
                                </dd>
                            </div>
                        </dl>

                        <dl className="divide-y divide-gray-100">
                            <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-black">Customer Bank Account Name</dt>
                                <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                    <span className="ml-4 flex-shrink-0">
                                        <input type="text" value={accountName} onChange={(e) => setAccountName(e.target.value)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black 0py-1 px-3" required>
                                        </input>
                                    </span>
                                </dd>
                            </div>
                        </dl>

                        <dl className="divide-y divide-gray-100">
                            <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-black">Customer Bank Account Number</dt>
                                <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                    <span className="ml-4 flex-shrink-0">
                                        <input type="text" value={bankAccountNum} onChange={(e) => setBankAccountNum(e.target.value)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black 0py-1 px-3" required>
                                        </input>
                                    </span>
                                </dd>
                            </div>
                        </dl>

                        <dl className="divide-y divide-gray-100">
                            <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-black">Customer Bank Address</dt>
                                <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                    <span className="ml-4 flex-shrink-0">
                                        <input type="text" value={bankAddress} onChange={(e) => setBankAddress(e.target.value)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black 0py-1 px-3" required>
                                        </input>
                                    </span>
                                </dd>
                            </div>
                        </dl>

                        <dl className="divide-y divide-gray-100">
                            <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-black">Customer Bank Officer</dt>
                                <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                    <span className="ml-4 flex-shrink-0">
                                        <input type="text" value={bankOfficer} onChange={(e) => setBankOfficer(e.target.value)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black 0py-1 px-3" required>
                                        </input>
                                    </span>
                                </dd>
                            </div>
                        </dl>

                        <dl className="divide-y divide-gray-100">
                            <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-black">Customer Bank SWIFT code</dt>
                                <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                    <span className="ml-4 flex-shrink-0">
                                        <input type="text" value={bankSwift} onChange={(e) => setBankSwift(e.target.value)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black 0py-1 px-3" required>
                                        </input>
                                    </span>
                                </dd>
                            </div>
                        </dl>

                        <dl className="divide-y divide-gray-100">
                            <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-black">Customer Bank Email</dt>
                                <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                    <span className="ml-4 flex-shrink-0">
                                        <input type="text" value={bankEmail} onChange={(e) => setBankEmail(e.target.value)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black 0py-1 px-3" required>
                                        </input>
                                    </span>
                                </dd>
                            </div>
                        </dl>

                        <dl className="divide-y divide-gray-100">
                            <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-black">Customer Bank Ph</dt>
                                <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                    <span className="ml-4 flex-shrink-0">
                                        <input type="text" value={bankTelephone} onChange={(e) => setBankTelephone(e.target.value)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black 0py-1 px-3" required>
                                        </input>
                                    </span>
                                </dd>
                            </div>
                        </dl>

                        <dl className="divide-y divide-gray-100">
                            <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-black">Bank Beneficiary Name</dt>
                                <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                    <span className="ml-4 flex-shrink-0">
                                        <input type="text" value={beneficiaryName} onChange={(e) => setBeneficiaryName(e.target.value)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black 0py-1 px-3" required>
                                        </input>
                                    </span>
                                </dd>
                            </div>
                        </dl>

                        <dl className="divide-y divide-gray-100">
                            <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-black">Customer Bank Signatory Name</dt>
                                <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                    <span className="ml-4 flex-shrink-0">
                                        <input type="text" value={signatoryName} onChange={(e) => setSignatoryName(e.target.value)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black 0py-1 px-3" required>
                                        </input>
                                    </span>
                                </dd>
                            </div>
                        </dl>

                        <dl className="divide-y divide-gray-100">
                            <div className="px-4 py-1 grid-cols-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-black"></dt>
                                <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                    <br></br>
                                    <button onClick={() => saveFormData()} className="mt-3 mb-3 rounded-md bg-white px-2 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-black hover:text-white">save new customer</button>
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}