import { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../Loading";
import { collection, getDocs, Timestamp, query, where, doc, getDoc, updateDoc, arrayUnion, QuerySnapshot, deleteDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { AuthContext } from "../../Context/AuthContext";
import { PaperClipIcon } from '@heroicons/react/20/solid'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { faExclamation, faFloppyDisk, faPencil, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ref, uploadBytes, listAll, getDownloadURL, deleteObject } from "firebase/storage";
import { storage } from "../../firebase/firebase";
import Notifications from "../Notifications";


type CustomerDataInterface = {
    CustomerAddress: string;
    CustomerContactEmail: string;
    CustomerContactPerson: string;
    CustomerCountry: string;
    CustomerName: string;
    accountName: string;
    bankAccountNum: string;
    bankAddress: string;
    bankEmail: string;
    bankName: string;
    bankOfficer: string;
    bankSwift: string;
    bankTelephone: string;
    beneficiaryName: string;
    customerNum: string;
    customerPpCountry: string;
    customerPpNum: string;
    customerPpExpiry: Timestamp;
    customerTel: string;
    registrationNum: string;
    signatoryName: string;
    trialQuantity: number;
    unitQuantity: string;
    notes: {
        addedByUser: string;
        assignedToUser: string;
        dateAdded: Timestamp;
        dateAssigned: Timestamp;
        lastEdited: Timestamp;
        lastEditedBy: string;
        noteDescription: string;
        noteTitle: string;
        noteStatus: string;
        followUpDate: Timestamp;
    }[];
};


export default function SelectCustomer({ customerId }) {

    const GenerateCustomerNumber = Math.floor(Math.random() * 90000) + 10000;

    const [CustomerAddress, setCustomerAddress] = useState("")
    const [isEditingCustomerAddress, setIsEditingCustomerAddress] = useState(false)

    const [CustomerContactEmail, setCustomerContactEmail] = useState("")
    const [isEditingCustomerContactEmail, setIsEditingCustomerContactEmail] = useState(false)

    const [CustomerContactPerson, setCustomerContactPerson] = useState("")
    const [isEditingCustomerContactPerson, setIsEditingCustomerContactPerson] = useState(false)

    const [CustomerCountry, setCustomerCountry] = useState("")
    const [isEditingCustomerCountry, setIsEditingCustomerCountry] = useState(false)

    const [CustomerName, setCustomerName] = useState("")
    const [isEditingCustomerName, setIsEditingCustomerName] = useState(false)

    const [accountName, setAccountName] = useState("")
    const [isEditingAccountName, setIsEditingAccountName] = useState(false)

    const [bankAccountNum, setBankAccountNum] = useState("")
    const [isEditingBankAccountNum, setIsEditingBankAccountNum] = useState(false)

    const [bankAddress, setBankAddress] = useState("")
    const [isEditingBankAddress, setIsEditingBankAddress] = useState(false)

    const [bankEmail, setBankEmail] = useState("")
    const [isEditingBankEmail, setIsEditingBankEmail] = useState(false)

    const [bankName, setBankName] = useState("")
    const [isEditingBankName, setIsEditingBankName] = useState(false)

    const [bankOfficer, setBankOfficer] = useState("")
    const [isEditingBankOfficer, setIsEditingBankOfficer] = useState(false)

    const [bankSwift, setBankSwift] = useState("")
    const [isEditingBankSwift, setIsEditingBankSwift] = useState(false)

    const [bankTelephone, setBankTelephone] = useState("")
    const [isEditingBankTelephone, setIsEditingBankTelephone] = useState(false)

    const [beneficiaryName, setBeneficiaryName] = useState("")
    const [isEditingBeneficiaryName, setIsEditingBeneficiaryName] = useState(false)

    const [customerNum, setCustomerNum] = useState("")
    const [isEditingCustomerNum, setIsEditingCustomerNum] = useState(false)

    const [customerPpCountry, setCustomerPpCountry] = useState("")
    const [isEditingCustomerPpCountry, setIsEditingCustomerPpCountry] = useState(false)

    const [customerPpNum, setCustomerPpNum] = useState("")
    const [isEditingCustomerPpNum, setIsEditingCustomerPpNum] = useState(false)

    const [customerPpExpiry, setCustomerPpExpiry] = useState(null)
    const [isEditingCustomerPpExpiry, setIsEditingCustomerPpExpiry] = useState(false)

    const [customerTel, setCustomerTel] = useState("")
    const [isEditingCustomerTel, setIsEditingCustomerTel] = useState(false)

    const [registrationNum, setRegistrationNum] = useState("")
    const [isEditingRegistrationNum, setIsEditingRegistrationNum] = useState(false)

    const [signatoryName, setSignatoryName] = useState("")
    const [isEditingSignatoryName, setIsEditingSignatoryName] = useState(false)

    const [trialQuantity, setTrialQuantity] = useState(0)
    const [isEditingTrialQuantity, setIsEditingTrialQuantity] = useState(false)

    const [unitQuantity, setUnitQuantity] = useState("")
    const [isEditingUnitQuantity, setIsEditingUnitQuantity] = useState(false)

    const [orders, setOrders] = useState<ordersInterface>([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();


    {/* CURRENT USER DATA */ }
    const [userData, setUserData] = useState<userDataInterface>();
    const { currentUser, setCurrentUser, signOut } = useContext(AuthContext);
    const userEmail = currentUser?.email;

    //Customer Data State
    const [customerData, setCustomerData] = useState<CustomerDataInterface[]>([]);

    //Constants for notes section
    const [showNewNoteForm, setShowNewNoteForm] = useState(false);
    const [newNote, setNewNote] = useState({ noteTitle: '', noteDescription: '', assignedToUser: '', noteStatus: '', followUpDate: null });
    const [editingNoteIndex, setEditingNoteIndex] = useState<number | null>(null);


    // Toaster notification state
    const [toasterShow, setToasterShow] = useState(false);
    const [newMessage, setNewMessage] = useState("");
    const [newTitle, setNewTitle] = useState("");


    type userDataInterface = {
        userContactPh: string;
        userCustomerNum: string;
        userEmail: string;
        userFirstName: string;
        userLastName: string;
        imageUrl: string;
        role: string;
    }[];

    const getUserData = async () => {

        const docRef = doc(db, "Users", userEmail);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            const data = docSnap.data()
            setUserData([
                {
                    userContactPh: data.contactPh,
                    userCustomerNum: data.userCustomerNum,
                    userEmail: data.email,
                    userFirstName: data.firstName,
                    userLastName: data.lastName,
                    imageUrl: data.imageUrl,
                    role: data.role
                }]
            );
        } else {
            alert('No such Id. Please check again');
        }
    }



    const getCustomerData = async () => {
        setLoading(true);
        const q = query(collection(db, "CustomerData"), where("customerNum", "==", String(customerId)));
        const querySnapshot = await getDocs(q);
        console.log("Query snapshot:", querySnapshot);
        const dataCustomer = querySnapshot.docs.map(doc => {
            const data = doc.data();
            return {
                CustomerAddress: data.CustomerAddress,
                CustomerContactEmail: data.CustomerContactEmail,
                CustomerContactPerson: data.CustomerContactPerson,
                CustomerCountry: data.CustomerCountry,
                CustomerName: data.CustomerName,
                accountName: data.accountName,
                bankAccountNum: data.bankAccountNum,
                bankAddress: data.bankAddress,
                bankEmail: data.bankEmail,
                bankName: data.bankName,
                bankOfficer: data.bankOfficer,
                bankSwift: data.bankSwift,
                bankTelephone: data.bankTelephone,
                beneficiaryName: data.beneficiaryName,
                customerNum: data.customerNum,
                customerPpCountry: data.customerPpCountry,
                customerPpNum: data.customerPpNum,
                customerPpExpiry: data.customerPpExpiry,
                customerTel: data.customerTel,
                registrationNum: data.registrationNum,
                signatoryName: data.signatoryName,
                trialQuantity: data.trialQuantity,
                unitQuantity: data.unitQuantity,
                notes: data.notes
            };
        });
        console.log("Mapped customer data:", dataCustomer);
        setCustomerData(dataCustomer);
        // Set state for current customer values
        setCustomerAddress(dataCustomer?.[0]?.CustomerAddress)
        setCustomerContactEmail(dataCustomer?.[0]?.CustomerContactEmail)
        setCustomerContactPerson(dataCustomer?.[0]?.CustomerContactPerson)
        setCustomerCountry(dataCustomer?.[0]?.CustomerCountry)
        setCustomerName(dataCustomer?.[0]?.CustomerName)
        setAccountName(dataCustomer?.[0]?.accountName)
        setBankAccountNum(dataCustomer?.[0]?.bankAccountNum)
        setBankAddress(dataCustomer?.[0]?.bankAddress)
        setBankEmail(dataCustomer?.[0]?.bankEmail)
        setBankName(dataCustomer?.[0]?.bankName)
        setBankOfficer(dataCustomer?.[0]?.bankOfficer)
        setBankSwift(dataCustomer?.[0]?.bankSwift)
        setBankTelephone(dataCustomer?.[0]?.bankTelephone)
        setBeneficiaryName(dataCustomer?.[0]?.beneficiaryName)
        setCustomerNum(dataCustomer?.[0]?.customerNum)
        setCustomerPpCountry(dataCustomer?.[0]?.customerPpCountry)
        setCustomerPpNum(dataCustomer?.[0]?.customerPpNum)
        setCustomerPpExpiry(dataCustomer?.[0]?.customerPpExpiry)
        setCustomerTel(dataCustomer?.[0]?.customerTel)
        setRegistrationNum(dataCustomer?.[0]?.registrationNum)
        setSignatoryName(dataCustomer?.[0]?.signatoryName)
        setTrialQuantity(dataCustomer?.[0]?.trialQuantity)
        setUnitQuantity(dataCustomer?.[0]?.unitQuantity)
        console.log("TESTING " + dataCustomer)
        setLoading(false);

    };

    // LIST OF USERS FOR NOTES
    const [userNames, setUserNames] = useState<string[]>([]);

    const fetchUserNames = async () => {
        const usersCollection = collection(db, 'Users');
        const usersSnapshot = await getDocs(usersCollection);
        const names = usersSnapshot.docs.map(doc => {
            const userData = doc.data();
            return `${userData.firstName} ${userData.lastName}`;
        });
        setUserNames(names);
    };

    // Consants for file uploads
    const [file, setFile] = useState(null);
    const [attachments, setAttachments] = useState([]);
    const fileInputRef = useRef(null);

    // File upload functions
    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    // Delay function
    function delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const uploadFile = async () => {
        if (!file) return;
        const fileRef = ref(storage, `Customers/${customerId}/${file.name}`);
        await uploadBytes(fileRef, file);
        setFile(null);
        fetchAttachments();
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }

        // Alert stuff

        setNewTitle("Your file was uploaded successfully!")
        setNewMessage(file.name + " was successfully uploaded to Tanekaha Storage")
        await delay(2000);
        setToasterShow(true)
        await delay(3000);
        setToasterShow(false)
    };

    const fetchAttachments = async () => {
        const listRef = ref(storage, `Customers/${customerId}`);
        const res = await listAll(listRef);
        const attachmentPromises = res.items.map(async (itemRef) => {
            const url = await getDownloadURL(itemRef);
            return { name: itemRef.name, url };
        });
        const attachmentList = await Promise.all(attachmentPromises);
        setAttachments(attachmentList);
    };

    // File attachment download function
    const downloadFile = async (fileName: string) => {
        const fileRef = ref(storage, `Customers/${customerId}/${fileName}`);
        try {
            const url = await getDownloadURL(fileRef);
            const link = document.createElement('a');
            link.href = url;
            link.download = fileName;
            document.body.appendChild(link);
            window.open(url, '_blank');
            document.body.removeChild(link);
        } catch (error) {
            console.error("Error downloading file:", error);
        }
    };

    // File attachment delete file
    const deleteFile = async (fileName) => {
        const fileRef = ref(storage, `Customers/${customerId}/${fileName}`);
        await deleteObject(fileRef);
        fetchAttachments();
        // Alert stuff
        setNewTitle("Your file was deleted")
        setNewMessage(fileName + " was deleted")
        await delay(2000);
        setToasterShow(true)
        await delay(3000);
        setToasterShow(false)

    };

    type ordersInterface = {
        buyer: string;
        buyerAddress: string;
        buyerRepresentation: string;
        comissionBuyer: number;
        comissionSeller: number;
        commodity: string;
        consultant: string;
        customerName: string;
        customerNum: string;
        date: Timestamp;
        following: string;
        fuelType: string;
        grossPrice: number;
        grossPriceCurrency: string;
        guaranteeForContract: string;
        id: number;
        inspectionValidity: string;
        loi: string;
        oneYearContractPayment: string;
        orderNum: string;
        origin: string;
        paymentForTrial: string;
        paymentStatus: string;
        performanceBond: string;
        quantity: number;
        quantityFrequency: string;
        refinery: string;
        refineryDirector: string;
        representedBy: string;
        status: string;
        trialQuantity: number;
        unitQuantity: string;
        globalQuantity: string;
        deliveryTrial: string;
        deliveryRegular: string;
        loadingPort: string;
        trackingURL: string;
        firstDelivery: string;
        priceGrossBuyer: string;
        termsContract: string;
        notes: {
            addedByUser: string;
            assignedToUser: string;
            dateAdded: Timestamp;
            dateAssigned: Timestamp;
            lastEdited: Timestamp;
            lastEditedBy: string;
            noteDescription: string;
            noteTitle: string;
            noteStatus: string;
            followUpDate: Timestamp;
        }[];

    }[];

    const LoadingEvent = () => (
        <Loading />
    );

    // MAIN USE EFFECT
    useEffect(() => {
        if (userEmail === undefined) {
            navigate('/Portal')
            console.log("Current user is not authorised")
        } else {
            console.log('USE EFFECT RAN')
            setLoading(true);
            //Get current logged on user data from firestore
            getCustomerData();
            //Get all Customer Data from firestore
            getUserData();
            //Get all users from firestore
            fetchUserNames();
            //fetch attachments from firestore
            fetchAttachments();
            console.log(userEmail + " is logged on currently")
            setLoading(false);
        }
    }, [editingNoteIndex, newNote]);

    // NOTE STATUS COLOR SETTINGS
    const getStatusColor = (status: string) => {
        switch (status?.toLowerCase()) {
            case 'closed completed':
                return 'bg-green-500';
            case 'in progress':
                return 'bg-yellow-500';
            case 'open':
                return 'bg-green-500';
            case 'pending':
                return 'bg-red-500';
            case 'on hold':
                return 'bg-red-500';
            case 'closed incomplete':
                return 'bg-red-500';
            default:
                return 'bg-gray-500';
        }
    };

    // NOTE STATUS OPTIONS
    const statusOptions = [
        'open',
        'in progress',
        'pending',
        'on hold',
        'closed incomplete',
        'closed completed'
    ];

    // NEW NOTE FUNCTION
    const saveNewNote = async () => {
        const q = query(collection(db, "CustomerData"), where("customerNum", "==", String(customerId)));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
            const orderDoc = querySnapshot.docs[0];
            const orderRef = doc(db, "CustomerData", orderDoc.id);
            const newNoteData = {
                noteTitle: newNote.noteTitle,
                noteDescription: newNote.noteDescription,
                assignedToUser: newNote.assignedToUser,
                addedByUser: userData[0].userFirstName + " " + userData[0].userLastName,
                noteStatus: newNote.noteStatus,
                dateAdded: Timestamp.now().toDate(),
                dateAssigned: Timestamp.now().toDate(),
                lastEdited: Timestamp.now().toDate(),
                lastEditedUser: userData[0].userFirstName + " " + userData[0].userLastName,
                followUpDate: newNote.followUpDate
            };

            try {
                await updateDoc(orderRef, {
                    notes: arrayUnion(newNoteData)
                });
                setNewNote({ noteTitle: '', noteDescription: '', assignedToUser: '', noteStatus: '', followUpDate: Timestamp.now().toDate() });
                setShowNewNoteForm(false);
                setToasterShow(true)
                setNewTitle("You have added a new note to the customer file")
                setNewMessage(newNote.noteTitle + " was added successfully")
                await delay(5000);
                setToasterShow(false)
            } catch (error) {
                console.error("Error adding new note: ", error);
            }
        } else {
            console.error("No matching order found");
        }
    };

    // NOTE DELETE FUNCTION
    const deleteNote = async (index: number) => {
        const q = query(collection(db, "CustomerData"), where("customerNum", "==", String(customerId)));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
            const orderDoc = querySnapshot.docs[0];
            const orderRef = doc(db, "CustomerData", orderDoc.id);
            const updatedNotes = [...customerData[0].notes];
            updatedNotes.splice(index, 1);

            try {
                await updateDoc(orderRef, {
                    notes: updatedNotes
                });
                setNewTitle("You have removed a note from the customer file")
                setNewMessage(newNote.noteTitle + " was removed successfully")
                await delay(2000);
                setToasterShow(true)
                await delay(5000);
                setToasterShow(false)
            } catch (error) {
                console.error("Error deleting note: ", error);
            }
        }
    };

    // NOTE EDITOR FUNCTION
    const editNote = async (index: number, updatedNote: any) => {
        const q = query(collection(db, "CustomerData"), where("customerNum", "==", String(customerId)));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
            const orderDoc = querySnapshot.docs[0];
            const orderRef = doc(db, "CustomerData", orderDoc.id);
            const updatedNotes = [...customerData[0].notes];
            updatedNotes[index] = { ...updatedNotes[index], ...updatedNote };

            try {
                await updateDoc(orderRef, {
                    notes: updatedNotes,
                    lastEdited: Timestamp.now(),
                    lastEditedUser: userData[0].userFirstName + " " + userData[0].userLastName
                });
                setEditingNoteIndex(null);
                // Alert stuff
                setNewTitle("You have edited a note on the customer file")
                setNewMessage("note was edited successfully")
                await delay(2000);
                setToasterShow(true)
                await delay(5000);
                setToasterShow(false)
            } catch (error) {
                console.error("Error editing note: ", error);
            }
        }
    };

    // CUSTOMER DELETE FUNCTION
    const deleteCustomer = async () => {
        const q = query(collection(db, "CustomerData"), where("customerNum", "==", String(customerId)))
        const querySnapshot = await getDocs(q)
        if (!querySnapshot.empty) {
            const orderDoc = querySnapshot.docs[0]
            const orderRef = doc(db, "CustomerData", orderDoc.id)

            try {
                await deleteDoc(orderRef)
                setNewTitle("Customer Deleted")
                setNewMessage("The customer has been successfully deleted")
                await delay(2000)
                setToasterShow(true)
                await delay(5000)
                setToasterShow(false)
                // Navigate to '/customer' URL
                window.location.href = '/Customers'
            } catch (error) {
                console.error("Error deleting customer: ", error)
                setNewTitle("Error")
                setNewMessage("An error occurred while deleting the customer")
                await delay(2000)
                setToasterShow(true)
                await delay(5000)
                setToasterShow(false)
            }
        }
    }


    const saveRow = async (field: string) => {
        const q = query(collection(db, "CustomerData"), where("customerNum", "==", String(customerId)));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
            const orderDoc = querySnapshot.docs[0];
            const orderRef = doc(db, "CustomerData", orderDoc.id);

            try {
                let updateData: { [key: string]: any } = {};
                let fieldValue: any;

                switch (field) {
                    case 'CustomerName':
                        fieldValue = CustomerName;
                        setIsEditingCustomerName(false)
                        break;
                    case 'CustomerAddress':
                        fieldValue = CustomerAddress;
                        setIsEditingCustomerAddress(false)
                        break;
                    case 'CustomerContactEmail':
                        fieldValue = CustomerContactEmail
                        setIsEditingCustomerContactEmail(false)
                        break
                    case 'CustomerContactPerson':
                        fieldValue = CustomerContactPerson
                        setIsEditingCustomerContactPerson(false)
                        break
                    case 'CustomerCountry':
                        fieldValue = CustomerCountry
                        setIsEditingCustomerCountry(false)
                        break
                    case 'accountName':
                        fieldValue = accountName
                        setIsEditingAccountName(false)
                        break
                    case 'bankAccountNum':
                        fieldValue = bankAccountNum
                        setIsEditingBankAccountNum(false)
                        break
                    case 'bankAddress':
                        fieldValue = bankAddress
                        setIsEditingBankAddress(false)
                        break
                    case 'bankEmail':
                        fieldValue = bankEmail
                        setIsEditingBankEmail(false)
                        break
                    case 'bankName':
                        fieldValue = bankName
                        setIsEditingBankName(false)
                        break
                    case 'bankOfficer':
                        fieldValue = bankOfficer
                        setIsEditingBankOfficer(false)
                        break
                    case 'bankSwift':
                        fieldValue = bankSwift
                        setIsEditingBankSwift(false)
                        break
                    case 'bankTelephone':
                        fieldValue = bankTelephone
                        setIsEditingBankTelephone(false)
                        break
                    case 'beneficiaryName':
                        fieldValue = beneficiaryName
                        setIsEditingBeneficiaryName(false)
                        break
                    case 'customerNum':
                        fieldValue = customerNum
                        setIsEditingCustomerNum(false)
                        break
                    case 'customerPpCountry':
                        fieldValue = customerPpCountry
                        setIsEditingCustomerPpCountry(false)
                        break
                    case 'customerPpNum':
                        fieldValue = customerPpNum
                        setIsEditingCustomerPpNum(false)
                        break
                    case 'customerPpExpiry':
                        fieldValue = customerPpExpiry
                        setIsEditingCustomerPpExpiry(false)
                        break
                    case 'customerTel':
                        fieldValue = customerTel
                        setIsEditingCustomerTel(false)
                        break
                    case 'registrationNum':
                        fieldValue = registrationNum
                        setIsEditingRegistrationNum(false)
                        break
                    case 'signatoryName':
                        fieldValue = signatoryName
                        setIsEditingSignatoryName(false)
                        break
                    case 'trialQuantity':
                        fieldValue = trialQuantity
                        setIsEditingTrialQuantity(false)
                        break
                    case 'unitQuantity':
                        fieldValue = unitQuantity
                        setIsEditingUnitQuantity(false)
                        break

                    // Add more cases for other fields as needed
                    default:
                        throw new Error(`Unsupported field: ${field}`);
                }

                updateData[field] = fieldValue;

                await updateDoc(orderRef, {
                    ...updateData,
                    lastEdited: Timestamp.now(),
                    lastEditedUser: userData[0].userFirstName + " " + userData[0].userLastName
                });

                // Reset editing state
                switch (field) {
                    case 'CustomerName':
                        setIsEditingCustomerName(false);
                        break;
                    case 'CustomerAddress':
                        setIsEditingCustomerAddress(false);
                        break;
                    // Add more cases for other fields as needed
                }

                setNewTitle(`You have updated the ${field} field`);
                setNewMessage(`${field} was updated successfully`);
                await delay(2000);
                getCustomerData();
                setToasterShow(true);
                await delay(5000);
                setToasterShow(false);
            } catch (error) {
                console.error(`Error updating ${field}: `, error);
            }
        }
    };

    return (
        <>
            {toasterShow ? (
                <Notifications newMessage={newMessage} newTitle={newTitle} />
            ) : (
                null
            )}
            {!customerData.length && loading ? (
                <LoadingEvent />
            ) : (
                <div className="relative sm:flex flex-col space-y-6 min-h-screen w-full bg-tanekaha3">
                    <div>
                        <div className="px-4 sm:px-0 sm:right-0 lg:mt-20 lg:right-0 mx-auto text-center">
                            <p className="top-1 text-base mx-auto font-bebas leading-7 text-gray-900 text-center lg:text-4xl">{customerData[0]?.CustomerName} </p>
                            <p className="mt-1 max-w-2xl text-xl leading-6 text-white mx-auto text-center">{customerData[0]?.customerNum}</p>
                        </div>

                        <dl>
                                <div className="grid grid-cols-3 gap-4 place-items-center">
                                    <dt className="text-sm font-medium text-gray-900"></dt>
                                    <dd className="mt-1 text-sm text-white">
                                    <span>
                                    <button 
                                        className="mt-3 mb-3 rounded-md bg-white px-2 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-red-500 hover:text-white"
                                        onClick={() => deleteCustomer()}
                                        >
                                        <FontAwesomeIcon icon={faTrash} />&nbsp;Delete Customer
                                        </button>
                                    </span>   
                                    </dd>
                                </div>
                            </dl>

                            
                        <div className="mx-auto mt-6 border-t border-gray-100 lg:w-1/2 lg:left-20 bottom md:w-3/4 sm:w-3/4 max-sm:w-4/5 ">
                           

                            <dl className="divide-y divide-gray-100">
                                {isEditingCustomerName ? (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Customer Name</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <input type="text" className="flex-grow text-black" value={CustomerName} onChange={(e) => setCustomerName(e.target.value)} />
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => saveRow('CustomerName')} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faFloppyDisk} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                ) : (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Customer Name</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <span className="flex-grow">{customerData[0]?.CustomerName}</span>
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={(e) => setIsEditingCustomerName(true)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black 0py-1 px-3">
                                                    <FontAwesomeIcon icon={faPencil} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                )}


                                {isEditingCustomerAddress ? (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Customer Address</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <input type="text" className="flex-grow text-black" value={CustomerAddress} onChange={(e) => setCustomerAddress(e.target.value)} />
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => saveRow('CustomerAddress')} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faFloppyDisk} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                ) : (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Customer Address</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <span className="flex-grow">{customerData[0]?.CustomerAddress}</span>
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={(e) => setIsEditingCustomerAddress(true)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faPencil} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                )}

                                {isEditingCustomerContactEmail ? (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Customer Contact Email</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <input type="text" className="flex-grow text-black" value={CustomerContactEmail} onChange={(e) => setCustomerContactEmail(e.target.value)} />
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => saveRow('CustomerContactEmail')} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faFloppyDisk} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                ) : (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Customer Contact Email</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <span className="flex-grow">{customerData[0]?.CustomerContactEmail}</span>
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={(e) => setIsEditingCustomerContactEmail(true)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faPencil} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                )}

                                {isEditingCustomerContactPerson ? (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Customer Contact Person</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <input type="text" className="flex-grow text-black" value={CustomerContactPerson} onChange={(e) => setCustomerContactPerson(e.target.value)} />
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => saveRow('CustomerContactPerson')} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faFloppyDisk} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                ) : (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Customer Contact Person</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <span className="flex-grow">{customerData[0]?.CustomerContactPerson}</span>
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={(e) => setIsEditingCustomerContactPerson(true)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faPencil} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                )}

                                {isEditingCustomerCountry ? (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Customer Country</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <input type="text" className="flex-grow text-black" value={CustomerCountry} onChange={(e) => setCustomerCountry(e.target.value)} />
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => saveRow('CustomerCountry')} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faFloppyDisk} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                ) : (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Customer Country</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <span className="flex-grow">{customerData[0]?.CustomerCountry}</span>
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={(e) => setIsEditingCustomerCountry(true)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faPencil} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                )}


                                {isEditingAccountName ? (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Account Name</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <input type="text" className="flex-grow text-black" value={accountName} onChange={(e) => setAccountName(e.target.value)} />
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => saveRow('accountName')} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faFloppyDisk} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                ) : (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Account Name</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <span className="flex-grow">{customerData[0]?.accountName}</span>
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={(e) => setIsEditingAccountName(true)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faPencil} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                )}

                                {isEditingBankAccountNum ? (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Bank Account Number</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <input type="text" className="flex-grow text-black" value={bankAccountNum} onChange={(e) => setBankAccountNum(e.target.value)} />
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => saveRow('bankAccountNum')} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faFloppyDisk} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                ) : (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Bank Account Number</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <span className="flex-grow">{customerData[0]?.bankAccountNum}</span>
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={(e) => setIsEditingBankAccountNum(true)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faPencil} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                )}

                                {isEditingBankAddress ? (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Bank Address</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <input type="text" className="flex-grow text-black" value={bankAddress} onChange={(e) => setBankAddress(e.target.value)} />
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => saveRow('bankAddress')} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faFloppyDisk} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                ) : (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Bank Address</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <span className="flex-grow">{customerData[0]?.bankAddress}</span>
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={(e) => setIsEditingBankAddress(true)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faPencil} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                )}

                                {isEditingBankEmail ? (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Bank Email</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <input type="text" className="flex-grow text-black" value={bankEmail} onChange={(e) => setBankEmail(e.target.value)} />
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => saveRow('bankEmail')} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faFloppyDisk} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                ) : (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Bank Email</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <span className="flex-grow">{customerData[0]?.bankEmail}</span>
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={(e) => setIsEditingBankEmail(true)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faPencil} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                )}

                                {isEditingBankName ? (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Bank Name</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <input type="text" className="flex-grow text-black" value={bankName} onChange={(e) => setBankName(e.target.value)} />
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => saveRow('bankName')} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faFloppyDisk} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                ) : (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Bank Name</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <span className="flex-grow">{customerData[0]?.bankName}</span>
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={(e) => setIsEditingBankName(true)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faPencil} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                )}

                                {isEditingBankOfficer ? (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Bank Officer</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <input type="text" className="flex-grow text-black" value={bankOfficer} onChange={(e) => setBankOfficer(e.target.value)} />
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => saveRow('bankOfficer')} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faFloppyDisk} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                ) : (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Bank Officer</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <span className="flex-grow">{customerData[0]?.bankOfficer}</span>
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={(e) => setIsEditingBankOfficer(true)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faPencil} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                )}

                                {isEditingBankSwift ? (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Bank Swift</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <input type="text" className="flex-grow text-black" value={bankSwift} onChange={(e) => setBankSwift(e.target.value)} />
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => saveRow('bankSwift')} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faFloppyDisk} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                ) : (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Bank Swift</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <span className="flex-grow">{customerData[0]?.bankSwift}</span>
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={(e) => setIsEditingBankSwift(true)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faPencil} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                )}

                                {isEditingBankTelephone ? (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Bank Telephone</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <input type="text" className="flex-grow text-black" value={bankTelephone} onChange={(e) => setBankTelephone(e.target.value)} />
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => saveRow('bankTelephone')} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faFloppyDisk} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                ) : (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Bank Telephone</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <span className="flex-grow">{customerData[0]?.bankTelephone}</span>
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={(e) => setIsEditingBankTelephone(true)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faPencil} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                )}

                                {isEditingBeneficiaryName ? (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Beneficiary Name</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <input type="text" className="flex-grow text-black" value={beneficiaryName} onChange={(e) => setBeneficiaryName(e.target.value)} />
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => saveRow('beneficiaryName')} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faFloppyDisk} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                ) : (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Beneficiary Name</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <span className="flex-grow">{customerData[0]?.beneficiaryName}</span>
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={(e) => setIsEditingBeneficiaryName(true)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faPencil} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                )}

                                {isEditingCustomerNum ? (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Customer Number</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <input type="text" className="flex-grow text-black" value={customerNum} onChange={(e) => setCustomerNum(e.target.value)} />
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => saveRow('customerNum')} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faFloppyDisk} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                ) : (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Customer Number</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <span className="flex-grow">{customerData[0]?.customerNum}</span>
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={(e) => setIsEditingCustomerNum(true)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faPencil} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                )}

                                {isEditingCustomerPpCountry ? (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Customer Passport Country</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <input type="text" className="flex-grow text-black" value={customerPpCountry} onChange={(e) => setCustomerPpCountry(e.target.value)} />
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => saveRow('customerPpCountry')} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faFloppyDisk} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                ) : (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Customer Passport Country</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <span className="flex-grow">{customerData[0]?.customerPpCountry}</span>
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={(e) => setIsEditingCustomerPpCountry(true)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faPencil} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                )}

                                {isEditingCustomerPpNum ? (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Customer Passport Number</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <input type="text" className="flex-grow text-black" value={customerPpNum} onChange={(e) => setCustomerPpNum(e.target.value)} />
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => saveRow('customerPpNum')} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faFloppyDisk} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                ) : (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Customer Passport Number</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <span className="flex-grow">{customerData[0]?.customerPpNum}</span>
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={(e) => setIsEditingCustomerPpNum(true)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faPencil} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                )}

                                {isEditingCustomerPpExpiry ? (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Customer Passport Expiry</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <input type="date" className="flex-grow text-black" value={customerPpExpiry} onChange={(e) => setCustomerPpExpiry(e.target.value)} />
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => saveRow('customerPpExpiry')} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faFloppyDisk} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                ) : (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Customer Passport Expiry</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <span className="flex-grow">{customerData[0]?.customerPpExpiry.toDate().toString()}</span>
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={(e) => setIsEditingCustomerPpExpiry(true)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faPencil} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                )}

                                {isEditingCustomerTel ? (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Customer Telephone</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <input type="tel" className="flex-grow text-black" value={customerTel} onChange={(e) => setCustomerTel(e.target.value)} />
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => saveRow('customerTel')} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faFloppyDisk} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                ) : (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Customer Telephone</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <span className="flex-grow">{customerData[0]?.customerTel}</span>
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={(e) => setIsEditingCustomerTel(true)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faPencil} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                )}

                                {isEditingRegistrationNum ? (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Registration Number</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <input type="text" className="flex-grow text-black" value={registrationNum} onChange={(e) => setRegistrationNum(e.target.value)} />
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={() => saveRow('registrationNum')} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faFloppyDisk} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                ) : (
                                    <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Registration Number</dt>
                                        <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                            <span className="flex-grow">{customerData[0]?.registrationNum}</span>
                                            <span className="ml-4 flex-shrink-0">
                                                <button type="button" onClick={(e) => setIsEditingRegistrationNum(true)} className="text-black bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-3">
                                                    <FontAwesomeIcon icon={faPencil} />
                                                </button>
                                            </span>
                                        </dd>
                                    </div>
                                )}



                                {/* Customer notes section here*/}
                                <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-medium leading-6 text-gray-900">
                                        Customer Notes
                                        <br></br>
                                        <button
                                            onClick={() => setShowNewNoteForm(!showNewNoteForm)}
                                            className="mt-2 text-white bg-black hover:bg-main font-bold py-1 px-2 rounded text-xs"
                                        >
                                            {showNewNoteForm ? 'Cancel' : 'Add New Note'}
                                        </button>
                                    </dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                        {customerData[0]?.notes?.length > 0 && customerData[0].notes.map((note, index) => (
                                            <div key={index} className="mb-8">
                                                {/* Note editor section here*/}
                                                {editingNoteIndex === index ? (
                                                    <div>
                                                        <input
                                                            type="text"
                                                            value={newNote?.noteTitle}
                                                            onChange={(e) => setNewNote({ ...newNote, noteTitle: e.target.value })}
                                                            className="w-full mb-2 p-2 border rounded text-black"
                                                        />
                                                        <textarea
                                                            value={newNote?.noteDescription}
                                                            onChange={(e) => setNewNote({ ...newNote, noteDescription: e.target.value })}
                                                            className="w-full mb-2 p-2 border rounded text-black"
                                                        />
                                                        <select
                                                            id="noteStatus"
                                                            name="noteStatus"
                                                            value={newNote?.noteStatus}
                                                            onChange={(e) => setNewNote({ ...newNote, noteStatus: e.target.value })}
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6 mb-2"
                                                        >
                                                            {statusOptions.map((option) => (
                                                                <option key={option} value={option}>
                                                                    {option}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <select
                                                            id="assignedTo"
                                                            name="assignedTo"
                                                            value={newNote.assignedToUser}
                                                            onChange={(e) => setNewNote({ ...newNote, assignedToUser: e.target.value })}
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6 mb-2"
                                                        >
                                                            <option value="">Select a user</option>
                                                            {userNames.map((name, index) => (
                                                                <option key={index} value={name}>
                                                                    {name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <DatePicker
                                                            selected={newNote?.followUpDate?.toDate()}
                                                            onChange={(date) => {
                                                                const parsedDate = Timestamp.fromDate(date);
                                                                setNewNote({ ...newNote, followUpDate: parsedDate });
                                                            }}
                                                            dateFormat="dd-MM-yyyy"
                                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm mb-2"
                                                        />
                                                        <br></br>
                                                        <button
                                                            onClick={() => editNote(index, newNote)}
                                                            className="text-white bg-green-500 hover:bg-green-700 font-bold py-1 px-2 rounded text-xs mr-2"
                                                        >
                                                            Save
                                                        </button>
                                                        <button
                                                            onClick={() => setEditingNoteIndex(null)}
                                                            className="text-white bg-gray-500 hover:bg-gray-700 font-bold py-1 px-2 rounded text-xs"
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                ) : (
                                                    <>
                                                        {/* Displayed notes here*/}
                                                        <div className="mx-auto bg-gray-200 rounded-md px-1 py-2 mb-2">
                                                            <h4 className="font-mono text-black mb-2 decoration-main text-xl underline decoration-main">{note?.noteTitle}</h4>
                                                            <p className="bg-white rounded-md py-1 px-2 mb-2">{note?.noteDescription}</p>
                                                            <p className="text-xs text-black mb-2">
                                                                Added by: {note?.addedByUser} on {note?.dateAdded.toDate().toLocaleDateString('en-NZ', {
                                                                    year: 'numeric',
                                                                    month: '2-digit',
                                                                    day: '2-digit'
                                                                })};
                                                            </p>
                                                            <p className={`text-xs text-white rounded-md py-1 px-2 mb-2 ${getStatusColor(note?.noteStatus)}`}>
                                                                Status: {note?.noteStatus}
                                                            </p>
                                                            <p className="text-xs text-black bg-white rounded-md py-1 px-2 mb-2">
                                                                Assigned to: {note?.assignedToUser} on {note?.dateAssigned.toDate().toLocaleDateString('en-NZ', {
                                                                    year: 'numeric',
                                                                    month: '2-digit',
                                                                    day: '2-digit'
                                                                })}
                                                            </p>
                                                            <p className="text-xs text-black py-1 px-2 mb-2">
                                                                <FontAwesomeIcon icon={faExclamation} bounce />{" "}Follow up due: {note?.followUpDate?.toDate().toLocaleDateString('en-NZ', {
                                                                    year: 'numeric',
                                                                    month: '2-digit',
                                                                    day: '2-digit'
                                                                })}
                                                            </p>
                                                            <button
                                                                onClick={() => {
                                                                    setNewNote(note);
                                                                    setEditingNoteIndex(index);
                                                                }}
                                                                className="mt-2 text-white bg-gray-400 hover:bg-black font-bold py-1 px-2 rounded text-xs mr-2"
                                                            >
                                                                <FontAwesomeIcon icon={faPencil} />{"  "}Edit
                                                            </button>
                                                            <button
                                                                onClick={() => deleteNote(index)}
                                                                className="mt-2 text-white bg-main hover:bg-red-700 font-bold py-1 px-2 rounded text-xs"
                                                            >
                                                                Delete
                                                            </button>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        ))}
                                        {/** New Note Form */}
                                        {showNewNoteForm && (
                                            <div className="mt-4 bg-white p-4 rounded-md">
                                                <input
                                                    type="text"
                                                    placeholder="Note Title"
                                                    value={newNote.noteTitle}
                                                    onChange={(e) => setNewNote({ ...newNote, noteTitle: e.target.value })}
                                                    className="w-full mb-2 p-2 border rounded"
                                                />
                                                <textarea
                                                    placeholder="Note Description"
                                                    value={newNote.noteDescription}
                                                    onChange={(e) => setNewNote({ ...newNote, noteDescription: e.target.value })}
                                                    className="w-full mb-2 p-2 border rounded"
                                                />
                                                <select
                                                    id="status"
                                                    name="status"
                                                    value={newNote.noteStatus}
                                                    onChange={(e) => setNewNote({ ...newNote, noteStatus: e.target.value })}
                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6 mb-2"
                                                >
                                                    <option value="">Select a status</option>
                                                    {statusOptions.map((name, index) => (
                                                        <option key={index} value={name}>
                                                            {name}
                                                        </option>
                                                    ))}
                                                </select>
                                                <select
                                                    id="assignedToUser"
                                                    name="assignedToUser"
                                                    value={newNote.assignedToUser}
                                                    onChange={(e) => setNewNote({ ...newNote, assignedToUser: e.target.value })}
                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6 mb-2"
                                                >
                                                    <option value="">Select the assignee</option>
                                                    {userNames.map((name, index) => (
                                                        <option key={index} value={name}>
                                                            {name}
                                                        </option>
                                                    ))}
                                                </select>
                                                <label htmlFor="followUpDate" className="block text-sm font-medium text-gray-700">
                                                    Follow-up Date
                                                </label>
                                                <DatePicker
                                                    id="followUpDate"
                                                    selected={newNote.followUpDate}
                                                    onChange={(date) => {
                                                        const utcDate = date ? new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())) : null;
                                                        setNewNote({ ...newNote, followUpDate: utcDate });
                                                    }}
                                                    dateFormat="dd-MM-yyyy"
                                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm mb-2"
                                                />
                                                <br></br>
                                                <button
                                                    onClick={saveNewNote}
                                                    className="bg-gray-400 hover:bg-black text-white font-bold py-2 px-4 rounded"
                                                >
                                                    Save
                                                </button>
                                            </div>
                                        )}
                                    </dd>

                                </div>

                                {/* Attachments section here */}
                                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-medium leading-6 text-gray-900">Attachments</dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                        <ul role="list" className="divide-y divide-gray-100 rounded-md border border-gray-200">
                                            {attachments.map((attachment, index) => (
                                                <li key={index} className="bg-gray-200 flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                                    <div className="flex w-0 flex-1 items-center">
                                                        <PaperClipIcon aria-hidden="true" className="h-5 w-5 flex-shrink-0 text-black" />
                                                        <div className="ml-4 flex min-w-0 flex-1 gap-2">
                                                            <span
                                                                className="font-medium cursor-pointer hover:underline"
                                                                onClick={() => downloadFile(attachment.name)}
                                                            >
                                                                {attachment.name}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="ml-4 flex flex-shrink-0 space-x-4">
                                                        <button type="button" onClick={() => deleteFile(attachment.name)} className="text-black bg-white rounded hover:text-white hover:bg-main py-1 px-1">
                                                            Remove
                                                        </button>
                                                    </div>
                                                </li>
                                            ))}
                                            <li className="bg-gray-200 flex justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                                <div className="flex w-0 flex-1">
                                                    <input type="file" className="mx-auto mt-1 text-black font-bold py-3 px-3 rounded text-md" onChange={handleFileChange} ref={fileInputRef} />

                                                </div>
                                            </li>
                                            <li className="bg-gray-200 flex justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                                <button onClick={uploadFile} className="mx-auto mt-2 text-white bg-black hover:bg-main hover:text-white font-bold py-2 px-2 rounded text-md left-0">
                                                    Upload File
                                                </button>
                                            </li>
                                        </ul>
                                        <div className="mt-4 relative">

                                        </div>
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </div>
            )
            }</>
    )
}