import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid'
import { AuthContext } from '../../Context/AuthContext'
import { useEffect, useContext, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { db, storage } from "../../firebase/firebase";
import { getDoc, doc, updateDoc, setDoc, deleteDoc, getDocs, collection, Timestamp } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk, faPencil, faPlus } from '@fortawesome/free-solid-svg-icons';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import Loading from '../Loading';


export default function Customers() {

  // Page loading State
  const [loading, setLoading] = useState(true);

  // current user logged in State
  const { currentUser, setCurrentUser, signOut } = useContext(AuthContext);
  const auth = useContext(AuthContext)
  const navigate = useNavigate()
  const userEmail = auth.currentUser?.email
  const [userData, setUserData] = useState<userDataInterface>();
  const [passwordValue, setPasswordValue] = useState("");

  // Random number generator
  const randn = require('randn');

  const [customerAddress, setCustomerAddress] = useState('');
  const [customerContactEmail, setCustomerContactEmail] = useState('');
  const [customerContactPerson, setCustomerContactPerson] = useState('');
  const [customerCountry, setCustomerCountry] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [accountName, setAccountName] = useState('');
  const [bankAccountNum, setBankAccountNum] = useState('');
  const [bankAddress, setBankAddress] = useState('');
  const [bankEmail, setBankEmail] = useState('');
  const [bankName, setBankName] = useState('');
  const [bankOfficer, setBankOfficer] = useState('');
  const [bankSwift, setBankSwift] = useState('');
  const [bankTelephone, setBankTelephone] = useState('');
  const [beneficiaryName, setBeneficiaryName] = useState('');
  const [customerNum, setCustomerNum] = useState('');
  const [customerPpCountry, setCustomerPpCountry] = useState('');
  const [customerPpNum, setCustomerPpNum] = useState('');
  const [customerTel, setCustomerTel] = useState('');
  const [registrationNum, setRegistrationNum] = useState('');
  const [signatoryName, setSignatoryName] = useState('');

  const [customers, setCustomers] = useState<CustomerDataInterface[]>([]);

  type userDataInterface = {
    contactPh: string;
    customerNum: string;
    email: string;
    firstName: string;
    lastName: string;
    imageUrl: string;
    role: string;
    address: string;
  }[];


  const getDataFirestore = async () => {
    setLoading(true);
    const querySnapshot = await getDocs(collection(db, "CustomerData"));
    const customerInfo = querySnapshot.docs.map(doc => {
      const data = doc.data();
      return {
        CustomerAddress: data.CustomerAddress,
        CustomerContactEmail: data.CustomerContactEmail,
        CustomerContactPerson: data.CustomerContactPerson,
        CustomerCountry: data.CustomerCountry,
        CustomerName: data.CustomerName,
        accountName: data.accountName,
        bankAccountNum: data.bankAccountNum,
        bankAddress: data.bankAddress,
        bankEmail: data.bankEmail,
        bankName: data.bankName,
        bankOfficer: data.bankOfficer,
        bankSwift: data.bankSwift,
        bankTelephone: data.bankTelephone,
        beneficiaryName: data.beneficiaryName,
        customerNum: data.customerNum,
        customerPpCountry: data.customerPpCountry,
        customerPpNum: data.customerPpNum,
        customerPpExpiry: data.customerPpExpiry,
        customerTel: data.customerTel,
        registrationNum: data.registraionNum,
        signatoryName: data.signatoryName,
        trialQuantity: data.trialQuantity,
        unitQuantity: data.unitQuantity
      };
    });
    setCustomers(customerInfo);
    setLoading(false);
  };

  type CustomerDataInterface = {
    CustomerAddress: string;
    CustomerContactEmail: string;
    CustomerContactPerson: string;
    CustomerCountry: string;
    CustomerName: string;
    accountName: string;
    bankAccountNum: string;
    bankAddress: string;
    bankEmail: string;
    bankName: string;
    bankOfficer: string;
    bankSwift: string;
    bankTelephone: string;
    beneficiaryName: string;
    customerNum: string;
    customerPpCountry: string;
    customerPpNum: string;
    customerPpExpiry: Timestamp;
    customerTel: string;
    registrationNum: string;
    signatoryName: string;
  };


  const handleSave = async (e) => {
    e.preventDefault();
    try {
      await setDoc(doc(db, "CustomerData", customerNum), {
        CustomerAddress: customerAddress,
        CustomerContactEmail: customerContactEmail,
        CustomerContactPerson: customerContactPerson,
        CustomerCountry: customerCountry,
        CustomerName: customerName,
        accountName: accountName,
        bankAccountNum: bankAccountNum,
        bankAddress: bankAddress,
        bankEmail: bankEmail,
        bankName: bankName,
        bankOfficer: bankOfficer,
        bankSwift: bankSwift,
        bankTelephone: bankTelephone,
        beneficiaryName: beneficiaryName,
        customerNum: customerNum,
        customerPpCountry: customerPpCountry,
        customerPpNum: customerPpNum,
        // customerPpExpiry: customerPpExpiry,
        customerTel: customerTel,
        registrationNum: registrationNum,
        signatoryName: signatoryName,
      });
      console.log("Document successfully written!");
    } catch (error) {
      console.error("Error writing document: ", error);
    }
  };

  const selectCustomer = (customerId: string) => {
    navigate(`/SelectCustomer/${customerId}`)
  }

  useEffect(() => {
    if (userEmail == undefined) {
      navigate('/Portal')
      console.log('User is not authorised to acces this page')
    } else {
      setLoading(true);
      getDataFirestore();
      setLoading(false);
    }

  }, [userEmail])

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div className="inset-y-0 right-0 lg:absolute sm:flex flex-col min-h-screen w-full bg-tanekaha3">
          <div className="absolute mx-auto overflow-y-auto lg:top-0 lg:right-10 lg:w-3/4 md:top-30 md:right-10 md:w-3/4 h-screen sm:right-5 sm:top-30 sm:right-10 sm:w-full max-sm:top-30 max-sm:right-10 max-sm:full">
            <header className="bg-primary text-primary-foreground py-4 text-left">
              <div className="container mx-auto flex justify-between">
                <h1 className="lg:text-4xl font-bebas text-left text-black mt-10 max-sm:text-xl sm:text-xl">TANEKAHA PORTAL</h1>
              </div>
            </header>
            <div className="flex flex-1 bg-background place-content-center">
              <div className="flex-1 mx-auto">
                <main className="flex-1 bg-background justify-items-center">
                  <div className="container mx-auto grid grid-cols-1 md:grid-cols-1 place-items-center">
                    <div className="mx-auto w-auto">
                      <h2 className="lg:text-2xl font-bebas mb-4 text-left max-sm:text-lg sm:text-lg">CUSTOMERS</h2>
                      <button 
                      type="button" 
                      className="rounded-md bg-white px-3.5 py-2.5 text-sm font-regular text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-black hover:text-white" 
                      onClick={() => navigate('/NewCustomer')}>
                        <FontAwesomeIcon icon={faPlus} />
                        &nbsp;Add Customer
                      </button>
                      <br></br>
                      <br></br>
                      <table className="divide-y divide-gray-400 mx-auto bg-white">
                        <thead className="bg-gray-200">
                          <tr>
                            <th className="py-3.5 pl-4 pr-3 text-center font-regular text-black sm:pl-6 max-sm:text-xs sm:text-xs lg:text-sm">Customer #</th>
                            <th className="px-3 py-3.5 text-cemter font-regular text-black max-sm:text-xs sm:text-xs lg:text-sm">Customer Name</th>
                            <th className="px-3 py-3.5 text-cemter font-regular text-black max-sm:text-xs sm:text-xs lg:text-sm">Customer Address</th>
                            <th className="px-3 py-3.5 text-cemter font-regular text-black max-sm:text-xs sm:text-xs lg:text-sm">Customer Country</th>
                            <th className="px-3 py-3.5 text-cemter font-regular text-black max-sm:text-xs sm:text-xs lg:text-sm">Customer Contact Person</th>
                            <th className="px-3 py-3.5 text-cemter font-regular text-black max-sm:text-xs sm:text-xs lg:text-sm">Customer Contact Person Email</th>
                          </tr>
                        </thead>
                        <tbody>
                          {customers.map((customer) => (
                            <tr className="divide-y divide-gray-200 lg:w-full sm:w-full" key={customer.customerNum}>
                              <td className="whitespace-normal text-center text-wrap text-gray-900 max-sm:text-xs max-sm:py-1 max-sm:pl-2 sm:text-xs lg:text-sm lg:gap-x-1 lg:py-1 lg:px-3"><button className="bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-1" onClick={() => selectCustomer(customer.customerNum)}> {customer.customerNum}</button></td>
                              <td className="whitespace-normal text-center text-wrap text-gray-900 max-sm:text-xs sm:text-xs lg:text-sm lg:gap-x-1 lg:py-1 lg:px-3">{customer.CustomerName}</td>
                              <td className="whitespace-normal text-center text-wrap text-gray-900 max-sm:text-xs sm:text-xs lg:text-sm lg:gap-x-1 lg:py-1 lg:px-3">{customer.CustomerAddress}</td>
                              <td className="whitespace-normal text-center text-wrap text-gray-900 max-sm:text-xs sm:text-xs lg:text-sm lg:gap-x-1 lg:py-1 lg:px-3">{customer.CustomerCountry}</td>
                              <td className="whitespace-normal text-center text-wrap text-gray-900 max-sm:text-xs sm:text-xs lg:text-sm lg:gap-x-1 lg:py-1 lg:px-3">{customer.CustomerContactPerson}</td>
                              <td className="whitespace-normal text-center text-wrap text-gray-900 max-sm:text-xs sm:text-xs lg:text-sm lg:gap-x-1 lg:py-1 lg:px-3">{customer.CustomerContactEmail}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>

                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
          <footer className="absolute sm:hidden max-sm:hidden inset-x-0 bottom-10 bg-muted text-muted-foreground">
            <div className="container mx-auto text-center">© 2024 Tanekaha Fuels. All rights reserved.</div>
          </footer>
        </div>
      )}
    </div>
  );
}