import Header from "../components/CustomerPortal/Header";
import SideBar from "../components/CustomerPortal/SideBar";
import { useParams } from "react-router-dom";
import NewCustomer from "../components/CustomerPortal/NewCustomer";


function CustomerSelectPage() {
  const { customerId } = useParams();

  return(
      <div>
        <Header />
        <SideBar />
        <NewCustomer/>
      </div>
  );
}

export default CustomerSelectPage;
