import Header from "../components/CustomerPortal/Header";
import SideBar from "../components/CustomerPortal/SideBar";
import { useParams } from "react-router-dom";
import NewOrder from "../components/CustomerPortal/NewOrder";


function NewOrderPage() {
  const { customerId } = useParams();

  return(
      <div>
        <Header />
        <SideBar />
        <NewOrder />
      </div>
  );
}

export default NewOrderPage;
