import React from 'react';
import './App.css';
import Main from './pages/Main';
import {Routes, Route } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import About from './pages/About'
import CustomerPortal from './pages/CurrentOrdersPage';
import OrderPage from './pages/OrderPage';
import Loading from './components/Loading';
import { AuthContext} from './Context/AuthContext';
import { useContext } from 'react';
import ProfilePage from './pages/ProfilePage';
import SelectPage from './pages/SelectPage';
import OrderHistoryPage from './pages/OrderHistoryPage';
import CurrentOrdersPage from './pages/CurrentOrdersPage';
import CustomersPage from './pages/CustomersPage';
import CustomerSelectPage from './pages/CustomerSelectPage';
import NewCustomerPage from './pages/NewCustomerPage';
import NewOrderPage from './pages/NewOrderPage';
import { DashboardIcon } from '@radix-ui/react-icons';
import DashboardPage from './pages/DashboardPage';


function App() {
  const { currentUser, setCurrentUser, signOut, loading} = useContext(AuthContext);
  if (loading) {
    return <Loading />;
  }
  
  return (
  <AuthContext.Provider value={{currentUser, setCurrentUser, signOut, loading}}>
    <Routes>
      <Route path="/" element={<LoginPage />}/>
      <Route path="/Login" element={<LoginPage />}/>
      <Route path="/Profile" element={<ProfilePage />}/>
      <Route path="/Dashboard" element={<DashboardPage />}/>
      <Route path="/Portal" element={<DashboardPage/>}/>
      <Route path="/CurrentOrders" element={<CurrentOrdersPage/>}/>
      <Route path="/OrderHistory" element={<OrderHistoryPage/>}/>
      <Route path="/Customers" element={<CustomersPage/>}/>
      <Route path="/NewCustomer" element={<NewCustomerPage/>}/>
      <Route path="/NewOrder" element={<NewOrderPage/>}/>
      <Route path="/Order" element={<OrderPage />}/>  
      <Route path="/SelectOrder/:orderId" element={<SelectPage />} />
      <Route path="/SelectCustomer/:customerId" element={<CustomerSelectPage />} />
   </Routes>
 </AuthContext.Provider>
  );
}

export default App;
