import { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../Loading";
import { collection, getDocs, Timestamp, query, where, doc, getDoc, updateDoc, arrayUnion, setDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { AuthContext } from "../../Context/AuthContext";
import { PaperClipIcon } from '@heroicons/react/20/solid'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { faExclamation, faFloppyDisk, faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ref, uploadBytes, listAll, getDownloadURL, deleteObject } from "firebase/storage";
import { storage } from "../../firebase/firebase";
import Notifications from "../Notifications";


export default function NewOrder() {
    const [orders, setOrders] = useState<ordersInterface>([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const GenerateOrderNumber = Math.floor(1000 + Math.random() * 9000);


    {/* CURRENT USER DATA */ }
    const [userData, setUserData] = useState<userDataInterface>();
    const { currentUser, setCurrentUser, signOut } = useContext(AuthContext);
    const userEmail = currentUser?.email;

    //Constants for notes section
    const [showNewNoteForm, setShowNewNoteForm] = useState(false);
    const [newNote, setNewNote] = useState({ noteTitle: '', noteDescription: '', assignedToUser: '', noteStatus: '', followUpDate: null });
    const [editingNoteIndex, setEditingNoteIndex] = useState<number | null>(null);

    // constant for editing rows
    const [editBuyer, setEditBuyer] = useState(false)
    const [buyerValue, setBuyerValue] = useState("")

    const [editBuyerAddress, setEditBuyerAddress] = useState(false)
    const [buyerAddressValue, setBuyerAddressValue] = useState("")

    const [editBuyerRepresentation, setEditBuyerRepresentation] = useState(false)
    const [buyerRepresentationValue, setBuyerRepresentationValue] = useState("")

    const [editComissionBuyer, setEditComissionBuyer] = useState(false)
    const [comissionBuyerValue, setComissionBuyerValue] = useState<number>(0)

    const [editComissionSeller, setEditComissionSeller] = useState(false)
    const [comissionSellerValue, setComissionSellerValue] = useState<number>(0)

    const [editCommodity, setEditCommodity] = useState(false)
    const [commodityValue, setCommodityValue] = useState("")

    const [editWholesalePrice, setEditWholesalePrice] = useState(false)
    const [wholesalePriceValue, setWholesalePriceValue] = useState("")

    const [editTanekahaCommission, setEditTanekahaCommission] = useState(false)
    const [tanekahaCommissionValue, setTanekahaCommissionValue] = useState("")

    const [editConsultant, setEditConsultant] = useState(false)
    const [consultantValue, setConsultantValue] = useState("")

    const [editCustomerName, setEditCustomerName] = useState(false)
    const [customerNameValue, setCustomerNameValue] = useState("")

    const [editCustomerNum, setEditCustomerNum] = useState(false)
    const [customerNumValue, setCustomerNumValue] = useState("")

    const [editDate, setEditDate] = useState(false)
    const [dateValue, setDateValue] = useState(null)

    const [editFollowing, setEditFollowing] = useState(false)
    const [followingValue, setFollowingValue] = useState("")

    const [editFuelType, setEditFuelType] = useState(false)
    const [fuelTypeValue, setFuelTypeValue] = useState("")

    const [editGrossPrice, setEditGrossPrice] = useState(false)
    const [grossPriceValue, setGrossPriceValue] = useState("")

    const [editGrossPriceCurrency, setEditGrossPriceCurrency] = useState(false)
    const [grossPriceCurrencyValue, setGrossPriceCurrencyValue] = useState("")

    const [editGuaranteeForContract, setEditGuaranteeForContract] = useState(false)
    const [guaranteeForContractValue, setGuaranteeForContractValue] = useState("")

    const [editId, setEditId] = useState(false)
    const [idValue, setIdValue] = useState()

    const [editInspectionValidity, setEditInspectionValidity] = useState(false)
    const [inspectionValidityValue, setInspectionValidityValue] = useState("")

    const [editLoi, setEditLoi] = useState(false)
    const [loiValue, setLoiValue] = useState("")

    const [editOneYearContractPayment, setEditOneYearContractPayment] = useState(false)
    const [oneYearContractPaymentValue, setOneYearContractPaymentValue] = useState("")

    const [editOrderNum, setEditOrderNum] = useState(false)
    const [orderNumValue, setOrderNumValue] = useState("")

    const [editOrigin, setEditOrigin] = useState(false)
    const [originValue, setOriginValue] = useState("")

    const [editPaymentForTrial, setEditPaymentForTrial] = useState(false)
    const [paymentForTrialValue, setPaymentForTrialValue] = useState("")

    const [editPaymentStatus, setEditPaymentStatus] = useState(false)
    const [paymentStatusValue, setPaymentStatusValue] = useState("")

    const [editPerformanceBond, setEditPerformanceBond] = useState(false)
    const [performanceBondValue, setPerformanceBondValue] = useState("")

    const [editQuantity, setEditQuantity] = useState(false)
    const [quantityValue, setQuantityValue] = useState("")

    const [editQuantityFrequency, setEditQuantityFrequency] = useState(false);
    const [quantityFrequencyValue, setQuantityFrequencyValue] = useState('');

    const [editRefinery, setEditRefinery] = useState(false);
    const [refineryValue, setRefineryValue] = useState('');

    const [editRefineryDirector, setEditRefineryDirector] = useState(false);
    const [refineryDirectorValue, setRefineryDirectorValue] = useState('');

    const [editRepresentedBy, setEditRepresentedBy] = useState(false);
    const [representedByValue, setRepresentedByValue] = useState('');

    const [editStatus, setEditStatus] = useState(false);
    const [statusValue, setStatusValue] = useState('');

    const [editTrialQuantity, setEditTrialQuantity] = useState(false);
    const [trialQuantityValue, setTrialQuantityValue] = useState<number>(0);

    const [editUnitQuantity, setEditUnitQuantity] = useState(false);
    const [unitQuantityValue, setUnitQuantityValue] = useState('MT');

    const [editGlobalQuantity, setEditGlobalQuantity] = useState(false);
    const [globalQuantityValue, setGlobalQuantityValue] = useState('');

    const [editDeliveryTrial, setEditDeliveryTrial] = useState(false);
    const [deliveryTrialValue, setDeliveryTrialValue] = useState('');

    const [editDeliveryRegular, setEditDeliveryRegular] = useState(false);
    const [deliveryRegularValue, setDeliveryRegularValue] = useState('');

    const [editLoadingPort, setEditLoadingPort] = useState(false);
    const [loadingPortValue, setLoadingPortValue] = useState('');

    const [editTrackingURL, setEditTrackingURL] = useState(false);
    const [trackingURLValue, setTrackingURLValue] = useState('');

    const [editFirstDelivery, setEditFirstDelivery] = useState(false);
    const [firstDeliveryValue, setFirstDeliveryValue] = useState('');

    const [editPriceGrossBuyer, setEditPriceGrossBuyer] = useState(false);
    const [priceGrossBuyerValue, setPriceGrossBuyerValue] = useState('');

    const [editTermsContract, setEditTermsContract] = useState(false);
    const [termsContractValue, setTermsContractValue] = useState('');


    // Toaster notification state
    const [toasterShow, setToasterShow] = useState(false);
    const [newMessage, setNewMessage] = useState("");
    const [newTitle, setNewTitle] = useState("");


    type userDataInterface = {
        userContactPh: string;
        userCustomerNum: string;
        userEmail: string;
        userFirstName: string;
        userLastName: string;
        imageUrl: string;
        role: string;
    }[];

    const getUserData = async () => {

        const docRef = doc(db, "Users", userEmail);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            const data = docSnap.data()
            setUserData([
                {
                    userContactPh: data.contactPh,
                    userCustomerNum: data.customerNum,
                    userEmail: data.email,
                    userFirstName: data.firstName,
                    userLastName: data.lastName,
                    imageUrl: data.imageUrl,
                    role: data.role
                }]
            );
        } else {
            alert('No such Id. Please check again');
        }

    }

    // Delay function
    function delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }


    type ordersInterface = {
        buyer: string;
        buyerAddress: string;
        buyerRepresentation: string;
        comissionBuyer: number;
        comissionSeller: number;
        commodity: string;
        consultant: string;
        customerName: string;
        customerNum: string;
        date: Timestamp;
        following: string;
        fuelType: string;
        grossPrice: number;
        grossPriceCurrency: string;
        guaranteeForContract: string;
        id: number;
        inspectionValidity: string;
        loi: string;
        oneYearContractPayment: string;
        orderNum: string;
        origin: string;
        paymentForTrial: string;
        paymentStatus: string;
        performanceBond: string;
        quantity: number;
        quantityFrequency: string;
        refinery: string;
        refineryDirector: string;
        representedBy: string;
        status: string;
        trialQuantity: number;
        unitQuantity: string;
        globalQuantity: string;
        deliveryTrial: string;
        deliveryRegular: string;
        loadingPort: string;
        trackingURL: string;
        firstDelivery: string;
        priceGrossBuyer: string;
        termsContract: string;
        tanekahaCommission: string;
        notes: {
            addedByUser: string;
            assignedToUser: string;
            dateAdded: Timestamp;
            dateAssigned: Timestamp;
            lastEdited: Timestamp;
            lastEditedBy: string;
            noteDescription: string;
            noteTitle: string;
            noteStatus: string;
            followUpDate: Timestamp;
        }[];

    }[];

    // get customer names for new order form
    const [customerNames, setCustomerNames] = useState<string[]>([]);
    const [customerAddresses, setCustomerAddresses] = useState<string[]>([]);
    const fetchCustomerNames = async () => {
        const customersCollection = collection(db, 'CustomerData');
        const usersSnapshot = await getDocs(customersCollection);
        const names = usersSnapshot.docs.map(doc => {
            const customerData = doc.data();
            return `${customerData.CustomerName}`;
        });
        const addresses = usersSnapshot.docs.map(doc => {
            const customerData = doc.data();
            return `${customerData.CustomerAddress}`;
        });
        const customerNumber = usersSnapshot.docs.map(doc => {
            const customerData = doc.data();
            return `${customerData.customerNum}`;
        });
        setCustomerNames(names);
        setCustomerAddresses(addresses);
    };

    const LoadingEvent = () => (
        <Loading />
    );

    // MAIN USE EFFECT
    useEffect(() => {
        if (userEmail === undefined) {
            navigate('/Portal')
            console.log("Current user is not authorised")
        } else {
            setLoading(true);
            //Get order data from firestore
            getUserData();
            //Get all users from firestore
            fetchCustomerNames();
            // Update automatic fields
            automaticFields();
            console.log(userEmail + " is logged on currently")
            setLoading(false);
        }
    }, [currentUser, userEmail, buyerValue]);

    // Global Quantity effect
    useEffect(() => {
        setGlobalQuantityValue((Number(quantityValue) * 11 + trialQuantityValue) + 'MT');
        setDeliveryTrialValue(trialQuantityValue.toString() + 'MT');
        setDeliveryRegularValue(quantityValue + 'MT');
    }, [quantityValue, trialQuantityValue]);

    // NOTE STATUS COLOR SETTINGS
    const getStatusColor = (status: string) => {
        switch (status?.toLowerCase()) {
            case 'closed completed':
                return 'bg-green-500';
            case 'in progress':
                return 'bg-yellow-500';
            case 'open':
                return 'bg-green-500';
            case 'pending':
                return 'bg-red-500';
            case 'on hold':
                return 'bg-red-500';
            case 'closed incomplete':
                return 'bg-red-500';
            default:
                return 'bg-gray-500';
        }
    };

    // NOTE STATUS OPTIONS
    const statusOptions = [
        'open',
        'in progress',
        'pending',
        'on hold',
        'closed incomplete',
        'closed completed'
    ];

    const automaticFields = async () => {
        if (buyerValue) {
            try {
                const q = query(collection(db, "CustomerData"), where("CustomerName", "==", buyerValue));
                const customerSnapshot = await getDocs(q);

                if (!customerSnapshot.empty) {
                    const customerData = customerSnapshot.docs[0]
                    const data = customerData.data();
                    console.log('Customer data:', customerData)
                    setConsultantValue('BEAZLEY GROUP PTYLTD/TANEKAHA FUEL LTD.')
                    setQuantityValue('200000')
                    setTrialQuantityValue(200000)
                    setGlobalQuantityValue((Number(quantityValue) * 11 + trialQuantityValue ) + 'MT')
                    setTermsContractValue('12 months with R&E')
                    setFirstDeliveryValue('See Procedure (1) CIF & ASWP')
                    setBuyerAddressValue(data.CustomerAddress || '')
                    setBuyerRepresentationValue(data.CustomerContactPerson || '')
                    setPaymentForTrialValue('By MT103 TT Wire Transfer for the first delivery')
                    setOneYearContractPaymentValue('Payment By MT103 TT Wire Transfer for each shipment')
                    setGuaranteeForContractValue('By SBLC MT760 with Total amount of Two shipment and valid 366days')
                    setPerformanceBondValue('2% of SBLC value issuing by Seller in favor of Buyer for the Contract')
                    setInspectionValidityValue('By SGS at loading port by Seller/by SGS at destination port by Buyer Seven (7) days from transmittal of documents')
                    setComissionBuyerValue(10)
                    setComissionSellerValue(10)
                    setTanekahaCommissionValue("20")
                    setWholesalePriceValue("450")
                    setFollowingValue("HMR/TFL/EURO5/10PPM")
                    setCommodityValue("EN590 10 PPM")
                    setGrossPriceValue("450")
                } else {
                    console.log('No customer data found for the selected name')
                }
            } catch (error) {
                console.error('Error fetching customer data:', error)
            }
        }
    }

    
const saveNewOrder = async () => {
    try {
        const newOrderId:number = GenerateOrderNumber
        const orderData = {
                buyer: buyerValue,
                buyerAddress: buyerAddressValue,
                buyerRepresentation: buyerRepresentationValue,
                comissionBuyer: comissionBuyerValue,
                comissionSeller: comissionSellerValue,
                commodity: commodityValue,
                consultant: consultantValue,
                customerName: customerNameValue,
                customerNum: customerNumValue,
                date: Timestamp.now(),
                following: followingValue,
                fuelType: fuelTypeValue,
                grossPrice: grossPriceValue,
                grossPriceCurrency: grossPriceCurrencyValue,
                guaranteeForContract: guaranteeForContractValue,
                id: newOrderId,
                inspectionValidity: inspectionValidityValue,
                loi: loiValue,
                oneYearContractPayment: oneYearContractPaymentValue,
                orderNum: orderNumValue,
                origin: originValue,
                paymentForTrial: paymentForTrialValue,
                paymentStatus: paymentStatusValue,
                performanceBond: performanceBondValue,
                quantity: quantityValue,
                quantityFrequency: quantityFrequencyValue,
                refinery: refineryValue,
                refineryDirector: refineryDirectorValue,
                representedBy: representedByValue,
                status: statusValue,
                trialQuantity: trialQuantityValue,
                unitQuantity: unitQuantityValue,
                globalQuantity: globalQuantityValue,
                deliveryTrial: deliveryTrialValue,
                deliveryRegular: deliveryRegularValue,
                loadingPort: loadingPortValue,
                trackingURL: trackingURLValue,
                firstDelivery: firstDeliveryValue,
                priceGrossBuyer: priceGrossBuyerValue,
                termsContract: termsContractValue,
                wholesalePrice: wholesalePriceValue,
                tanekahaCommission: tanekahaCommissionValue
        }

        await setDoc(doc(db, "Orders", newOrderId.toString()), orderData)
        console.log("New order saved successfully with ID:", newOrderId)
        // You might want to show a success message or redirect the user
        navigate('/SelectOrder/' + newOrderId)
    } catch (error) {
        console.error("Error saving new order:", error)
        // Handle the error (e.g., show an error message to the user)
    }
}


    return (
        <>
            {toasterShow ? (
                <Notifications newMessage={newMessage} newTitle={newTitle} />
            ) : (
                null
            )}
            {loading ? (
                <LoadingEvent />
            ) : (
                <div className="inset-y-0 right-0 lg:relative sm:flex flex-col min-h-screen w-full bg-tanekaha3">
                    <div>
                        <div className="relative px-4 sm:px-0 sm:right-0 lg:mt-20 lg:right-0 mx-auto text-center">
                            <p className="relative top-1 font-bebas mx-auto leading-7 text-gray-900 text-center lg:text-4xl md: text-2xl sm:text-xl max-sm:text-lg">NEW ORDER</p>
                            <p className="mt-1 max-w-2xl text-md font-bebas leading-6 text-white mx-auto text-center">Customer purchase order information</p>
                        </div>
                        <div className="relative mx-auto mt-6 border-t border-gray-100 lg:w-1/2 lg:left-20 bottom md:w-3/4 sm:w-3/4 max-sm:w-4/5 ">
                            <dl className="divide-y divide-gray-100">
                                <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-regular leading-6 text-gray-900">Order Satus</dt>
                                    <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-1 sm:mt-0">
                                        <select className="mt-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-tanekaha sm:max-w-xs sm:text-sm sm:leading-6 mb-2" value={statusValue} onChange={(e) => setStatusValue(e.target.value)}>
                                            <option value="Select a status">Select a status</option>
                                            <option value="Stage 0: Awaiting Mandate DD">Stage 0: Awaiting Mandate DD</option>
                                            <option value="Stage 1:Awaiting LOI">Stage 1: Awaiting LOI</option>
                                            <option value="Stage 2: Awaiting Seller FCO">Stage 2: Awaiting Seller FCO</option>
                                            <option value="Stage 3: Awaiting Buyer Signed FCO">Stage 3: Awaiting Buyer Signed FCO </option>
                                            <option value="Stage 4: Awaiting Buyer to send FCO + BCL or MT799">Stage 4: Awaiting Buyer to send FCO + BCL or MT799 </option>
                                            <option value="Stage 5: Awaiting Seller POP to Buyer's bank">Stage 5: Awaiting Seller POP to Buyer's bank</option>
                                            <option value="Stage 6: Awaiting Seller Draft Contract">Stage 6: Awaiting Seller Draft Contract</option>
                                            <option value="Stage 7: Awaiting completed final contract">Stage 7: Awaiting completed final contract</option>
                                            <option value="Stage 8: Awaiting SBLC from Buyer Bank">Stage 8: Awaiting SBLC from Buyer Bank </option>
                                            <option value="Stage 9: Awaiting PB from Seller's Bank">Stage 9: Awaiting PB from Seller's Bank </option>
                                            <option value="Stage 10: Awaiting Buyer Confirmation of Operational Payment Instrument">Stage 10: Awaiting Buyer Confirmation of Operational Payment Instrument</option>
                                            <option value="Stage 11: Awaiting Buyer visit to Loading Port">Stage 11: Awaiting Buyer visit to Loading Port </option>
                                            <option value="Stage 12: Awaiting Seller shipment">Stage 12: Awaiting Seller shipment </option>
                                            <option value="Stage 13: Awaiting Clearance of Shipment and CIQ Documentation from port of Destination">Stage 13: Awaiting Clearance of Shipment and CIQ Documentation from port of Destination</option>
                                            <option value="Stage 14: Awaiting Buyer Payment">Stage 14: Awaiting Buyer Payment </option>
                                            <option value="IN PROGRESS: Order Ongoing">IN PROGRESS: Order Ongoing</option>
                                            <option value="CLOSED: Order Completed">CLOSED: Order Completed</option>
                                            <option value="CLOSED: Order not proceeding">CLOSED: Order not proceeding</option>
                                        </select>
                                    </dd>
                                </div>


                                <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-regular leading-6 text-gray-900">Buyer Name</dt>
                                    <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                        <select
                                            id="buyerName"
                                            name="buyerName"
                                            value={buyerValue}
                                            onChange={(e) => setBuyerValue(e.target.value)}
                                            className="mt-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-tanekaha sm:max-w-xs sm:text-sm sm:leading-6 mb-2"
                                        >
                                            <option value="">Select a customer</option>
                                            {customerNames.map((name, index) => (
                                                <option key={index} value={name}>
                                                    {name}
                                                </option>
                                            ))}
                                        </select>
                                    </dd>
                                </div>


                                <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-regular leading-6 text-gray-900">Buyer Address</dt>
                                    <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                        <input type="text" className="block flex-1 border-0 rounded bg-white py-1 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" value={buyerAddressValue} onChange={(e) => setBuyerAddressValue(e.target.value)} />
                                    </dd>
                                </div>


                                <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-regular leading-6 text-gray-900">Represented By</dt>
                                    <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                        <input type="text" className="block flex-1 border-0 rounded bg-white py-1 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" value={buyerRepresentationValue} onChange={(e) => setBuyerRepresentationValue(e.target.value)} />
                                    </dd>
                                </div>


                                <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-regular leading-6 text-gray-900">Consulting party</dt>
                                    <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                        <input type="text" className="block flex-1 border-0 rounded bg-white py-1 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" value={consultantValue} onChange={(e) => setConsultantValue(e.target.value)} />
                                    </dd>
                                </div>

                                <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-regular leading-6 text-gray-900">Consultant representative (Name of person i.e. MR ZANE TAIT)</dt>
                                    <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                        <input type="text" className="block flex-1 border-0 rounded bg-white py-1 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" value={representedByValue} onChange={(e) => setRepresentedByValue(e.target.value)} />
                                    </dd>
                                </div>

                                <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-regular leading-6 text-gray-900">Following (Commodity Details)</dt>
                                    <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                        <input type="text" className="block flex-1 border-0 rounded bg-white py-1 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" value={followingValue} onChange={(e) => setFollowingValue(e.target.value)} />
                                    </dd>
                                </div>

                                <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-regular leading-6 text-gray-900">Refinery</dt>
                                    <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                        <input type="text" className="block flex-1 border-0 rounded bg-white py-1 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" value={refineryValue} onChange={(e) => setRefineryValue(e.target.value)} />
                                    </dd>
                                </div>

                                <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-regular leading-6 text-gray-900">Refinery Director</dt>
                                    <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                        <input type="text" className="block flex-1 border-0 rounded bg-white py-1 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" value={refineryDirectorValue} onChange={(e) => setRefineryDirectorValue(e.target.value)} />
                                    </dd>
                                </div>


                                <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-regular leading-6 text-gray-900">Commodity</dt>
                                    <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                        <input type="text" className="block flex-1 border-0 rounded bg-white py-1 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" value={commodityValue} onChange={(e) => setCommodityValue(e.target.value)} />
                                    </dd>
                                </div>

                                <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-regular leading-6 text-gray-900">Wholesale Price (USD Per MT)</dt>
                                    <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                        <input type="text" className="block flex-1 border-0 rounded bg-white py-1 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" value={wholesalePriceValue} onChange={(e) => setWholesalePriceValue(e.target.value)} />
                                    </dd>
                                </div>

                                <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-regular leading-6 text-gray-900">Quantity for Trial (MT)</dt>
                                    <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                        <input type="number" className="block flex-1 border-0 rounded bg-white py-1 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" value={trialQuantityValue} onChange={(e) => setTrialQuantityValue(parseInt(e.target.value))} />
                                    </dd>
                                </div>


                                <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-regular leading-6 text-gray-900">Quantity for Contract (MT)</dt>
                                    <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                        <input type="text" className="block flex-1 border-0 rounded bg-white py-1 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" value={quantityValue} onChange={(e) => setQuantityValue(e.target.value)} />
                                    </dd>
                                </div>


                                <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-regular leading-6 text-gray-900">Terms Contract</dt>
                                    <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                        <input type="text" className="block flex-1 border-0 rounded bg-white py-1 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" value={termsContractValue} onChange={(e) => setTermsContractValue(e.target.value)} />
                                    </dd>
                                </div>


                                <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-regular leading-6 text-gray-900">Global Quantity (First Year Total in MT)</dt>
                                    <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                        <input type="text" className="block flex-1 border-0 rounded bg-white py-1 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" value={globalQuantityValue} onChange={(e) => setGlobalQuantityValue(e.target.value)} />
                                    </dd>
                                </div>


                                <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-regular leading-6 text-gray-900">Origin</dt>
                                    <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                        <input type="text" className="block flex-1 border-0 rounded bg-white py-1 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" value={originValue} onChange={(e) => setOriginValue(e.target.value)} />
                                    </dd>
                                </div>

                                <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-regular leading-6 text-gray-900">Trial Delivery (MT)</dt>
                                    <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                        <input type="text" className="block flex-1 border-0 rounded bg-white py-1 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" value={deliveryTrialValue} onChange={(e) => setDeliveryTrialValue(e.target.value)} />
                                    </dd>
                                </div>


                                <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-regular leading-6 text-gray-900">Regular Delivery (MT)</dt>
                                    <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                        <input type="text" className="block flex-1 border-0 rounded bg-white py-1 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" value={deliveryRegularValue} onChange={(e) => setDeliveryRegularValue(e.target.value)} />
                                    </dd>
                                </div>


                                <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-regular leading-6 text-gray-900">First Delivery</dt>
                                    <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                        <input type="text" className="block flex-1 border-0 rounded bg-white py-1 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" value={firstDeliveryValue} onChange={(e) => setFirstDeliveryValue(e.target.value)} />
                                    </dd>
                                </div>

                                <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-regular leading-6 text-gray-900">Loading Port</dt>
                                    <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                        <input type="text" className="block flex-1 border-0 rounded bg-white py-1 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" value={loadingPortValue} onChange={(e) => setLoadingPortValue(e.target.value)} />
                                    </dd>
                                </div>

                                <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-regular leading-6 text-gray-900">Buyer Gross Price (USD)</dt>
                                    <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                        <input type="text" className="block flex-1 border-0 rounded bg-white py-1 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" value={grossPriceValue} onChange={(e) => setGrossPriceValue(e.target.value)} />
                                    </dd>
                                </div>

                                <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-regular leading-6 text-gray-900">Payment For Trial</dt>
                                    <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                        <input type="text" className="block flex-1 border-0 rounded bg-white py-1 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" value={paymentForTrialValue} onChange={(e) => setPaymentForTrialValue(e.target.value)} />
                                    </dd>
                                </div>

                                <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-regular leading-6 text-gray-900">One Year Contract</dt>
                                    <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                        <input type="text" className="block flex-1 border-0 rounded bg-white py-1 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" value={oneYearContractPaymentValue} onChange={(e) => setOneYearContractPaymentValue(e.target.value)} />
                                    </dd>
                                </div>

                                <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-regular leading-6 text-gray-900">Guarantee for Contract</dt>
                                    <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                        <input type="text" className="block flex-1 border-0 rounded bg-white py-1 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" value={guaranteeForContractValue} onChange={(e) => setGuaranteeForContractValue(e.target.value)} />
                                    </dd>
                                </div>

                                <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-regular leading-6 text-gray-900">Performance Bond</dt>
                                    <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                        <input type="text" className="block flex-1 border-0 rounded bg-white py-1 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" value={performanceBondValue} onChange={(e) => setPerformanceBondValue(e.target.value)} />
                                    </dd>
                                </div>

                                <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-regular leading-6 text-gray-900">Inspection Validity</dt>
                                    <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                        <input type="text" className="block flex-1 border-0 rounded bg-white py-1 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" value={inspectionValidityValue} onChange={(e) => setInspectionValidityValue(e.target.value)} />
                                    </dd>
                                </div>

                                <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-regular leading-6 text-gray-900">Comission (USD BUYER | SELLER)</dt>
                                    <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                        <input type="text" className="block flex-1 border-0 rounded bg-white py-1 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" value={comissionBuyerValue} onChange={(e) => setComissionBuyerValue(Number(e.target.value))} placeholder="Buyer side" />
                                        <input type="text" className="ml-1 block flex-1 border-0 rounded bg-white py-1 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" value={comissionSellerValue} onChange={(e) => setComissionSellerValue(Number(e.target.value))} placeholder="Seller side" />
                                    </dd>
                                </div>

                                <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-regular leading-6 text-gray-900">Tanekaha Profit Per MT (USD)</dt>
                                    <dd className="mt-1 flex text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                                        <input type="text" className="block flex-1 border-0 rounded bg-white py-1 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" value={tanekahaCommissionValue} onChange={(e) => setTanekahaCommissionValue(e.target.value)} placeholder="Buyer side" />
                                    </dd>
                                </div>

                                <div className="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0 items-center">
                                    <div></div>
                                    <div></div>
                                    <button
                                        type="button"
                                        className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-black hover:text-white"
                                        onClick={() => saveNewOrder()}
                                    >
                                        <FontAwesomeIcon icon={faFloppyDisk} />
                                        &nbsp;Save Order
                                    </button>
                                </div>

                            </dl>
                        </div>
                    </div>
                </div>
            )
            };
        </>
    );
}