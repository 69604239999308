import Header from "../components/CustomerPortal/Header";
import CurrentOrders from "../components/CustomerPortal/CurrentOrders";
import SideBar from "../components/CustomerPortal/SideBar";

function CurrentOrdersPage() {

  return(
      <div>
        <Header />
        <SideBar />
        <CurrentOrders/>
      </div>
  );
}

export default CurrentOrdersPage;
