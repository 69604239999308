import TanekahaLogo from '../images/TanekahaLogo.jpg';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom'

export default function Login() {
 
const auth = getAuth();
const [email, setEmail] = useState("");
const [password, setPassword] = useState("");
const navigate = useNavigate()

function handleChangeEmail(e) { 
  setEmail(e.target.value); 
} 

function handleChangePassword(e) {
  setPassword(e.target.value);
}

const onSubmit = async (e) => {
  e.preventDefault()

signInWithEmailAndPassword(auth, email, password)
  .then((userCredential) => {
    // Signed in 
    const user = userCredential.user;
    console.log(userCredential.user)
    navigate('/Portal')
    // ...
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
  });

}

    return (
        <div>
   <div className="flex min-h-full flex-1 items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
        <div className="w-full max-w-sm space-y-10">
          <div>
            <img
              alt="Tanekaha Fuels"
              src={TanekahaLogo}
              className="mx-auto size-32"
            />
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-[#c1a274]">
              Sign in to your account
            </h2>
          </div>
          <form onSubmit={onSubmit} className="space-y-6">
            <div className="relative -space-y-px rounded-md shadow-sm">
              <div className="pointer-events-none absolute inset-0 z-10 rounded-md ring-1 ring-inset ring-gray-300" />
              <div>
                <label htmlFor="email" className="sr-only">
                  Email address
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  placeholder="Email address"
                  autoComplete="email"
                  className="relative block w-full rounded-t-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  value={email}
                  onChange={handleChangeEmail}
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  required
                  placeholder="Password"
                  autoComplete="current-password"
                  className="relative block w-full rounded-b-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  value={password}
                  onChange={handleChangePassword}
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-[#c1a274] focus:ring-[#c1a274]"
                />
                <label htmlFor="remember-me" className="ml-3 block text-sm leading-6 text-[#c1a274]">
                  Remember me
                </label>
              </div>

              <div className="text-sm leading-6">
                <a href="/" className="font-semibold text-[#c1a274] hover:text-white">
                  Forgot password?
                </a>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-[#c1a274] px-3 py-1.5 text-sm font-semibold leading-6 text-white hover:text-black hover:bg-white  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#c1a274]"
              >
                Sign in
              </button>
              <p className="mt-10 text-center text-sm text-gray-500">This system is for authorized users only. By accessing this system, you agree to comply with all applicable laws and regulations. Unauthorized access, use, or modification of this system or its data is strictly prohibited and may result in disciplinary action, civil and/or criminal penalties.</p>
            </div>
          </form>
        </div>
      </div>

        </div>
    );
}