import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid'
import { AuthContext } from '../../Context/AuthContext'
import { useEffect, useContext, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { db, storage } from "../../firebase/firebase";
import { getDoc, doc, updateDoc, setDoc, deleteDoc } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk, faPencil } from '@fortawesome/free-solid-svg-icons';
import { EmailAuthProvider, reauthenticateWithCredential, updateEmail } from 'firebase/auth';
import { getDownloadURL, uploadBytes, ref } from 'firebase/storage';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

export default function Profile() {

  const { currentUser, setCurrentUser, signOut } = useContext(AuthContext);
  const auth = useContext(AuthContext)
  const navigate = useNavigate()
  const userEmail = auth.currentUser?.email
  const [userData, setUserData] = useState<userDataInterface>();
  const [passwordValue, setPasswordValue] = useState("");

  type userDataInterface = {
    contactPh: string;
    customerNum: string;
    email: string;
    firstName: string;
    lastName: string;
    imageUrl: string;
    role: string;
    address: string;
  }[];

  // Constants for form fields
  const [editContactPh, setEditContactPh] = useState(false);
  const [contactPhValue, setContactPhValue] = useState("");

  const [editCustomerNum, setEditCustomerNum] = useState(false);
  const [customerNumValue, setCustomerNumValue] = useState("");

  const [editEmail, setEditEmail] = useState(false);
  const [emailValue, setEmailValue] = useState("");

  const [editFirstName, setEditFirstName] = useState(false);
  const [firstNameValue, setFirstNameValue] = useState("");

  const [editLastName, setEditLastName] = useState(false);
  const [lastNameValue, setLastNameValue] = useState("");

  const [editImageUrl, setEditImageUrl] = useState(false);
  const [imageUrlValue, setImageUrlValue] = useState("");

  const [editRole, setEditRole] = useState(false);
  const [roleValue, setRoleValue] = useState("");

  const [editAddress, setEditAddress] = useState(false);
  const [addressValue, setAddressValue] = useState("");

  const getUserData = async () => {

    const docRef = doc(db, "Users", userEmail);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const data = docSnap.data()
      setUserData([
        {
          contactPh: data.contactPh,
          customerNum: data.customerNum,
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
          imageUrl: data.imageUrl,
          role: data.role,
          address: data.address
        }]
      );
      setContactPhValue(data.contactPh)
      setCustomerNumValue(data.customerNum)
      setEmailValue(data.email)
      setFirstNameValue(data.firstName)
      setLastNameValue(data.lastName)
      setImageUrlValue(data.imageUrl)
      setRoleValue(data.role)
      setAddressValue(data.address)

    } else {
      alert('No such Id. Please check again');
    }

  }

  const saveUserData = async (field) => {
    const docRef = doc(db, "Users", userEmail);
    const updateData = {
      [field]: eval(`${field}Value`)
    };
    console.log(userEmail)
    console.log('Updating document with data:', updateData);

    try {
      await updateDoc(docRef, updateData);
      console.log('Document successfully updated');
    } catch (error) {
      console.error('Error updating document:', error);
      alert('Failed to update user data');
      return;
    }

    alert("User data updated successfully");
    await getUserData();

    switch (field) {
      case 'contactPh':
        setEditContactPh(false);
        break;
      case 'customerNum':
        setEditCustomerNum(false);
        break;
      case 'email':
        setEditEmail(false);
        updateUserEmail();
        break;
      case 'firstName':
        setEditFirstName(false);
        break;
      case 'lastName':
        setEditLastName(false);
        break;
      case 'imageUrl':
        setEditImageUrl(false);
        break;
      case 'role':
        setEditRole(false);
        break;
      case 'address':
          setEditAddress(false);
          break;
    }
  }


  useEffect(() => {
    if (userEmail == undefined) {
      navigate('/login')
      console.log("No valid user logged in")
    } else {
      getUserData()
      console.log("current Order Form user is " + userEmail)
    }
  }, [auth])

  const updateUserEmail = async () => {
    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error("No user is currently signed in.");
      }

      const credential = EmailAuthProvider.credential(userEmail, passwordValue);
      await reauthenticateWithCredential(user, credential);

      // Update email in Firebase Authentication
      await updateEmail(user, emailValue);
      console.log('Email successfully updated in Firebase Authentication');

      // Retrieve data from the existing document
      const oldDocRef = doc(db, "Users", userEmail);
      const oldDocSnap = await getDoc(oldDocRef);

      if (!oldDocSnap.exists()) {
        throw new Error("Old document does not exist.");
      }

      const userData = oldDocSnap.data();

      // Create a new document with the new email address
      const newDocRef = doc(db, "Users", emailValue);
      await setDoc(newDocRef, userData);
      console.log('New document created with new email address');

      // Delete the old document
      await deleteDoc(oldDocRef);
      console.log('Old document deleted');

      alert("Email updated successfully");
    } catch (error) {
      console.error('Error updating email:', error);
      alert('Failed to update email');
    }
  };

  const uploadProfilePicture = async (file) => {
    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error("No user is currently signed in.");
      }

      const storageRef = ref(storage, `users/${user.email}/profile_picture`);
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);

      console.log('Profile picture uploaded successfully:', downloadURL);

      // Update the Firestore document with the new profile picture URL
      const docRef = doc(db, "Users", user.email);
      await updateDoc(docRef, { profilePicture: downloadURL });

      console.log('Firestore document updated with new profile picture URL');
      alert("Profile picture updated successfully");
    } catch (error) {
      console.error('Error uploading profile picture:', error);
      alert('Failed to upload profile picture');
    }
  };

  return (
    <div className="relative w-full h-lvh bg-tanekaha3">
      <div className="absolute mt-20 w-3/4 right-10 space-y-10 divide-y divide-gray-900/10">
        <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
          <div className="px-4 sm:px-0 bg-transparent rounded-xl">
            <h2 className="text-xl font-semibold leading-7 text-gray-900">Profile</h2>
            <p className="mt-1 text-lg text-mono leading-6 text-gray-600">
              You can update your user profile information here (NOT WORKING YET)
            </p>
          </div>

          <form className="bg-gray-200 shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
            <div className="px-4 py-6 sm:p-8">
              <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                {/*First name field */}
                {editFirstName ? (

                  <div className="sm:col-span-4">
                    <label htmlFor="website" className="block text-sm font-medium leading-6 text-gray-900">
                      First Name
                    </label>
                    <div className="mt-2">
                      <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                        <input
                          id="firstNameEdit"
                          className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                          onChange={(e) => setFirstNameValue(e.target.value)}
                          value={firstNameValue}
                        ></input>
                        <button type="button" onClick={() => saveUserData('firstName')} className="text-black bg-gray-200 rounded hover:text-white py-1 px-3">
                          <FontAwesomeIcon icon={faFloppyDisk} />
                        </button>
                      </div>
                    </div>
                  </div>

                ) : (

                  <div className="sm:col-span-4">
                    <label htmlFor="website" className="block text-sm font-medium leading-6 text-gray-900">
                      First Name
                    </label>
                    <div className="mt-2">
                      <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                        <span
                          id="firstNameField"
                          className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        >{userData?.[0].firstName}</span>
                        <span className="ml-4 flex-shrink-0">
                          <button type="button" onClick={() => setEditFirstName(true)} className="text-black bg-gray-200 rounded hover:text-white py-1 px-3">
                            <FontAwesomeIcon icon={faPencil} />
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>

                )}


                {/*Last name field */}
                {editLastName ? (

                  <div className="sm:col-span-4">
                    <label htmlFor="website" className="block text-sm font-medium leading-6 text-gray-900">
                      Last Name
                    </label>
                    <div className="mt-2">
                      <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                        <input
                          id="lastNameEdit"
                          className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                          onChange={(e) => setLastNameValue(e.target.value)}
                          value={lastNameValue}
                        ></input>
                        <button type="button" onClick={() => saveUserData('lastName')} className="text-black bg-gray-200 rounded hover:text-white py-1 px-3">
                          <FontAwesomeIcon icon={faFloppyDisk} />
                        </button>
                      </div>
                    </div>
                  </div>

                ) : (

                  <div className="sm:col-span-4">
                    <label htmlFor="website" className="block text-sm font-medium leading-6 text-gray-900">
                      Last Name
                    </label>
                    <div className="mt-2">
                      <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                        <span
                          id="lastNameField"
                          className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        >{userData?.[0].lastName}</span>
                        <span className="ml-4 flex-shrink-0">
                          <button type="button" onClick={() => setEditLastName(true)} className="text-black bg-gray-200 rounded hover:text-white py-1 px-3">
                            <FontAwesomeIcon icon={faPencil} />
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>

                )}


                {/*Contact Phone Number field */}
                {editContactPh ? (

                  <div className="sm:col-span-4">
                    <label htmlFor="website" className="block text-sm font-medium leading-6 text-gray-900">
                      Contact Phone Number
                    </label>
                    <div className="mt-2">
                      <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                        <input
                          id="contactPhEdit"
                          className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                          onChange={(e) => setContactPhValue(e.target.value)}
                          value={contactPhValue}
                        ></input>
                        <button type="button" onClick={() => saveUserData('contactPh')} className="text-black bg-gray-200 rounded hover:text-white py-1 px-3">
                          <FontAwesomeIcon icon={faFloppyDisk} />
                        </button>
                      </div>
                    </div>
                  </div>

                ) : (

                  <div className="sm:col-span-4">
                    <label htmlFor="website" className="block text-sm font-medium leading-6 text-gray-900">
                      Contact Phone Number
                    </label>
                    <div className="mt-2">
                      <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                        <span
                          id="contactPhField"
                          className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        >{userData?.[0].contactPh}</span>
                        <span className="ml-4 flex-shrink-0">
                          <button type="button" onClick={() => setEditContactPh(true)} className="text-black bg-gray-200 rounded hover:text-white py-1 px-3">
                            <FontAwesomeIcon icon={faPencil} />
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>

                )}


                {/*Email field */}
                {editEmail ? (

                  <div className="sm:col-span-4">
                    <label htmlFor="website" className="block text-sm font-medium leading-6 text-gray-900">
                      Email
                    </label>
                    <div className="mt-2">
                      <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                        <input
                          id="emailEdit"
                          className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                          onChange={(e) => setEmailValue(e.target.value)}
                          value={emailValue}
                        ></input>
                        <button type="button" onClick={() => saveUserData('email')} className="text-black bg-gray-200 rounded hover:text-white py-1 px-3">
                          <FontAwesomeIcon icon={faFloppyDisk} />
                        </button>
                      </div>
                      <div className="flex rounded-md shadow-sm sm:max-w-md mt-1 mb-1">
                        <span className="text-md text-red-700">*Enter your current password below to confirm and click the "save" button</span>
                      </div>
                      <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                        <input
                          id="password"
                          type="password"
                          className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-black placeholder:text-grey-400 focus:ring-0 sm:text-sm sm:leading-6"
                          placeholder='enter your current password here'
                          onChange={(e) => setPasswordValue(e.target.value)}
                          value={passwordValue}
                        ></input>
                      </div>
                    </div>
                  </div>

                ) : (

                  <div className="sm:col-span-4">
                    <label htmlFor="website" className="block text-sm font-medium leading-6 text-gray-900">
                      Email
                    </label>
                    <div className="mt-2">
                      <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                        <span
                          id="emailField"
                          className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        >{userData?.[0].email}</span>
                        <span className="ml-4 flex-shrink-0">
                          <button type="button" onClick={() => setEditEmail(true)} className="text-black bg-gray-200 rounded hover:text-white py-1 px-3">
                            <FontAwesomeIcon icon={faPencil} />
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>

                )}

                {/*Address field */}
                {editAddress ? (

                  <div className="sm:col-span-4">
                    <label htmlFor="website" className="block text-sm font-medium leading-6 text-gray-900">
                      Address
                    </label>
                    <div className="mt-2">
                      <div className="flex m:max-w-md">
                      <GooglePlacesAutocomplete apiKey="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" />
                        <button type="button" onClick={() => saveUserData('address')} className="text-black bg-gray-200 rounded hover:text-white py-1 px-3">
                          <FontAwesomeIcon icon={faFloppyDisk} />
                        </button>
                      </div>
                    </div>
                    <div>
                      <div className="flex sm:max-w-md">
                        
                      </div>
                    </div>
                  </div>

                ) : (

                  <div className="sm:col-span-4">
                    <label htmlFor="website" className="block text-sm font-medium leading-6 text-gray-900">
                      Address
                    </label>
                    <div className="mt-2">
                      <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                        <span
                          id="addressField"
                          className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        >{userData?.[0].address}</span>
                        <span className="ml-4 flex-shrink-0">
                          <button type="button" onClick={() => setEditAddress(true)} className="text-black bg-gray-200 rounded hover:text-white py-1 px-3">
                            <FontAwesomeIcon icon={faPencil} />
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>

                )}

                <div className="col-span-full">
                  <label htmlFor="photo" className="block text-sm font-medium leading-6 text-gray-900">
                    Photo
                  </label>
                  <div className="mt-2 flex items-center gap-x-3">
                    <UserCircleIcon aria-hidden="true" className="h-12 w-12 text-gray-300" />
                    <button
                      type="button"
                      className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                      Change
                    </button>
                  </div>
                </div>


              </div>
            </div>
          </form>
        </div>


      </div>

    </div>
  )
}