import { useState, useContext, useEffect, useMemo } from "react"
import { AuthContext } from '../../Context/AuthContext'
import { useNavigate } from 'react-router-dom'
import { PDFDownloadLink } from '@react-pdf/renderer';
import Invoice from "./Invoice"
import { storage } from "../../firebase/firebase";
import { ref, uploadBytes } from "firebase/storage";
import { collection, doc, getDoc, getDocs, Timestamp, query, where, setDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import Loading from "../Loading";
import Notifications from "../Notifications";

function CurrentOrders() {

  const GenerateCustomerNumber = Math.floor(Math.random() * 90000) + 8000;

  const [showNotification, setShowNotification] = useState(false);

  const handleShowNotification = () => {
    setShowNotification(true);
  };

  const handleCloseNotification = () => {
    setShowNotification(false);
  };

  // Toaster notification state
  const [toasterShow, setToasterShow] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const [newTitle, setNewTitle] = useState("");

  //Current User State
  const { currentUser, setCurrentUser, signOut } = useContext(AuthContext);
  const navigate = useNavigate();
  const userEmail = currentUser?.email
  const [userData, setUserData] = useState<userDataInterface>();

  // Page loading State
  const [loading, setLoading] = useState(true);


  const [fileUpload, setFileUpload] = useState(null);
  const [fileName, setFileName] = useState();
  const [filesListing, setFilesListing] = useState([]);

  const [currentOrderNum, setCurrentOrderNum] = useState<number | "">("");
  const [orders, setOrders] = useState<ordersInterface>([]);


  type ordersInterface = {
    buyer: string;
    buyerAddress: string;
    buyerRepresentation: string;
    comissionBuyer: number;
    comissionSeller: number;
    commodity: string;
    consultant: string;
    customerName: string;
    customerNum: string;
    date: Timestamp;
    following: string;
    fuelType: string;
    grossPrice: number;
    grossPriceCurrency: string;
    guaranteeForContract: string;
    id: number;
    inspectionValidity: string;
    loi: string;
    oneYearContractPayment: string;
    orderNum: string;
    origin: string;
    paymentForTrial: string;
    paymentStatus: string;
    performanceBond: string;
    quantity: number;
    quantityFrequency: string;
    refinery: string;
    refineryDirector: string;
    representedBy: string;
    status: string;
    trialQuantity: number;
    unitQuantity: string;

  }[];

  type userDataInterface = {
    userContactPh: string;
    userCustomerNum: string;
    userEmail: string;
    userFirstName: string;
    userLastName: string;
    imageUrl: string;
    role: string;
  }[];

const copySelectedOrder = async (orderId: number) => {
  try {
    const orderRef = query(collection(db, "Orders"), where("id", "==", orderId));
    const orderSnap = await getDocs(orderRef);

    if (!orderSnap.empty) {
      const orderDoc = orderSnap.docs[0];
      const orderData = orderDoc.data();
      const newCustomerNumber = GenerateCustomerNumber;
      const newOrderRef = doc(collection(db, "Orders"), `${newCustomerNumber}`);
      
      await setDoc(newOrderRef, {
        ...orderData,
        id: newCustomerNumber,        
        orderNum: newCustomerNumber,
        date: Timestamp.now(),
        status: "OPEN: New Order"
      });

      console.log("Order copied successfully");
       // Alert stuff
       setToasterShow(true)
       setNewTitle("You have added a new order")
       setNewMessage("New order number " + newCustomerNumber + " was added successfully")
       await delay(5000);
       setToasterShow(false)
       navigate('/SelectOrder/'+ newCustomerNumber);
      getDataFirestore(); // Refresh the orders list
    } else {
      console.log("No such order!");
    }
  } catch (error) {
    console.error("Error copying order: ", error);
  }
}



  const getDataFirestore = async () => {
    setLoading(true);
    const q = query(collection(db, "Orders"), where("status", "not-in", ["CLOSED: Order not proceeding", "CLOSED: Order Completed"]));
    const querySnapshot = await getDocs(q);
    const orderInfo = querySnapshot.docs.map(doc => {
      const data = doc.data();
      return {
        buyer: data.buyer,
        buyerAddress: data.buyerAddress,
        buyerRepresentation: data.buyerRepresentation,
        comissionBuyer: data.comissionBuyer,
        comissionSeller: data.comissionSeller,
        commodity: data.commodity,
        consultant: data.consultant,
        customerName: data.customerName,
        customerNum: data.customerNum,
        date: data.date,
        following: data.following,
        fuelType: data.fuelType,
        grossPrice: data.grossPrice,
        grossPriceCurrency: data.grossPriceCurrency,
        guaranteeForContract: data.guaranteeForContract,
        id: data.id,
        inspectionValidity: data.inspectionValidity,
        loi: data.loi,
        oneYearContractPayment: data.oneYearContractPayment,
        orderNum: data.orderNum,
        origin: data.origin,
        paymentForTrial: data.paymentForTrial,
        paymentStatus: data.paymentStatus,
        performanceBond: data.performanceBond,
        quantity: data.quantity,
        quantityFrequency: data.quantityFrequency,
        refinery: data.refinery,
        refineryDirector: data.refineryDirector,
        representedBy: data.representedBy,
        status: data.status,
        trialQuantity: data.trialQuantity,
        unitQuantity: data.unitQuantity
      };
    });
    setOrders(orderInfo);
    setLoading(false);
  };

  const getUserData = async () => {
    setLoading(true);
    const docRef = doc(db, "Users", userEmail);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const data = docSnap.data()
      setUserData([
        {
          userContactPh: data.contactPh,
          userCustomerNum: data.customerNum,
          userEmail: data.email,
          userFirstName: data.firstName,
          userLastName: data.lastName,
          imageUrl: data.imageUrl,
          role: data.role
        }]
      );
      setLoading(false);
    } else {
      alert('No such Id. Please check again');
    }

  }

  const orderSelect = (orderId: number) => {
    navigate(`/SelectOrder/${orderId}`);
  }

  const LoadingEvent = () => (
    <Loading />
  );

  // Delay function
  function delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }



  useEffect(() => {
    if (userEmail == undefined) {
      navigate('/Portal')
      console.log("Current user is not authorised")
    } else {
      setLoading(true);
      getDataFirestore()
      getUserData()
      console.log(userEmail + " is logged on currently")
      setLoading(false);
    }
  }, [currentUser])

  const [customerInfo, setCustomerInfo] = useState({
    companyName: "Offshore Fuels Company",
    name: "John Doe",
    email: "john.doe@example.com",
    phone: "555-1234",
    address: "123 Main St, Anytown USA",
  })

  // PDF Document constant

  const generatePdfInvoice = (order) => {
    console.log("Generating PDF invoice for order:", order)

  }

  const updateCustomerInfo = (updatedInfo) => {
    setCustomerInfo(updatedInfo)
  }

  return (
    <>
      {toasterShow ? (
        <Notifications newMessage={newMessage} newTitle={newTitle} />
      ) : (
        null
      )}
      {loading ? (
        <LoadingEvent />
      ) : (

        <div className="inset-y-0 right-0 lg:absolute sm:flex flex-col min-h-screen w-full bg-tanekaha3">
          <div className="absolute mx-auto overflow-y-auto lg:top-0 lg:right-10 lg:w-3/4 md:top-30 md:right-10 md:w-3/4 h-screen sm:right-5 sm:top-30 sm:right-10 sm:w-full max-sm:top-30 max-sm:right-10 max-sm:full">
            <header className="bg-primary text-primary-foreground py-4 text-left">
              <div className="container mx-auto flex justify-between">
                <h1 className="lg:text-4xl font-bebas text-left text-black mt-10 max-sm:text-xl sm:text-xl">TANEKAHA PORTAL</h1>
              </div>
            </header>
            <div className="flex flex-1 bg-background place-content-center">
              <div className="flex-1 mx-auto">
                <main className="flex-1 bg-background justify-items-center">
                  <div className="container mx-auto grid grid-cols-1 md:grid-cols-1 place-items-center">
                    <div className="mx-auto w-auto">
                      <h2 className="lg:text-2xl font-bebas mb-4 text-left max-sm:text-lg sm:text-lg">CURRENT ORDERS</h2>
                      <table className="divide-y divide-gray-400 mx-auto bg-white">
                        <thead className="bg-gray-200">
                          <tr>
                            <th className="py-3.5 pl-4 pr-3 text-center font-regular text-black sm:pl-6 max-sm:text-xs sm:text-xs lg:text-sm">Order #</th>
                            <th className="px-3 py-3.5 text-cemter font-regular text-black max-sm:text-xs sm:text-xs lg:text-sm">Customer</th>
                            <th className="px-3 py-3.5 text-cemter font-regular text-black max-sm:text-xs sm:text-xs lg:text-sm">Date</th>
                            <th className="px-3 py-3.5 text-cemter font-regular text-black max-sm:text-xs sm:text-xs lg:text-sm">Commodity</th>
                            <th className="px-3 py-3.5 text-cemter font-regular text-black max-sm:text-xs sm:text-xs lg:text-sm">Quantity</th>
                            <th className="px-3 py-3.5 text-cemter font-regular text-black max-sm:text-xs sm:text-xs lg:text-sm">Status</th>
                            {/*<th className="px-3 py-3.5 text-cemter font-regular text-black max-sm:text-xs sm:text-xs lg:text-sm">LOI status</th>
                          <th className="px-3 py-3.5 text-cemter font-regular text-black max-sm:text-xs sm:text-xs lg:text-sm">Payment Status</th>
                          <th className="ptext-left font-regular text-black max-sm:text-xs sm:text-xs lg:text-sm">Generate ICPO</th>*/}
                          </tr>
                        </thead>
                        <tbody>
                          {orders.map((order) => (
                            <tr className="divide-y divide-gray-200 lg:w-full sm:w-full" key={order.id}>
                              <td className="whitespace-normal text-center text-wrap text-gray-900 max-sm:text-xs max-sm:py-1 max-sm:pl-2 sm:text-xs lg:text-sm lg:gap-x-1 lg:py-1 lg:px-3">
                                <button className="bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-1" onClick={() => orderSelect(order.id)}>{order.id}
                                  </button>
                                  <br></br>
                                  <br></br>
                                  <button className="bg-gray-200 rounded hover:text-white hover:bg-black py-1 px-1" onClick={() => copySelectedOrder(order.id)}>copy
                                  </button>
                              </td>
                              <td className="whitespace-normal font-regular text-center text-wrap text-gray-900 max-sm:text-xs sm:text-xs lg:text-sm lg:gap-x-1 lg:py-1 lg:px-3">{order?.buyer}</td>
                              <td className="whitespace-normal font-regular text-center text-wrap text-gray-900 max-sm:text-xs sm:text-xs lg:text-sm lg:gap-x-1 lg:py-1 lg:px-3">{order?.date.toDate().toDateString()}</td>
                              <td className="whitespace-normal font-regular text-center text-wrap text-gray-900 max-sm:text-xs sm:text-xs lg:text-sm lg:gap-x-1 lg:py-1 lg:px-3">{order?.commodity}</td>
                              <td className="whitespace-normal font-regular text-center text-wrap text-gray-900 max-sm:text-xs sm:text-xs lg:text-sm lg:gap-x-1 lg:py-1 lg:px-3">{order?.quantity + order.unitQuantity}</td>
                              <td className="whitespace-normal font-regular text-center text-wrap text-gray-900 max-sm:text-xs sm:text-xs lg:text-sm lg:gap-x-1 lg:py-1 lg:px-3">
                                <span className="inline-flex items-center text-wrap gap-x-1 py-1 px-3 rounded-full text-xs font-medium bg-gray-100 text-gray-800 dark:bg-white/10 dark:text-white">{order.status}</span>
                              </td>
                              {/*<td className="whitespace-normal py-4 pl-4 pr-3 text-sm text-center text-gray-900 sm:pl-6 max-sm:text-xs sm:text-xs lg:text-sm">
                              <span className="inline-flex items-center text-wrap gap-x-1 py-1.5 px-3 rounded-full text-xs font-medium bg-gray-100 text-gray-800 dark:bg-white/10 dark:text-white">{order.loi}</span>
                            </td>
                            <td className="whitespace-normal text-wrap text-sm text-center items-center text-gray-500 max-sm:text-xs sm:text-xs lg:text-sm">
                              <span className="inline-flex items-center text-wrap mx-auto gap-x-1 py-1 px-3 rounded-full text-xs font-medium bg-gray-100 text-gray-800 dark:bg-white/10 dark:text-white ml-2 mr-2">{order.paymentStatus}</span>
                            </td>
                           
                            <td className="whitespace-normal text-sm text-center items-center text-gray-500 max-sm:text-xs sm:text-xs lg:text-sm">

                              <PDFDownloadLink document={
                                <Invoice CustomerName={order.customerName}
                                  OrderNum={order.id}
                                  Date={order.date.toDate().toDateString()}
                                  CustomerAddress={customerInfo.address} />} fileName={customerInfo.companyName + " " + order.id}>
                                <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-1 px-4 rounded inline-flex items-center ml-2 mr-2">
                                  <svg className="fill-current w-3 h-2 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" /></svg>
                                  <span>Download</span>
                                </button>
                              </PDFDownloadLink>
                            </td>
                          */}
                            </tr>
                          ))}
                        </tbody>
                      </table>

                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
          <footer className="absolute inset-x-0 bottom-10 bg-muted text-muted-foreground">
            <div className="container sm:hidden max-sm:hidden mx-auto text-center">© 2024 Tanekaha Fuels. All rights reserved.</div>
          </footer>
        </div>
      )}
    </>
  );
}
export default CurrentOrders;