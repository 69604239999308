import Header from "../components/CustomerPortal/Header";
import SideBar from "../components/CustomerPortal/SideBar";
import Dashboard from "../components/CustomerPortal/Dashboard";

function DashboardPage() {

  return(
      <div>
        <Header />
        <SideBar />
        <Dashboard />
      </div>
  );
}

export default DashboardPage;
