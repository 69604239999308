import Header from "../components/CustomerPortal/Header";
import SideBar from "../components/CustomerPortal/SideBar";
import Profile from "../components/CustomerPortal/Profile";

function OrderPage() {

  return(
      <div>
        <Header />
        <SideBar />
        <Profile />
      </div>
  );
}

export default OrderPage;
