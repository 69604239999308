import Header from "../components/CustomerPortal/Header";
import SideBar from "../components/CustomerPortal/SideBar";
import SelectOrder from "../components/CustomerPortal/SelectOrder";
import { useParams } from "react-router-dom";


function SelectPage() {
  const { orderId } = useParams();

  return(
      <div>
        <Header />
        <SideBar />
        <SelectOrder orderId={orderId}/>
      </div>
  );
}

export default SelectPage;
